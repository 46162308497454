// TODO поменять название. Сделать более понятным
import * as Api from '../../../utils/api';
import { dataDontSave, dataSaved, initSavingProcess } from './spaces';

export const INIT_PAGES_DATA = 'INIT_PAGES_DATA';
export const CHANGE_PAGE_SETTINGS = 'CHANGE_PAGE_SETTINGS';
export const RECEIVE_PAGE_SETTINGS_FROM_SERVER = 'RECEIVE_PAGE_SETTINGS_FROM_SERVER';

const initialState = {
  pages: [],
  currentPage: 0
};

export const initPages = landing => dispatch => {
  const pagesWithoutBlocks = landing.pages.map(
    page => ({ ...page, blocks: page.blocks.map(block => ({ _id: block._id })) })
  );

  dispatch({
    type: INIT_PAGES_DATA,
    payload: {
      pages: pagesWithoutBlocks
    }
  });
};

export const changePageSettings = ({propName, propValue, needSave = true, needSetData = true}) => (dispatch, getState) => {
  const { pages: { pages, currentPage }, landing: { landing } } = getState();

  const updatedPages = pages.map(
    (page, pageIndex) => (pageIndex === currentPage ? { ...page, [propName]: propValue } : page)
  );
  dispatch({
    type: CHANGE_PAGE_SETTINGS,
    payload: {
      pages: updatedPages
    }
  });

  if (needSave) {
    dispatch(initSavingProcess());
    const { merchantId, projectId, _id: landingId } = landing;
    const data = { propName, propValue };
    Api.savePageValue({
      merchantId,
      projectId,
      landingId,
      pageId: updatedPages[currentPage]._id,
      data
    }).then(response => {
      if (needSetData) {
        if (response.status === 200 && !!response?.data?.pages) {
          const updatedDocument = response.data;
          dispatch({
            type: RECEIVE_PAGE_SETTINGS_FROM_SERVER,
            payload: {
              pages: updatedDocument.pages
            }
          });
        }
      }
    })
      .catch(() => dispatch(dataDontSave()))
      .finally(() => dispatch(dataSaved()));
  }
};

export default function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_PAGES_DATA:
    case CHANGE_PAGE_SETTINGS:
    case RECEIVE_PAGE_SETTINGS_FROM_SERVER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
