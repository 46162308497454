import { AbstractBlock } from '@site-builder/common/src/types/block/common/abstract-block';
import { StoreBlock } from '@site-builder/common/src/types/block/store/store-block';
import { RenderMode, Environment } from '@site-builder/common/src/types/build';
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import {
  CartSettings,
  Authorization,
  LandingThemeType,
  WidgetsType,
} from '@site-builder/common/src/types/model/landing';
import { Subscription } from '@site-builder/common/src/types/model/subscription';

import { Theme as LoginTheme } from '../../../utils/login/types';
import { Action, Dispatch, GetState } from '../../types';
import { RootState } from '../../types/root-state';
import { RetailersType } from './retailers';

export type ScriptsState = {
  auth: Authorization;
  accentColor: string;
  analyticsCounterId: number;
  bannerBackgroundColor: string;
  buttonBorderRadius: number;
  buttonTextColor: string;
  domain: string;
  embeds: Record<string, unknown>[];
  environment: Environment;
  facebookPixel?: string;
  fb?: string | typeof undefined;
  landingId: string;
  landingType: string;
  landingTheme: LandingThemeType;
  gallerys: Record<string, unknown>[];
  sliders: Record<string, unknown>[];
  googleAnalytics?: string;
  googleOptimize?: string;
  gtm: string;
  headerFont: string;
  isNewScheme: boolean;
  isPreviewFrameMode: boolean;
  isStoreBlockEnabled?: boolean;
  isTokenlessIntegration: boolean | null;
  isVideo?: boolean;
  launcherNewsInfo: Array<{
    blockId: string;
    launcherId: string;
    template: Record<string, unknown>;
  }>;
  locale: FiveSymbolLocale;
  locales: FiveSymbolLocale[];
  loginId: string;
  loginWidgetGeneration: 0 | 1 | null;
  mainFont: string;
  merchantId: string;
  modalBackgroundColor: string;
  originalTextColor: string;
  projectId: string;
  retailersData: RetailersType[];
  renderMode: RenderMode;
  blocksStructures: Array<StoreBlock | AbstractBlock>;
  widgets: WidgetsType | null;
  storeItemsGroups: Record<string, unknown>[];
  subscriptionPurchase: Subscription[];
  subscriptions: Record<string, unknown>[];
  textColor: string;
  tweet: string | typeof undefined;
  twitterPixel: string;
  isMultipage: boolean;
  cartId: string;
  userIdPlaceholders: Array<{
    text: string;
    blockId: string;
  }>;
  payStationReturnUrl: string;
  loginTheme: LoginTheme | Record<string, unknown>;
  cart: CartSettings;
};

const scripts: Partial<ScriptsState> = {
  gallerys: [],
  sliders: [],
  subscriptions: [],
  locale: 'en-US',
  locales: [],
  domain: '',
  gtm: '',
  fb: '',
  landingId: '',
  landingType: '',
  landingTheme: {
    accentColor: '',
    backgroundBlur: 0,
    buttonBorderRadius: 0,
    buttonTextColor: '',
    mainFont: '',
    headerFont: '',
    textColor: '',
    borderColor: '',
    secondaryColor: '',
    overlayColor: '',
  },
  tweet: '',
  twitterPixel: '',
  analyticsCounterId: 0,
  merchantId: '',
  projectId: '',
  loginId: '',
  embeds: [],
  loginWidgetGeneration: null,
  launcherNewsInfo: [],
  accentColor: '',
  buttonTextColor: '',
  textColor: '',
  originalTextColor: '',
  headerFont: '',
  mainFont: '',
  buttonBorderRadius: 0,
  bannerBackgroundColor: '',
  modalBackgroundColor: '',
  isNewScheme: true,
  isPreviewFrameMode: false,
  isTokenlessIntegration: null,
  subscriptionPurchase: [],
  environment: Environment.DEV,
  blocksStructures: [],
  widgets: null,
  storeItemsGroups: [],
  retailersData: [],
  renderMode: RenderMode.PREVIEW,
  isMultipage: false,
  cartId: '',
  userIdPlaceholders: [],
  payStationReturnUrl: '',
  loginTheme: {},
  cart: {
    enable: false,
  },
};

export const ADD_SCRIPT_DATA = 'ADD_SCRIPT_DATA';
export const SET_SERVER_DATA = 'SET_SERVER_DATA';

export const addScriptData =
  (name: string, data: Record<string, unknown>) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { isServerSideRender },
    } = getState();
    if (isServerSideRender) {
      dispatch({
        type: ADD_SCRIPT_DATA,
        name,
        data,
      });
    }
  };

export const setServerData =
  (name: string, data: Record<string, unknown>) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { isServerSideRender },
    } = getState();
    if (isServerSideRender) {
      dispatch({
        type: SET_SERVER_DATA,
        name,
        data,
      });
    }
  };

export default function scriptsReducer(
  state: Partial<ScriptsState> = scripts,
  action: Action
) {
  switch (action.type) {
    case ADD_SCRIPT_DATA:
      return { ...state, [action.name]: [...state[action.name], action.data] };
    case SET_SERVER_DATA:
      return { ...state, [action.name]: action.data };
    default:
      return state;
  }
}
