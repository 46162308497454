/** @prettier */
// @flow
import React from 'react';

import './style.pcss';

type Props = {
  text: string,
};

const ErrorMessage = ({ text }: Props) => (
  <span className='exp-error'>{text}</span>
);

export default ErrorMessage;
