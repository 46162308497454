import Color from 'color';

const detectModalBackgroundColor = (
  pictureBackground: string,
  textColor: string
) => {
  const tintColor = Color(pictureBackground);
  if (Color(textColor).isLight() && Color(tintColor).isLight()) {
    return '#333333';
  }
  if (Color(textColor).isDark() && Color(tintColor).isDark()) {
    return '#FFFFFF';
  }
  // @ts-ignore
  if (tintColor.valpha > 0) {
    return tintColor.opaquer(100).rgb().toString() as string;
  }
  return Color(textColor).isLight() ? '#333333' : '#FFFFFF';
};

export default detectModalBackgroundColor;
