/**
 * @flow
 * @prettier
 */

import { RenderMode } from '@site-builder/common/src/types/build';
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';

import type { PlanModelElement, PlanModelMap } from './planModels';
import type { SubscriptionsPSSettingsProps } from '@site-builder/common/src/flow-types/subscriptionsPacks/types';

import { isSandboxMode } from '../../../../../scripts/helpers/sandbox';
import { getTokenSettings } from '../../../../../utils/paystation/paystation-token';
import { api } from '../../utils';

export const getTokenDataForSub = ({
  projectId,
  locale,
  renderMode,
  payStationReturnUrl,
}: {
  projectId: string,
  locale: FiveSymbolLocale,
  renderMode: RenderMode,
  payStationReturnUrl: string,
}) => {
  const settings = getTokenSettings({
    projectId,
    locale,
    renderMode,
    payStationReturnUrl,
  });

  return {
    locale: settings.language,
    sandbox: isSandboxMode(renderMode),
    return_url: settings.return_url,
    ui: settings.ui,
  };
};

export const buySubscriptions = async (
  planId: string,
  projectId: string,
  token: string,
  settings: SubscriptionsPSSettingsProps
) => {
  const response = await api('buy', {
    projectId,
    query: {},
    headers: {
      token,
    },
    body: JSON.stringify({
      plan_external_id: planId,
      settings,
    }),
    method: 'post',
  });

  if (response.status !== 200) {
    return {
      success: false,
      link: '',
    };
  }

  return {
    success: true,
    link: response.json.link_to_ps,
  };
};

export const manageSubscriptions = async (
  projectId: string,
  token: string,
  settings: SubscriptionsPSSettingsProps
) => {
  const response = await api('manage', {
    projectId,
    query: {},
    headers: {
      token,
    },
    body: JSON.stringify({
      settings,
    }),
    method: 'post',
  });

  if (response.status !== 200) {
    return {
      success: false,
      link: '',
    };
  }

  return {
    success: true,
    link: response.json.link_to_ps,
  };
};

export const renewSubscriptions = async (
  subscriptionId: null | number,
  projectId: string,
  token: string,
  settings: SubscriptionsPSSettingsProps
) => {
  const response = await api('renew', {
    subscriptionId,
    projectId,
    query: {},
    headers: {
      token,
    },
    body: JSON.stringify({
      settings,
    }),
    method: 'post',
  });

  if (response.status !== 200) {
    return {
      success: false,
      link: '',
    };
  }

  return {
    success: true,
    link: response.json.link_to_ps,
  };
};

export const getUserSubscription = async (projectId: string, token: string) => {
  const response = await api('subscriptions', {
    projectId,
    query: {},
    headers: { token },
    method: 'get',
  });

  if (response.status !== 200) {
    return [];
  }

  return response.json.items;
};

export const shallowMatchObj = (a: any, b: any): boolean => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  // cant perform `for`, iterators/generators require regenerator-runtime
  const compare = aKeys.map((key) => a[key] === b[key]);
  const isOk = compare.findIndex((el) => el === false);

  return !(isOk >= 0);
};

// special mapping for flow, to fix PlanModel missing type
export const objValues = (objs: PlanModelMap): PlanModelElement[] =>
  Object.keys(objs).map((key) => objs[key]);
