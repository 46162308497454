/** @prettier */
// @flow
import type { ScriptsData } from '../types';

import { getPayStationTokenForBBWidget } from '../../utils/paystation/paystation-token';
import { BuyButtonWidgetOptionsBuilder } from '../../utils/services/buyButtonWidget';
import { StoreAPIDataService } from '../blocks/store/services/StoreAPIDataService';
import events from '../events';
import { getTokenFromCookie } from '../helpers/common';
import { isSandboxMode } from '../helpers/sandbox';
import { getP2PScript } from '../helpers/thirdPartyScripts';
import { renderUniversalItemButton } from './buyUniversalItem';

export const renderGameKeyButton = async ({
  data: {
    merchantId,
    projectId,
    analyticsCounterId,
    loginId,
    locale,
    isTokenlessIntegration,
    isPreviewFrameMode,
    renderMode,
    payStationReturnUrl,
  },
  gameKey: { sku, drm, target, projectId: projectIdRetailer },
}: {
  data: ScriptsData,
  gameKey: {
    sku: string,
    drm: string,
    target: string,
    projectId?: string,
  },
}) => {
  const button = document.querySelector(target);
  if (!target || !button) {
    return;
  }

  // check that Xsolla Buy Button is downloaded and available in runtime
  const { XBuyButtonWidget } = await getP2PScript();
  if (!XBuyButtonWidget) {
    return;
  }

  const widgetOptions = new BuyButtonWidgetOptionsBuilder({
    projectId: projectIdRetailer || projectId,
    selector: target,
    analyticsCounterId,
    locale,
  });

  const userToken = getTokenFromCookie(isPreviewFrameMode);
  if (userToken && !isTokenlessIntegration) {
    button.classList.remove('xl_auth');
    const psToken = await getPayStationTokenForBBWidget({
      merchantId,
      projectId: projectIdRetailer || projectId,
      isPreviewFrameMode,
      userToken,
      renderMode,
      locale,
      analyticsCounterId,
      payStationReturnUrl,
      purchase: {
        pin_codes: {
          codes: [
            {
              digital_content: sku,
              drm,
            },
          ],
        },
      },
    });

    XBuyButtonWidget.create(
      widgetOptions
        .withAccessToken(psToken.token)
        .withSandbox(isSandboxMode(renderMode))
        .construct()
    );
    return;
  }

  XBuyButtonWidget.create(
    widgetOptions
      .withSandbox(isSandboxMode(renderMode))
      .withSku(sku)
      .withDrm(drm)
      .construct()
  );

  const needOpenLogin = !isTokenlessIntegration && loginId;
  if (needOpenLogin) {
    button.classList.add('xl_auth');
    button.addEventListener(
      'click',
      (e: Event) => {
        e.stopPropagation();
        // $FlowFixMe
        document.querySelector('.xl_auth').click();
      },
      false
    );
  }
};

export const initGameKeyEventHandler = () => {
  window.addEventListener(
    events.gameKey.init,
    (event) => {
      const { gameKey, data, storeAPIDataService } = event.detail;
      if (data.isNewScheme) {
        const gameKeyAsUniversalItem = { ...gameKey };
        // $FlowFixMe
        renderUniversalItemButton({
          universalItem: gameKeyAsUniversalItem,
          data,
          storeAPIDataService,
          requiresBuyButtonWidget: true,
        });
      } else {
        renderGameKeyButton({ gameKey, data });
      }
    },
    false
  );
};

export const handleGameKey = ({
  data,
  gameKey,
  storeAPIDataService,
}: {
  data: ScriptsData,
  gameKey: {
    sku: string,
    target: string,
  },
  storeAPIDataService: StoreAPIDataService,
}) => {
  const event = new CustomEvent(events.gameKey.init, {
    detail: {
      data,
      gameKey,
      storeAPIDataService,
    },
  });
  window.dispatchEvent(event);
};
