// @flow
import { RenderMode } from '@site-builder/common/src/types/build';

let landingAPISetting: null | boolean = null;

export const setSandboxMode = (value: boolean) => {
  landingAPISetting = value;
};

export const isSandboxMode = (renderMode: RenderMode = 'preview') => {
  if (landingAPISetting != null) {
    return landingAPISetting;
  }
  return renderMode === RenderMode.PREVIEW;
};
