import { RenderMode } from '@site-builder/common/src/types/build';
import {
  FiveSymbolLocale,
  fiveSymbolsMap,
} from '@site-builder/common/src/types/locale';

import { STORE_API } from '../../config';
import { buyItem } from '../../scripts/blocks/store/api';
import { getCountryCode } from '../../scripts/helpers/common';
import { isSandboxMode } from '../../scripts/helpers/sandbox';
import { getPaystationCustomParameters } from '../../scripts/landingAPI/pay-station-custom-parameters';
import { getPaystationWidgetUISettings } from '../../scripts/landingAPI/payStationWidgetSettings';
import { ICreateTokenForItem } from '../../types/paystation/create-oreder-token';
import {
  IPayStationCreateTokenData,
  IPayStationCreateTokenSettings,
  IPayStationCreateTokenUISettings,
} from '../../types/paystation/create-token';
import { mount } from '../../ui-components/settings/Loader';
import { parseJwt } from '../parse-jwt';
import { getAnalyticsTag } from '../store-helper';
import { isMobile } from './open-widget';

const openSpinner = () => {
  return mount({
    isLandingPage: true,
    spinner: 'ps',
  });
};

export const getTokenSettings = ({
  userToken,
  projectId,
  locale,
  analyticsCounterId,
  renderMode,
  payStationReturnUrl,
  ui,
}: {
  projectId: string;
  renderMode: RenderMode;
  locale: FiveSymbolLocale;
  userToken?: string;
  payStationReturnUrl?: string;
  analyticsCounterId?: number;
  ui?: IPayStationCreateTokenUISettings;
}): IPayStationCreateTokenSettings => {
  const tokenData: any = userToken ? parseJwt(userToken) : {};
  let settings: IPayStationCreateTokenSettings = {
    project_id: Number(projectId),
    language: fiveSymbolsMap[locale],
    disable_saved_methods: tokenData.type === 'server_custom_id',
    ui: {
      ...getPaystationWidgetUISettings(),
      ...ui,
    },
  };
  if (isSandboxMode(renderMode)) {
    settings.mode = 'sandbox';
  }
  if (isMobile()) {
    settings = {
      ...settings,
      ui: {
        ...settings.ui,
        version: 'mobile',
        mobile: {
          ...settings.ui?.mobile,
          header: {
            close_button: true,
          },
        },
      },
    };
    if (!payStationReturnUrl) {
      settings.return_url = window.location.href;
    }
  }
  if (analyticsCounterId) {
    settings.xsolla_product_tag = getAnalyticsTag(analyticsCounterId);
  }

  return settings;
};

const getTokenDataForBB = ({
  user,
  settings,
  purchase,
  customParameters,
}: {
  user?: any;
  settings: IPayStationCreateTokenSettings;
  purchase?: any;
  customParameters?: any;
}): IPayStationCreateTokenData => {
  const data = {
    user,
    purchase,
    settings,
    custom_parameters: getPaystationCustomParameters()
      ? {
          ...customParameters,
          ...getPaystationCustomParameters(),
        }
      : customParameters,
  };
  const country = getCountryCode();
  if (country && user) {
    data.user.country = {
      value: country,
      allow_modify: false,
    };
  }
  return data;
};

const getTokenSettingsForOrder = ({
  settings,
  renderMode,
  quantity,
  userName,
  customParameters,
}: {
  settings: IPayStationCreateTokenSettings;
  renderMode: RenderMode;
  quantity?: number;
  userName?: string;
  customParameters?: Record<string, any>;
}): ICreateTokenForItem => {
  const data: ICreateTokenForItem = {
    quantity,
    locale: settings.language,
    sandbox: isSandboxMode(renderMode),
    settings: {
      ui: settings.ui,
      return_url: settings.return_url,
      xsolla_product_tag: settings.xsolla_product_tag,
      disable_saved_methods: settings.disable_saved_methods,
    },
    custom_parameters: getPaystationCustomParameters()
      ? {
          ...customParameters,
          ...getPaystationCustomParameters(),
        }
      : customParameters,
  };
  if (userName) {
    data.user = { name: userName };
  }
  return data;
};

export const getPayStationToken = async ({
  userToken,
  sku,
  quantity,
  userName,
  projectId,
  locale,
  analyticsCounterId,
  renderMode,
  payStationReturnUrl,
  ui,
  customParameters,
}: {
  userToken: string;
  sku: string;
  projectId: string;
  renderMode: RenderMode;
  locale: FiveSymbolLocale;
  quantity?: number;
  userName?: string;
  payStationReturnUrl?: string;
  analyticsCounterId?: number;
  ui?: IPayStationCreateTokenUISettings;
  customParameters?: Record<string, any>;
}) => {
  const spinnerCloseFunction = openSpinner();
  const body = getTokenSettingsForOrder({
    settings: getTokenSettings({
      userToken,
      projectId,
      payStationReturnUrl,
      locale,
      analyticsCounterId,
      renderMode,
      ui,
    }),
    renderMode,
    quantity: quantity || 1,
    userName,
    customParameters,
  });
  const res = await buyItem({
    userToken,
    projectId,
    sku,
    body,
  });
  spinnerCloseFunction();
  return res.token as string;
};

export const getPayStationTokenForBBWidget = async ({
  merchantId,
  projectId,
  isPreviewFrameMode,
  userToken,
  renderMode,
  locale,
  analyticsCounterId,
  payStationReturnUrl,
  ui,
  purchase,
  customParameters,
}: {
  merchantId: string;
  projectId: string;
  isPreviewFrameMode: boolean;
  userToken: string;
  renderMode: RenderMode;
  locale: FiveSymbolLocale;
  analyticsCounterId: number;
  payStationReturnUrl: string;
  ui?: IPayStationCreateTokenUISettings;
  purchase?: any;
  customParameters?: any;
}): Promise<string> => {
  const spinnerCloseFunction = openSpinner();
  const tokenPayload: any = parseJwt(userToken);
  const data = getTokenDataForBB({
    user: {
      id: {
        value: tokenPayload?.sub,
      },
      name: {
        value: tokenPayload?.email,
      },
    },
    settings: getTokenSettings({
      projectId,
      locale,
      payStationReturnUrl,
      ui,
      renderMode,
      analyticsCounterId,
    }),
    purchase,
    customParameters,
  });
  const url = isPreviewFrameMode
    ? `/api/merchant/${merchantId}/token`
    : `${STORE_API}/v1/xsolla_login/paystation/token`;
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }),
    body: JSON.stringify({
      xsolla_login_user_id: data.user?.id.value,
      token_data: data,
    }),
  });
  spinnerCloseFunction();
  return (await res.json()).token as string;
};

export const headerForCart = ({ isAuth, userToken, unAuthorizationToken }) => {
  const commonHeader = {
    'Content-Type': 'application/json',
  };
  return isAuth
    ? { ...commonHeader, Authorization: `Bearer ${userToken}` }
    : { ...commonHeader, 'x-unauthorized-id': unAuthorizationToken };
};

export const getPSTokenForCart = async ({
  projectId,
  cartId,
  userToken,
  isAuth,
  xUser,
  locale,
  renderMode,
  payStationReturnUrl,
  unAuthorizationToken,
}: {
  projectId: string;
  cartId: string;
  isAuth: boolean;
  xUser: string;
  locale: FiveSymbolLocale;
  renderMode: RenderMode;
  payStationReturnUrl: string;
  userToken?: string;
  unAuthorizationToken?: string;
}) => {
  const url = `${STORE_API}/v2/project/${projectId}/payment/cart/${cartId}`;
  const headers = {
    ...headerForCart({ isAuth, userToken, unAuthorizationToken }),
  };
  if (xUser) {
    headers['x-user'] = btoa(JSON.stringify(xUser));
  }

  const options: ICreateTokenForItem = getTokenSettingsForOrder({
    settings: getTokenSettings({
      userToken,
      projectId,
      locale,
      renderMode,
      payStationReturnUrl,
    }),
    renderMode,
  });

  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers(headers),
    body: JSON.stringify(options),
  });
  return (await res.json()).token as string;
};
