/** @prettier */
// @flow

import type {
  ConfigMiddleware,
  LoginWidgetRoute,
} from '../blocks/user-account-services';

import {
  openLogin,
  setConfigMiddleware as _setConfigMiddleware,
} from '../blocks/user-account-services';

export { LOGIN_WIDGET_ROUTES as WIDGET_ROUTES } from '../blocks/user-account-services';

export const open = (
  props: $Shape<{
    route: LoginWidgetRoute,
  }> = {}
) => {
  openLogin(props);
};

export const setConfigMiddleware = (middleware: ConfigMiddleware) => {
  _setConfigMiddleware(middleware);
};
