/** @prettier */
import React from 'react';
import ReactDOM from 'react-dom';

import Authorization from '../../../experiments/partnersRequests/modals/Authorization';
import UserAccount from '../../../experiments/partnersRequests/modals/UserAccount';
import { getCustomTranslation } from '../../../experiments/partnersRequests/utils';
import { CookieNames } from '../../../utils/cookie';
import { parseJwt } from '../../../utils/parse-jwt';
import { isAfterRegistration } from '../../beforeBlocks/urlSearchParams';
import { getCookie } from '../../helpers/common';
import { setCartNotification } from './cartNotification';

export const BUY_BUTTON_FIRST_CLICK = 'buy-button-first';

export default (data) => {
  const { auth, locale } = data;
  const translations = getCustomTranslation(locale);
  const checkoutButton = document.querySelector(
    '.ui-site-cart-modal__checkout-button'
  );
  const headerUserAccount = document.querySelector('.header-user-account');
  const loginButton = document.querySelector('.login-button');
  const buyButtons = document.querySelectorAll('[data-require-auth="true"]');
  const mountNode = document.getElementById('experiments');
  const userToken = getCookie(CookieNames.USER_LOGIN_TOKEN);
  const searchParams = new URLSearchParams(window.location.search);
  const customErrorDescription = searchParams.has('error_description');

  let showErrorMessage = false;

  const renderAuthorizationModal = () => {
    ReactDOM.render(
      <Authorization
        loginWidgetId={auth.loginId}
        translations={translations}
        mountNode={mountNode}
        showErrorMessage={showErrorMessage}
      />,
      mountNode,
      () => {
        if (showErrorMessage) {
          window.history.replaceState(
            '',
            '',
            window.location.origin + window.location.pathname
          );
        }
      }
    );
  };

  if (sessionStorage.getItem('logOutFromSocialNetwork') === 'true') {
    renderAuthorizationModal();
    sessionStorage.setItem('logOutFromSocialNetwork', '');
  }

  const renderUserAccountModal = (buttonId) => {
    const userData = parseJwt(userToken);

    ReactDOM.render(
      <UserAccount
        userData={userData}
        translations={translations}
        mountNode={mountNode}
        buttonId={buttonId}
      />,
      mountNode
    );
  };

  const renderAuthorizationModalAfterClick = (button) => {
    const source = button.closest('[data-buy-button-item]');
    localStorage.setItem(BUY_BUTTON_FIRST_CLICK, source.id);
    renderAuthorizationModal();
  };

  if (customErrorDescription) {
    showErrorMessage = true;
    renderAuthorizationModal();
  }

  if (loginButton) {
    loginButton.addEventListener('click', () => {
      showErrorMessage = false;
      localStorage.removeItem(BUY_BUTTON_FIRST_CLICK);
      renderAuthorizationModal();
    });
  }

  if (checkoutButton && !userToken) {
    checkoutButton.addEventListener('click', () => renderAuthorizationModal());
  }

  if (headerUserAccount) {
    headerUserAccount.addEventListener('click', () => renderUserAccountModal());
  }

  if (isAfterRegistration()) {
    const buttonId = localStorage.getItem(BUY_BUTTON_FIRST_CLICK);
    renderUserAccountModal(buttonId);
  }

  if (buyButtons.length > 0 && !userToken) {
    buyButtons.forEach((button) => {
      button.addEventListener('click', () => {
        renderAuthorizationModalAfterClick(button);
      });
    });
  }

  setCartNotification();
};
