// @flow
import type { FiveSymbolLocale } from '../types/locale';
import type { LandingWithStructure } from '../types/model/landing';
import type { ProjectDetails } from '../types/model/project';
import type { Retailers } from '../types/model/retailers';
import type { Subscription } from '../types/model/subscription';
import type { Sku } from './sku';
import type { StoreItemsGroup } from './store';

export type Environment = 'development' | 'stage' | 'production';

export type IRenderMode = 'preview' | 'publication' | 'editing';

export const RenderMode = {
  PREVIEW: 'preview',
  PUBLICATION: 'publication',
  EDITING: 'editing'
};

export type PrepublishArgs = {|
  document: LandingWithStructure,
  features: Object[],
  locale: FiveSymbolLocale,
  SKUs: Sku[],
  subscriptionPlans: Subscription[],
  storeItemsGroups: StoreItemsGroup[],
  projectInfo: ProjectDetails,
  loginWidgetGeneration: { widget_generation: number },
  environment: Environment,
  renderMode: IRenderMode,
  userSubscription: string,
  retailersData: Retailers[],
  isMultipage: boolean
|};
