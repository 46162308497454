import {
  FiveSymbolLocale,
  fiveSymbolsMap,
} from '@site-builder/common/src/types/locale';

import { Theme } from '../../../utils/login/types';
import { xsollaAnalytics } from '../../helpers/analytics';
import { getBaseUrl, getSanitizedLoginRedirectUrl } from '../../helpers/common';
import { getVirtualCurrencyUserBalance } from '../store/api';
import { init, getWidgetGenerationInstance } from './generation';
import {
  IUserAccountServiceProps,
  UserAccountService,
} from './user-account-service';

export class UserAccountLoginService extends UserAccountService {
  balance: any;

  private readonly locale: FiveSymbolLocale;

  protected static instance: UserAccountLoginService | null = null;

  constructor({
    projectId,
    merchantId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountLoginServiceProps) {
    super({ projectId, merchantId });
    this.locale = locale;
    if (this.tokenData) {
      this.sendAnalytics();
      this.updateBalance();
    } else {
      this.initLoginWidget(loginId, loginWidgetGeneration, loginTheme);
    }
  }

  protected sendAnalytics() {
    window.dispatchEvent(
      new CustomEvent('sb_xsolla_login_success', {
        detail: this.token,
      })
    ); // event is used for restricting access to certain content https://developers.xsolla.com/doc/site-builder/how-to/how-to-restrict-access-to-certain-content-on-site/ //

    xsollaAnalytics((XA) =>
      XA.elementClick('xsolla_login_authentication_success', {
        ev: this.tokenData?.provider,
      })
    );
  }

  async updateBalance() {
    if (this.isAuthorized) {
      const locale = fiveSymbolsMap[this.locale];
      this.balance = await getVirtualCurrencyUserBalance(
        this.projectInfo.projectId,
        this.token,
        locale
      );
      this.notify();
    }
  }

  private initLoginWidget(
    loginId: string,
    loginWidgetGeneration: 0 | 1 | null,
    loginTheme: Theme
  ) {
    const payloadData = Object.create({});
    const callbackUrl = getBaseUrl();
    const redirectUrl = getSanitizedLoginRedirectUrl();
    if (callbackUrl !== redirectUrl) {
      payloadData.redirectUrl = redirectUrl;
    }
    if (!getWidgetGenerationInstance()) {
      init({
        loginWidgetGeneration,
        loginId,
        locale: this.locale,
        callbackUrl,
        payloadData,
        loginTheme,
      });
    }
  }

  static getInstance({
    projectId,
    merchantId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountLoginServiceProps) {
    if (!UserAccountLoginService.instance) {
      UserAccountLoginService.instance = new UserAccountLoginService({
        projectId,
        merchantId,
        locale,
        loginId,
        loginWidgetGeneration,
        loginTheme,
      });
    }
    return UserAccountLoginService.instance;
  }
}

export interface IUserAccountLoginServiceProps
  extends IUserAccountServiceProps {
  locale: FiveSymbolLocale;
  loginId: string;
  loginWidgetGeneration: 0 | 1 | null;
  loginTheme: Theme;
}
