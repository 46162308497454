// @flow
import { getYouTubeId } from '@site-builder/common/src/utils/video';
import cn from 'classnames';
import React from 'react';


import './style.pcss';

type Props = {
  videoSettings: {
    videoUrl: string,
    additionalClass?: string,
    isBackgroundVideo?: boolean
  }
};

const YouTubeVideo = ({ videoSettings: { videoUrl, additionalClass = '', isBackgroundVideo } }: Props) => {
  const videoId = getYouTubeId(videoUrl);
  const videoSrc = isBackgroundVideo
    ? `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&rel=0&showinfo=0&enablejsapi=1&mute=1&playlist=${videoId}&loop=1`
    : `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&enablejsapi=1&iv_load_policy=3`;
  return (
    <iframe
      className={cn('ui-site-youtube__video', additionalClass)}
      title='video'
      frameBorder='0'
      allowFullScreen
      allow='autoplay; encrypted-media'
      src={videoSrc}
    />
  );
};
export default YouTubeVideo;
