/**
 * @flow
 * @prettier
 */

export const INIT_BLOCKS: 'SUBSCRIPTIONS_PACKS/INIT_BLOCKS' =
  'SUBSCRIPTIONS_PACKS/INIT_BLOCKS';
export const INIT_BLOCK: 'SUBSCRIPTIONS_PACKS/INIT_BLOCK' =
  'SUBSCRIPTIONS_PACKS/INIT_BLOCK';
export const NETWORK_ERROR: 'SUBSCRIPTIONS_PACKS/NETWORK_ERROR' =
  'SUBSCRIPTIONS_PACKS/NETWORK_ERROR';
export const FETCHING_PLANS: 'SUBSCRIPTIONS_PACKS/FETCHING_PLANS' =
  'SUBSCRIPTIONS_PACKS/FETCHING_PLANS';
export const FETCHED_PLANS: 'SUBSCRIPTIONS_PACKS/FETCHED_PLANS' =
  'SUBSCRIPTIONS_PACKS/FETCHED_PLANS';
export const FETCHING_PRODUCTS: 'SUBSCRIPTIONS_PACKS/FETCHING_PRODUCTS' =
  'SUBSCRIPTIONS_PACKS/FETCHING_PRODUCTS';
export const FETCHED_PRODUCTS: 'SUBSCRIPTIONS_PACKS/FETCHED_PRODUCTS' =
  'SUBSCRIPTIONS_PACKS/FETCHED_PRODUCTS';
export const UPDATE_PRODUCT: 'SUBSCRIPTIONS_PACKS/UPDATE_PRODUCT' =
  'SUBSCRIPTIONS_PACKS/UPDATE_PRODUCT';
export const UPDATE_PLAN: 'SUBSCRIPTIONS_PACKS/UPDATE_PLAN' =
  'SUBSCRIPTIONS_PACKS/UPDATE_PLAN';

export const productsLimit: number = 50;
export const offset: number = 0;
