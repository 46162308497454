/**
 * Should return its full code by .toString() method
 * Used in packages/server/src/views/ssr/template.js
 * @param window
 */
export const init = (window) => {
  window.SB = {
    _callbacks: [],
    subscribe: (callback) => {
      window.SB._callbacks.push(callback);
    },
  };
};
