import unorphan from 'unorphan';

const fixWidowedText = () => {
  const selectors = [
    '.block--hero .ui-site-description',
    '.block--description .ui-site-description',
    '.block--packs .ui-site-description',
    '.block--faq .ui-site-description',
  ];
  const elements = [...document.querySelectorAll(selectors.join(', '))];
  const widowedElements = elements.reduce((nodesArr, element) => {
    const innerChildren = [...element.children];

    // fix widow word for children's texts
    if (innerChildren.length !== 0) {
      const children = innerChildren.filter(child => ['P', 'SPAN'].includes(child.tagName));
      return [...nodesArr, ...children];
    }
    // fix widow word for element text
    return [...nodesArr, element];
  }, []);
  unorphan(widowedElements);
};

export default fixWidowedText;
