// @flow

import { getYouTubeId } from '@site-builder/common/src/utils/video';

import type { Quality } from './types';


type YoutubeQuality = 'default' | 'mqdefault' | 'hqdefault' | 'sddefault' | 'maxresdefault';

const localQuality = (quality: Quality): YoutubeQuality => {
  switch (quality) {
    case 'hires':
      return 'maxresdefault';
    case 'medium':
    default:
      return 'mqdefault';
  }
};

export const getThumbnail = (quality: Quality) => (id: string) => `https://img.youtube.com/vi/${id}/${localQuality(quality)}.jpg`;

export const getIframeSrc = (url: string, isServerSideRender: boolean) => {
  const youTubeId: string = getYouTubeId(url);
  return `https://www.youtube.com/embed/${youTubeId}?autoplay=1&controls=0&rel=0&showinfo=0&enablejsapi=1&mute=1${isServerSideRender ? '' : `&playlist=${youTubeId}&loop=1`}`;
};
