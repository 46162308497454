/** @prettier */
import Toastify from 'toastify-js';

import { LOGIN_API } from '../../config';
import { xsollaAnalytics } from '../helpers/analytics';
import { getBaseUrl, getCookie } from '../helpers/common';
import { LANDING_LOCALE } from '../helpers/cookies';
import { fbEvent, twEvent } from '../helpers/pixels';

const SUCCESS_SUBSCRIPTION_EMAIL_SENDING = {
  'ar-AE':
    'لقد أرسلنا لك للتو رسالة تأكيد بالبريد الإلكتروني. يرجى اتباع الرابط في البريد الإلكتروني لتفعيل اشتراكك.',
  'bg-BG':
    'Току-що Ви изпратихме имейл с потвърждение. Моля, последвайте линка в имейла, за да активирате абонамента си.',
  'cs-CZ':
    'Právě jsme vám poslali potvrzovací e-mail. Chcete-li odběr aktivovat, klikněte na odkaz v e-mailu.',
  'de-DE':
    'Wir haben dir soeben eine Bestätigungs-E-Mail gesendet. Klicke auf den Link in der E-Mail, um dein Abonnement zu bestätigen.',
  'en-US':
    'We have just sent you a confirmation email. Please follow the link in the email to activate your subscription.',
  'es-ES':
    'Acabamos de enviarte un correo electrónico de confirmación. Pulsa en el enlace del correo para activar tu suscripción.',
  'fr-FR':
    'Nous venons de vous envoyer un e-mail de confirmation. Veuillez suivre le lien dans le courriel pour activer votre abonnement.',
  'he-IL':
    'שלחנו לך אימייל אימות. על מנת להפעיל את המנוי שלך, יש ללחוץ על הקישור בגוף האימייל.',
  'it-IT':
    "Ti abbiamo appena inviato un'e-mail di conferma. Segui il link contenuto nella email per attivare la tua iscrizione.",
  'ja-JP':
    '確認Eメールを送信しました。Eメールに記載されたリンクをクリックして、サブスクリプションを有効化してください。',
  'ko-KR':
    '확인 이메일을 보내 드렸습니다. 구독을 활성화하려면 이메일과 함께 보내드린 링크를 따라주세요.',
  'pl-PL':
    'Wysłaliśmy do Ciebie wiadomość e-mail z potwierdzeniem. Kliknij link w wiadomości, aby aktywować swoją subskrypcję.',
  'pt-BR':
    'Acabamos de enviar um e-mail de confirmação a você. Siga o link no e-mail para ativar sua inscrição.',
  'ro-RO':
    'Tocmai ți-am trimis un e-mail de confirmare. Te rugăm să urmezi linkul din e-mail pentru a-ți activa abonamentul.',
  'ru-RU':
    'На ваш email отправлено письмо. Перейдите по ссылке из письма, чтобы подтвердить подписку.',
  'th-TH':
    'เราได้ส่งอีเมลยืนยันให้คุณ โปรดคลิกลิงก์ในอีเมลเพื่อเปิดใช้งานการสมัครรับข่าวสาร',
  'tr-TR':
    'Size bir doğrulama e-postası gönderdik. Lütfen aboneliğinizi etkinleştirmek için e-postadaki bağlantıyı izleyin.',
  'vi-VN':
    'Chúng tôi vừa gửi email xác nhận cho bạn. Hãy theo liên kết trong email để kích hoạt gói đăng ký của bạn.',
  'zh-CN': '我们已向您发送了一封确认电子邮件。请点击邮件中的链接激活订阅。',
  'zh-TW':
    '我們已將電子郵件確認信傳送給您。請訪問電子郵件中的連結，即可啓用個人訂閱。',
};

const SUCCESS_SUBSCRIPTION_RETURN_FROM_EMAIL = {
  'ar-AE': 'لقد اشتركت بنجاح!',
  'bg-BG': 'Вие се абонирахте успешно!',
  'cs-CZ': 'Přihlášení k odběru novinek bylo úspěšné!',
  'de-DE': 'Du hast den Newsletter erfolgreich abonniert!',
  'en-US': 'You have successfully subscribed!',
  'es-ES': '¡Te has suscrito con éxito!',
  'fr-FR': 'Vous vous êtes inscrit avec succès!',
  'he-IL': 'נרשמת בהצלחה!',
  'it-IT': 'La tua iscrizione è avvenuta con successo!',
  'ja-JP': 'サブスクリプションに成功しました！',
  'ko-KR': '성공적으로 구독했습니다!',
  'pl-PL': 'Pomyślnie zapisałeś się do naszego newslettera!',
  'pt-BR': 'Você se inscreveu com sucesso!',
  'ro-RO': 'Te-ai abonat cu succes!',
  'ru-RU': 'Вы успешно подписаны!',
  'th-TH': 'คุณสมัครรับจดหมายข่าวสำเร็จแล้ว!',
  'tr-TR': 'Başarıyla abone oldunuz!',
  'vi-VN': 'Bạn đã đăng ký thành công!',
  'zh-CN': '您已成功订阅！',
  'zh-TW': '您已成功訂閱！',
};

const ERROR_SUBSCRIPTION_EMAIL_SENDING = {
  'ar-AE':
    'هناك خطأ ما. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بفريق التكامل لدينا على<a href="mailto:integration@xsolla.com">integration@xsolla.com</a>',
  'bg-BG':
    'Възникна проблем. Моля, опитайте по-късно или се свържете с нашия екип по интеграцията на <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'cs-CZ':
    'Něco se pokazilo. Zkuste to prosím znovu později nebo kontaktujte náš integrační tým na adrese <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'de-DE':
    "Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden Sie sich an unser Integrationsteam: <a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>.",
  'en-US':
    'Something went wrong. Please try again later or contact our integration team at <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'es-ES':
    'Se ha producido un error. Inténtalo de nuevo más tarde o contacta con nuestro equipo de integración: <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'fr-FR':
    'Quelque chose a mal tourné. Veuillez réessayer plus tard ou contacter notre équipe d\'intégration à <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'he-IL':
    'אירעה שגיאה. ניתן לנסות שנית מאוחר יותר או לפנות לצוות האינטגרציה שלנו בכתובת <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'it-IT':
    'Qualcosa è andato storto. Riprova più tardi o contatta il nostro team di integrazione all\'indirizzo <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'ja-JP':
    "問題が発生しました。時間を置いてもう一度お試しいただくか、当社の統合チーム<a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>までお問い合わせください.",
  'ko-KR':
    "문제가 발생했습니다. 나중에 다시 시도하거나 통합 팀(<a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>)에 문의하십시오.",
  'pl-PL':
    'Coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z naszym zespołem ds. integracji pod adresem <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'pt-BR':
    'Ocorreu um erro. Tente novamente mais tarde ou entre em contato com o nosso time de integração em<a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'ro-RO':
    'Ceva nu a funcționat corect. Te rugăm să încerci mai târziu sau să contactezi echipa noastră de experți la <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'ru-RU':
    'Произошла ошибка. Пожалуйста, повторите попытку позже или напишите нам на <a href="mailto:integration@xsolla.com"></a>.',
  'th-TH':
    'มีบางอย่างไม่ถูกต้อง โปรดลองอีกครั้งหรือติดต่อทีมงานบูรณาการที่<a href="mailto:integration@xsolla.com">integration@xsolla.com</a>',
  'tr-TR':
    'Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin veya <a href="mailto:integration@xsolla.com">integration@xsolla.com</a> adresinden entegrasyon ekibimizle iletişim geçin.',
  'vi-VN':
    'Có lỗi xảy ra. Vui lòng thử lại sau hoặc liên hệ với đội ngũ tích hợp của chúng tôi theo địa chỉ <a href="mailto:integration@xsolla.com">integration@xsolla.com</a>.',
  'zh-CN':
    "发生了问题。请稍后重试或联系我们的集成团队：<a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>.",
  'zh-TW':
    '出了點狀況。請稍後再試一次，或透過<a href="mailto:integration@xsolla.com">integration@xsolla.com</a>聯絡我們的整合團隊。',
};

const ERROR_SUBSCRIPTION_EMAIL_TAKEN = {
  'ar-AE': 'أنت مشترك بالفعل.',
  'bg-BG': 'Вие вече сте абонирани.',
  'cs-CZ': 'K odběru novinek jste se již přihlásili.',
  'de-DE': 'Du hast den Newsletter bereits abonniert.',
  'en-US': 'You are already subscribed.',
  'es-ES': 'Ya estás suscrito.',
  'fr-FR': 'Vous êtes déjà inscrit.',
  'he-IL': 'כבר יש לך מנוי.',
  'it-IT': 'Sei già iscritto.',
  'ja-JP': '既にサブスクリプションをお持ちです。',
  'ko-KR': '이미 구독하고 있습니다.',
  'pl-PL': 'Zapisałeś się już do naszego newslettera.',
  'pt-BR': 'Você já se inscreveu.',
  'ro-RO': 'Ești deja abonat.',
  'ru-RU': 'Вы уже подписаны.',
  'th-TH': 'คุณสมัครรับจดหมายข่าวแล้ว',
  'tr-TR': 'Zaten abonesiniz.',
  'vi-VN': 'Bạn đã đăng ký rồi.',
  'zh-CN': '您已订阅。',
  'zh-TW': '您已訂閱。',
};

export const ReturnCode = {
  SUBSCRIBED: 'Successfully subscribed',
  EMAIL_LINK_CONFIRM: 'Confirm subscription by the link in the email',
  ALREADY_SUBSCRIBED: 'User already subscribed',
  UNKNOWN_ERROR: 'Unknown error',
};

const SUCCESS_CODES = [ReturnCode.SUBSCRIBED, ReturnCode.EMAIL_LINK_CONFIRM];

const codeToMessages = (code) => {
  switch (code) {
    case ReturnCode.SUBSCRIBED:
      return SUCCESS_SUBSCRIPTION_RETURN_FROM_EMAIL;
    case ReturnCode.EMAIL_LINK_CONFIRM:
      return SUCCESS_SUBSCRIPTION_EMAIL_SENDING;
    case ReturnCode.ALREADY_SUBSCRIBED:
      return ERROR_SUBSCRIPTION_EMAIL_TAKEN;
    default:
      return ERROR_SUBSCRIPTION_EMAIL_SENDING;
  }
};

let subscribe = async ({ form, callbackUrl, loginId }) => {
  const email = form.elements.email.value;
  const response = await fetch(
    `${LOGIN_API}/subscribe/user?projectId=${loginId}&login_url=${callbackUrl}/?subscription=true`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    }
  );

  if (response.status < 400) {
    return ReturnCode.EMAIL_LINK_CONFIRM;
  }

  const payload = await response.json();

  if (payload.error.code === '003-004') {
    return ReturnCode.ALREADY_SUBSCRIBED;
  }

  return ReturnCode.UNKNOWN_ERROR;
};

export const setSubscribeFunction = (func) => {
  subscribe = func;
};

export default (() => {
  const classTemplate = 'ui-site-subscription ui-site-subscription--';
  const classDefault = `${classTemplate}default`;
  const classValid = `${classTemplate}valid`;
  const classSending = `${classTemplate}processing`;
  const notificationSettings = {
    duration: 60000,
    close: true,
    gravity: 'top',
    position: 'center',
    stopOnFocus: true,
  };

  const validateEmail = (data) => {
    const res = data.match(
      /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
    );
    return res !== null && true;
  };

  const onKeyUpHandler = (elementTarget) => {
    const element = elementTarget;
    const { value } = elementTarget.getElementsByTagName('input')[0];

    if (validateEmail(value)) {
      element.className = classValid;
      return;
    }
    element.className = classDefault;
  };

  const onSubmitHandler = async ({
    element,
    loginId,
    xaExtra,
    projectName,
    projectId,
    form,
  }) => {
    const callbackUrl = getBaseUrl();
    const notification = document.querySelectorAll('.landing-notification');

    if (notification.length > 2) {
      notification.forEach((e) => e.remove());
    }

    const locale = getCookie(LANDING_LOCALE) || 'en-US';

    if (validateEmail(form.elements.email.value) && loginId) {
      element.className = classSending;

      try {
        const code = await subscribe({
          form,
          loginId,
          callbackUrl,
        });

        if (SUCCESS_CODES.includes(code)) {
          fbEvent('Subscribe', {
            project_id: projectId,
            project_name: projectName,
          });
          twEvent('SignUp', {
            content_name: projectName,
          });

          xsollaAnalytics((XA) =>
            XA.elementClick('subscribe', { exv: xaExtra })
          );

          Toastify({
            text: codeToMessages(code)[locale],
            className: 'landing-notification landing-notification--success',
            ...notificationSettings,
          }).showToast();

          element.className = classValid;
        } else {
          Toastify({
            text: codeToMessages(code)[locale],
            className: 'landing-notification landing-notification--error',
            ...notificationSettings,
          }).showToast();

          element.className = classDefault;
        }
      } catch (e) {
        Toastify({
          text: ERROR_SUBSCRIPTION_EMAIL_SENDING[locale],
          className: 'landing-notification landing-notification--error',
          ...notificationSettings,
        }).showToast();

        element.className = classDefault;

        // to catch by loggers
        throw e;
      }
    }
  };

  return async ({
    subscription,
    domain: projectName,
    projectId,
    googleAnalytics: partnerGA,
  }) => {
    const successReturnFromEmailSubscription =
      SUCCESS_SUBSCRIPTION_RETURN_FROM_EMAIL[
        getCookie(LANDING_LOCALE) || 'en-US'
      ];

    const searchParams = new URLSearchParams(window.location.search);
    const isRedirectFromEmail = searchParams.get('subscription') === 'true';
    searchParams.delete('subscription');
    if (isRedirectFromEmail) {
      searchParams.delete('error');
      Toastify({
        text: successReturnFromEmailSubscription,
        className: 'landing-notification landing-notification--success',
        ...notificationSettings,
      }).showToast();
      const url =
        searchParams.toString() !== ''
          ? `${document.location.pathname}?${searchParams}`
          : document.location.pathname;
      window.history.replaceState('', '', url);
    }

    const element = document.getElementById(subscription.elementId);
    const { loginId } = subscription;
    const buttonElement = element.getElementsByTagName('button')[0];
    if (!element || !buttonElement) {
      return false;
    }

    const { xaExtra } = buttonElement.dataset;

    element.addEventListener('submit', (e) => {
      e.preventDefault();
      onSubmitHandler({
        element,
        loginId,
        subscription,
        xaExtra,
        projectName,
        partnerGA,
        projectId,
        form: e.target,
      });
    });
    element.addEventListener('keyup', () => onKeyUpHandler(element));

    return true;
  };
})();
