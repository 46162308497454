const xsollaAnalytics = callback => {
  if (window.XA) {
    callback(window.XA);
    return;
  }
  
  document.addEventListener('XALoad', e => {
    callback(e.XA);
  });
};

export {
  xsollaAnalytics, xsollaAnalytics as default
};
