const cssClasses = {
  dropdownNativeSelect: 'store__drm-native-select',
  dropdownBtn: 'store__buy-btn--drm-selector',
  dropdownOpened: 'store__buy-btn--drm-selector-opened',
  dropdownParent: '[data-buy-button-item]',
  dropdownParentWithOpenedDropdown: 'store__item-wrapper--dropdown-opened'
};

const closeAllSelectors = () => {
  document
    .querySelectorAll(`.${cssClasses.dropdownOpened}`)
    .forEach(el => {
      el.classList.remove(cssClasses.dropdownOpened);
      el.closest(cssClasses.dropdownParent).classList.remove(cssClasses.dropdownParentWithOpenedDropdown);
    });
};

const initStoreDropdown = () => {
  document.addEventListener(
    'click',
    event => {
      const targetEl = event.target;
      const isNativeSelect = targetEl.matches(`.${cssClasses.dropdownNativeSelect}`);
      const isSelectorChild = targetEl.closest(`.${cssClasses.dropdownBtn}`);
      const isSelectorTarget = !isNativeSelect
        && (targetEl.matches(`.${cssClasses.dropdownBtn}`) || isSelectorChild);
      if (isSelectorTarget) {
        if (targetEl.closest(`.${cssClasses.dropdownBtn}`).classList.contains(cssClasses.dropdownOpened)) {
          targetEl.closest(cssClasses.dropdownParent).classList.remove(cssClasses.dropdownParentWithOpenedDropdown);
          return targetEl.closest(`.${cssClasses.dropdownBtn}`).classList.remove(cssClasses.dropdownOpened);
        }
        closeAllSelectors();
        targetEl.closest(cssClasses.dropdownParent).classList.add(cssClasses.dropdownParentWithOpenedDropdown);
        return targetEl.closest(`.${cssClasses.dropdownBtn}`).classList.add(cssClasses.dropdownOpened);
      }
      return closeAllSelectors();
    },
    false
  );
};

export {
  initStoreDropdown
};
