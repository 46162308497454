export interface IModalWindow extends HTMLElement {
  callback?: () => void;
}

const openModalWindow = (
  modal: Element | null,
  modalName: string,
  callback?: () => void
) => {
  if (!modal) {
    throw new Error(`The ${modalName} modal is not present on Landing`);
  }
  const openedModal = modal as IModalWindow;
  openedModal.hidden = false;
  openedModal.callback = callback;
  document.body.style.overflow = 'hidden';
};

const closeModalWindow = (modal: IModalWindow) => {
  modal.hidden = true;
  modal.callback?.();
  document.body.style.overflow = 'visible';
};

const initialize = (modal: HTMLElement) => {
  if (!modal) {
    return;
  }

  const closeButton = modal.querySelector('.ui-site-modal-window__close');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      closeModalWindow(modal);
    });
  }

  modal.addEventListener('click', (event: MouseEvent) => {
    if (event.target === modal) {
      closeModalWindow(modal);
    }
  });

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModalWindow(modal);
    }
  });
};

export { openModalWindow, closeModalWindow, initialize };
