/** @prettier */
// @flow
import React from 'react';

import './style.pcss';

type Props = {
  url: string,
  text: string,
};

const Link = ({ url, text }: Props) => (
  <a className='exp-link' href={url} target='_blank' rel='noopener noreferrer'>
    {text}
  </a>
);

export default Link;
