/** @prettier */
import React from 'react';
import { createBreakpoint } from 'react-use';

import desktopLogo from '../../../assets/images/experiments/logo_desktop.png';
import mobileLogo from '../../../assets/images/experiments/logo_mobile.png';

import './style.pcss';

const useBreakpoint = createBreakpoint({ desktop: 576, mobile: 575 });

const Logo = () => {
  const breakpoint = useBreakpoint();

  return (
    <img
      src={breakpoint === 'desktop' ? desktopLogo : mobileLogo}
      className={`exp-logo exp-logo--${breakpoint}`}
      alt='text'
    />
  );
};

export default Logo;
