// @flow
import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';
import type { Asset, AssetRaw } from '@site-builder/common/src/flow-types/model/asset';
import type { Landing } from '@site-builder/common/src/flow-types/model/landing';
import type { ImageValue } from '@site-builder/common/src/flow-types/values';

import { openErrorPopup } from '../../../ui-components/settings/Error';
import * as API from '../../../utils/api';
import { twinkleElement } from '../../../utils/common-helper';
import { initSavingProcess, dataSaved, dataDontSave } from './spaces';

export const FETCH_ASSETS = 'FETCH_ASSETS';
export const INIT_NEW_ASSETS = 'INIT_NEW_ASSETS';
export const DELETE_ASSET = 'DELETE_ASSET';
export const ADD_ASSET = 'ADD_ASSET';
export const OPEN_ASSET_SETTING = 'OPEN_ASSET_SETTING';
export const UPDATE_CURRENT_ASSET = 'UPDATE_CURRENT_ASSET';
export const ASSETS_CLOSE = 'ASSETS_CLOSE';

export type State = {|
  assets: Array<Asset & { _id: string}>, // TODO
  assetsIsActive: boolean,
  assetFunction: Function,
  currentAsset: Asset | Object,
  assetInSetup: boolean,
  canSetVideo: boolean,
  canSetTint: boolean,
  assetsLabel: string,
  uploading: boolean,
  isCoverAsset: boolean,
  isBlockBackground: boolean
|};

type IAssetFunction = (data: Asset) => void | mixed;

const initialState: State = {
  assets: [],
  assetsIsActive: false,
  assetFunction: () => {},
  currentAsset: {},
  assetInSetup: false,
  canSetVideo: false,
  canSetTint: false,
  assetsLabel: '',
  uploading: false,
  isCoverAsset: false,
  isBlockBackground: false
};

export const fetchAssets = (landing: Landing) => async (dispatch: Dispatch) => {
  const { merchantId, projectId, _id: documentId } = landing;
  const response = await API.getImages({ merchantId, projectId, collectionId: documentId });
  if (response.status === 200) {
    dispatch({
      type: FETCH_ASSETS,
      payload: { assets: [...response.data] }
    });
  }
};

export const initNewAssets = (countNewAssets: number) => (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { assets: { assets } } = getState();

  dispatch({
    type: INIT_NEW_ASSETS,
    assets: [...new Array(countNewAssets), ...assets],
    uploading: true
  });
};

export const setAssets = ({
  assetFunction,
  currentAsset,
  canSetVideo = false,
  canSetTint = false,
  assetInSetup = true,
  assetsLabel = 'assets',
  uploading = false,
  isCoverAsset = false,
  isBlockBackground = false
}: {|
  assetFunction: IAssetFunction,
  currentAsset: Asset | ImageValue,
  canSetVideo?: boolean,
  canSetTint?: boolean,
  assetInSetup?: boolean,
  assetsLabel?: string,
  uploading?: boolean,
  isCoverAsset?: boolean,
  isBlockBackground?: boolean
|}) => (dispatch: Dispatch) => {
  dispatch({
    type: OPEN_ASSET_SETTING,
    assetFunction,
    currentAsset,
    assetInSetup,
    assetsIsActive: true,
    assetsLabel,
    canSetVideo,
    canSetTint,
    uploading,
    isCoverAsset,
    isBlockBackground
  });
  twinkleElement(1);
};

export const controlCurrentAsset = (
  asset: Asset & AssetRaw, saveAsset: boolean = true, callAssetFunction: boolean = true
) => (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { assets: { assetFunction, currentAsset, isBlockBackground } } = getState();

  const color = asset.color || currentAsset.color || 'rgba(0,0,0,0.0)';
  const img = asset.img === '' ? '' : asset.img || asset.url || currentAsset.img || '';
  const size = asset.size || currentAsset.size || 'contain';
  const type = asset.type || currentAsset.type || 'image';
  const video = asset.video === '' ? '' : asset.video || currentAsset.video || '';
  const player = asset.player === '' ? '' : asset.player || currentAsset.player || '';
  const backgroundAttachment = isBlockBackground ? (asset.backgroundAttachment || currentAsset.backgroundAttachment || 'scroll') : 'scroll';

  const resultAsset = {
    color, enable: true, img, size, type, video, player, backgroundAttachment
  };

  if (callAssetFunction && assetFunction) {
    if (saveAsset) {
      dispatch({
        type: UPDATE_CURRENT_ASSET,
        currentAsset: resultAsset
      });
    }
    assetFunction(resultAsset, saveAsset);
  }
};

export const addAssets = (files: Object) => async (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { assets: { assets }, landing: { landing } } = getState();
  const newAssets = [...assets];

  dispatch(initSavingProcess());
  dispatch(initNewAssets(files.length));

  const promises = files.map(async file => {
    const data = new FormData();
    data.append('file', file, file.name);
    const { merchantId, projectId, _id: collectionId } = landing;
    return API.uploadImage({
      merchantId,
      projectId,
      collectionId,
      data
    });
  });

  let responses = [];

  try {
    responses = await Promise.all(promises);
    responses.forEach(response => {
      if (response.status >= 200 && response.status < 300) {
        return newAssets.unshift({
          ...response.data,
          url: response.data.url
        });
      }

      openErrorPopup(response.data.error.code);
      dispatch(dataDontSave());
      return false;
    });

    if (responses.every(({ status }) => status >= 200 && status < 300)) {
      dispatch(dataSaved());
    }
  } catch (error) {
    dispatch(dataDontSave(error));
    return false;
  }

  dispatch({
    type: ADD_ASSET,
    uploading: false,
    assets: newAssets
  });

  return responses;
};

export const deleteAsset = (assetId: string) => async (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { assets: { assets }, landing: { landing } } = getState();
  const { merchantId, projectId, _id: collectionId } = landing;
  dispatch(initSavingProcess());

  const response = await API.deleteImage({
    merchantId, projectId, collectionId, assetId
  });

  if (response.status >= 200 && response.status < 300) {
    dispatch({
      type: DELETE_ASSET,
      assets: assets.filter(asset => asset._id !== assetId)
    });
    dispatch(dataSaved());
  } else {
    dispatch(dataDontSave());
  }
};

export const assetsClose = () => (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const {
    assets: {
      assetsIsActive,
      assetInSetup,
      canSetVideo
    }
  } = getState();
  if (assetsIsActive || assetInSetup || canSetVideo) {
    dispatch({
      type: ASSETS_CLOSE,
      assetsIsActive: false,
      assetInSetup: false,
      canSetVideo: false,
      canSetTint: false,
      uploading: false
    });
  }
};

export default function reducerAssents(state: State = initialState, action: Action) {
  switch (action.type) {
    case FETCH_ASSETS:
      return { ...state, ...action.payload };
    case DELETE_ASSET:
      return {
        ...state,
        assets: action.assets
      };
    case INIT_NEW_ASSETS:
    case ADD_ASSET:
      return {
        ...state,
        assets: action.assets,
        uploading: action.uploading
      };
    case OPEN_ASSET_SETTING:
      return {
        ...state,
        assetFunction: action.assetFunction,
        currentAsset: action.currentAsset,
        assetInSetup: action.assetInSetup,
        assetsIsActive: action.assetsIsActive,
        canSetVideo: action.canSetVideo,
        canSetTint: action.canSetTint,
        assetsLabel: action.assetsLabel,
        uploading: action.uploading,
        isCoverAsset: action.isCoverAsset,
        isBlockBackground: action.isBlockBackground
      };
    case UPDATE_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: action.currentAsset
      };
    case ASSETS_CLOSE:
      return {
        ...state,
        assetInSetup: action.assetInSetup,
        assetsIsActive: action.assetsIsActive,
        canSetVideo: action.canSetVideo,
        canSetTint: action.canSetTint,
        uploading: action.uploading
      };
    default:
      return state;
  }
}
