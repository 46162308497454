import jwtDecode from 'jwt-decode';

import type { UserDataPayload } from '../scripts/blocks/user-account-services/types';

export const parseJwt = (token?: string): UserDataPayload | null => {
  if (!token) {
    return null;
  }
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};
