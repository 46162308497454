/** @prettier */
// @flow
import React from 'react';

import './style.pcss';

type Props = {
  text: string,
};

const Description = ({ text }: Props) => (
  <span className='exp-description'>{text}</span>
);

export default Description;
