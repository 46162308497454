const twoSymbolsMapForPublisher = {
  en: 'en-US',
  ru: 'ru-RU',
  ko: 'ko-KR',
  zh: 'zh-CN',
  de: 'de-DE',
  ja: 'ja-JP',
};

export { twoSymbolsMapForPublisher };
