const isYoutubeUrl = (url) =>
  url.match(/^https?:\/\/(?:www\.)?(youtu\.be|youtube\.com)/);

export const getYouTubeId = (url) => {
  const p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const match = url.match(p);
  return match ? match[1] : '';
};

export const getSteamVideoId = (url) => {
  const p = /\d+(?!%)(.)(?=\/movie)/g;
  const match = url.match(p);
  return match ? match[0] : false;
};

export const getVideoId = (url) =>
  getYouTubeId(url) || getSteamVideoId(url);

export const validateYoutubeVideoUrl = (url) => !!getYouTubeId(url);

export const validateSteamVideoUrl = (url) => !!getSteamVideoId(url);

export const validateVimeoUrl = (url) =>
  /^(?:https?:\/\/)?(www\.)?vimeo.com\/(\d+)\/?$/.test(url);

export const validateVideo = (url) => {
  if (isYoutubeUrl(url)) {
    return !!getYouTubeId(url);
  }

  return !!getSteamVideoId(url);
};
