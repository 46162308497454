/** @prettier */
// @flow
import React from 'react';

import './style.pcss';

type Props = {
  handleClick: Function,
  children: React$Node,
};

const Button = ({ handleClick, children }: Props) => (
  <button className='exp-button' onClick={handleClick}>
    {children}
  </button>
);

export default Button;
