import { closeLoginWidget } from '../modules/site-builder/loginWidgetInfo';
import { closeUserIdModal } from '../modules/site-builder/modals';
import { CLOSE_LOGIN_SETTINGS } from '../modules/site-builder/spaces';

export const closeModals = (store) => (next) => (action) => {
  if (action.type === CLOSE_LOGIN_SETTINGS) {
    closeLoginWidget()(store.dispatch, store.getState);
    store.dispatch(closeUserIdModal());
  }
  next(action);
};
