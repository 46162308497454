// @flow
import React from 'react';

import { UserIdModal } from './UserIdModal';

export const userIdModalDataid = 'user-id-modal';

type Props = {
  isServerSideRender: boolean
};

export const EditorModals = ({ isServerSideRender }: Props) => (
  <div
    data-id={userIdModalDataid}
    className='user-id-modal__container'
    hidden={isServerSideRender}
  >
    <UserIdModal />
  </div>
);
