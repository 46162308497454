import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import crossIcon from '../../../assets/images/icons/cross.svg';

import './style.pcss';

interface IModalWindowProps {
  className: string;
  bodyClassName?: string;
  children?: React.ReactNode;
  customBackground?: React.ReactNode;
  isServerSideRender: boolean;
  enabledBorder?: boolean;
  hidden: boolean;
  dataAttributes?: any;
  closeModal?: () => void;
  hiddenClose?: boolean;
}

const ModalWindow = ({
  className,
  bodyClassName,
  children,
  isServerSideRender,
  enabledBorder,
  hidden,
  customBackground,
  dataAttributes,
  closeModal,
  hiddenClose,
}: IModalWindowProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleEsc = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      closeModal?.();
    }
  };
  useEffect(() => {
    if (document.body) {
      document.body.addEventListener('keydown', handleEsc);
    }
    return () => {
      if (document.body) {
        document.body.removeEventListener('keydown', handleEsc);
      }
    };
  });

  const handleClickOutsideBody = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (ev.target === modalRef.current) {
      closeModal?.();
    }
  };

  return (
    <div
      className={cn(
        'ui-site-modal-window',
        { 'ui-site-modal-window--editor': !isServerSideRender },
        { 'ui-site-modal-window--hide-overlay': customBackground },
        className
      )}
      hidden={hidden}
      {...dataAttributes}
      onClick={handleClickOutsideBody}
      ref={modalRef}
    >
      {customBackground}
      <div
        className={cn(
          'ui-site-modal-window__body',
          { 'ui-site-modal-window__body--with-border': enabledBorder },
          bodyClassName
        )}
      >
        {!hiddenClose && (
          <button
            className={`ui-site-modal-window__close ${className}__close`}
            data-testid='close-icon'
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: crossIcon }}
            onClick={closeModal}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default ModalWindow;
