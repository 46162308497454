export const SET_POST_MESSAGE_FUNCTION = 'SET_POST_MESSAGE_FUNCTION';


const initialState = {
  postMessageFunction: () => true
};

export const setPostMessageFunction = customFunction => dispatch => {
  dispatch({
    type: SET_POST_MESSAGE_FUNCTION,
    customFunction
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_POST_MESSAGE_FUNCTION:
      return { ...state, postMessageFunction: action.customFunction };
    default:
      return state;
  }
}
