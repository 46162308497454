export enum Environment {
  DEV = 'development',
  STAGE = 'stage',
  PROD = 'production',
}

export enum RenderMode {
  PREVIEW = 'preview',
  PUBLICATION = 'publication',
  EDITING = 'editing',
}
