import { RenderMode } from '@site-builder/common/src/types/build';
import { MergeProps } from 'react-redux';

type IMergePropsByRenderMode = <
  TStateProps extends { renderMode: string },
  TDispatchProps,
  TOwnProps,
  TMergedProps
>({
  editorMergeProps,
  landingMergeProps,
}: {
  editorMergeProps: MergeProps<
    TStateProps,
    TDispatchProps,
    TOwnProps,
    TMergedProps
  >;
  landingMergeProps: MergeProps<
    TStateProps,
    TDispatchProps,
    TOwnProps,
    TMergedProps
  >;
}) => MergeProps<TStateProps, TDispatchProps, TOwnProps, TMergedProps>;

export const mergePropsByRenderMode: IMergePropsByRenderMode =
  ({ editorMergeProps, landingMergeProps }) =>
  (...args) => {
    const [stateProps] = args;
    return stateProps.renderMode === RenderMode.EDITING
      ? editorMergeProps(...args)
      : landingMergeProps(...args);
  };

export const getPackLayoutClassName = (
  index: number,
  packsLength: number,
  module = 'packs'
): string => {
  const getPackColumnsClassName = (): string => {
    // избавляемся от edge-кейсов и исключений
    if (packsLength === 4 && module === 'retailers') {
      return 'pack--2-columns';
    }

    if (packsLength <= 4) {
      return `pack--${packsLength}-columns`;
    }
    if (packsLength === 5) {
      return packsLength - index <= 3 ? 'pack--3-columns' : 'pack--2-columns';
    }
    if (packsLength === 6 || packsLength === 9) {
      return 'pack--3-columns';
    }
    // высчитываем класс для неполной матрицы
    const is3x1Line = packsLength % 4 === 3 && index < 3;
    const is3x2Line = packsLength % 4 === 2 && index < 6;
    const is3x3Line = packsLength % 4 === 1 && index < 9;
    if (is3x1Line || is3x2Line || is3x3Line) {
      return 'pack--3-columns';
    }
    return 'pack--4-columns';
  };
  const getPackAloneClassName = (): string =>
    packsLength - 1 === index && packsLength % 2 ? 'pack--alone' : '';

  return [getPackColumnsClassName(), getPackAloneClassName()].join(' ');
};

export const getPacksSizeStyle = (
  packsLength: number,
  module = 'packs'
): { isWideSize: boolean; isWidestSize: boolean } => {
  const isWideSize = [3, 5, 6, 9].includes(packsLength);
  const isWidestSize =
    !isWideSize &&
    (packsLength === 4 || packsLength > 6) &&
    module !== 'retailers';
  return { isWideSize, isWidestSize };
};

export const checkIfNeedChangeSlide = (
  isSlideAdded: boolean,
  activeCurrentBlock: Record<'module' | '_id', string>,
  block: Record<'module' | '_id', string>
) => {
  const { module: activeBlockType, _id: activeBlockId } = activeCurrentBlock;
  const { module: checkingBlockType, _id: checkingBlockId } = block;
  return (
    isSlideAdded &&
    activeBlockType === checkingBlockType &&
    activeBlockId === checkingBlockId
  );
};
