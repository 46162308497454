import Bowser from 'bowser';

export default data => {
  const { isVideo } = data;

  const browser = Bowser.getParser(window.navigator.userAgent);
  const { type } = browser.parsedResult.platform;
  if (isVideo && type !== 'desktop') {
    document.getElementsByClassName('editor-background-video')[0].style.display = 'none';
  }
};
