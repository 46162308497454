import { IPayStationCreateTokenData } from '../../types/paystation/create-token';

let payStationCustomParameters: null | IPayStationCreateTokenData = null;

export const setPaystationCustomParameters = (
  value: IPayStationCreateTokenData
) => {
  payStationCustomParameters = value;
};

export const getPaystationCustomParameters = () =>
  payStationCustomParameters || undefined;
