/** @prettier */
import LazyLoad from 'vanilla-lazyload';

export const lazyLoadImage = new LazyLoad({
  elements_selector: '.lazy',
});

export const initLazy = () => {
  window.addEventListener('load', () => {
    lazyLoadImage.update();
  });
};
