// @flow
import React from 'react';

import './style.pcss';

type Props = {
  imageLink: string,
  size: 'cover' | 'contain'
}

const Image = ({ imageLink, size }: Props) => (
  <img
    className={`ui-site-image ui-site-image--${size} lazy`}
    src={imageLink}
    alt=''
  />
);

export default Image;
