/** @prettier */
import { CSSProperties } from 'react';

// This method is used to show image with lazyLoad settings when editing mode on
export const getStyleForLazyLoad = (
  isServerSideRender: boolean,
  img: string,
  otherStyles: CSSProperties = {}
) => {
  return isServerSideRender
    ? otherStyles
    : {
        backgroundImage: `url(${img})`,
        ...otherStyles,
      };
};
