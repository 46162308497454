// @flow
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import errorMapping from '../../../utils/errorsTools/mapping';

import './style.pcss';

type Props = {
  errorMessage: string
};

type CustomErrorProps = {
  textId?: string,
  titleId?: string,
  textValues?: Object
};

const Error = ({ errorMessage }: Props) => (
  <div className='error-container'>
    <div className='error-container__title'>
      <FormattedMessage id='popup.error.title' />
    </div>
    <div className='error-container__text'>
      <FormattedHTMLMessage id={errorMapping(errorMessage)} />
    </div>
  </div>
);

const CustomError = ({ textId = 'error.sb.default_error', titleId = 'popup.error.title', textValues = {} }: CustomErrorProps) => (
  <div className='error-container'>
    <div className='error-container__title'>
      <FormattedMessage id={titleId} />
    </div>
    <div className='error-container__text'>
      <FormattedHTMLMessage id={textId} values={textValues} />
    </div>
  </div>
);

export const openErrorPopup = (errorMessage: string) => {
  toast.error(<Error errorMessage={errorMessage} />, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000
  });
};

export const openCustomErrorPopup = ({
  textId, titleId, textValues, autoClose = 5000
}: {...CustomErrorProps, autoClose?: mixed}): void => {
  toast.error(<CustomError textId={textId} titleId={titleId} textValues={textValues} />, {
    position: toast.POSITION.TOP_CENTER,
    autoClose
  });
};

export default Error;
