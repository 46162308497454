/** @prettier */
// @flow
import React from 'react';

import './style.pcss';

type Props = {
  text: string,
};

const Title = ({ text }: Props) => <span className='exp-title'>{text}</span>;

export default Title;
