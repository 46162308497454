// @flow
import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';

import { CHECKOUT_BLOCK } from './blocks';

export const TOGGLE_LOGIN_WIDGET = 'TOGGLE_LOGIN_WIDGET';
export const SHOW_LOGIN_WIDGET = 'SHOW_LOGIN_WIDGET';
export const CLOSE_LOGIN_WIDGET = 'CLOSE_LOGIN_WIDGET';

export type State = {
  isShowLoginWidget: boolean
}

export const initialState: State = {
  isShowLoginWidget: false
};

export const toggleLoginWidget = () => (dispatch: Dispatch, getState: GetState<RootState>) => {
  const { loginWidgetInfo: { isShowLoginWidget } } = getState();
  dispatch({
    type: TOGGLE_LOGIN_WIDGET,
    payload: {
      isShowLoginWidget: !isShowLoginWidget
    }
  });
};

export const showLoginWidget = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_LOGIN_WIDGET,
    payload: {
      isShowLoginWidget: true
    }
  });
};

export const closeLoginWidget = () => (dispatch: Dispatch, getState: GetState<RootState>) => {
  const { loginWidgetInfo: { isShowLoginWidget } } = getState();
  if (!isShowLoginWidget) {
    return;
  }
  dispatch({
    type: CLOSE_LOGIN_WIDGET,
    payload: {
      isShowLoginWidget: false
    }
  });
};

export default function reducer(state: State = initialState, action: { payload: Object } & Action) {
  switch (action.type) {
    case TOGGLE_LOGIN_WIDGET:
    case SHOW_LOGIN_WIDGET:
    case CLOSE_LOGIN_WIDGET:
      return { ...state, ...action.payload };
    case CHECKOUT_BLOCK:
      return { ...state, isShowLoginWidget: false };
    default:
      return state;
  }
}
