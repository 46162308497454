import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { convertHTMLStringToElement } from '../../../utils/common-helper';
import CustomSelector from '../../templates/CustomSelector';

export const cssClasses = {
  selectorContainer: 'ui-site-custom-selector',
  selectorContainerOpened: 'ui-site-custom-selector--opened',
  selectorOptionsList: 'ui-site-custom-selector__list',
  selectorOption: 'ui-site-custom-selector__option',
  selectorOptionActive: 'ui-site-custom-selector__option--active',
  selectorSelectedContainer: 'ui-site-custom-selector__selected',
  selectorSelectedText: 'ui-site-custom-selector__selected-text',
  selectorMobile: 'ui-site-custom-selector--mobile'
};

export const collectElements = (element, selector) => element.querySelectorAll(selector);

export const closeAllSelectors = () => {
  [...collectElements(document, `.${cssClasses.selectorContainerOpened}`)]
    .forEach(element => element.classList.remove(cssClasses.selectorContainerOpened));
};

export const addClickHandlerOnOptions = (nativeSelector, customSelector) => {
  const selectedTextElement = customSelector.querySelector(`.${cssClasses.selectorSelectedText}`);
  const selectorOptions = collectElements(customSelector, `.${cssClasses.selectorOption}`);
  selectorOptions.forEach(opt => {
    opt.addEventListener('click', event => {
      const target = event.currentTarget;
      if (target.dataset.disabled === 'disabled') {
        return;
      }
      if (selectedTextElement) {
        const { value: newValue } = target.dataset;
        const oldValue = nativeSelector.value;
        if (oldValue === newValue) {
          return;
        }
        selectedTextElement.textContent = target.textContent;

        // clear all active options
        selectorOptions.forEach(option => option.classList.remove(cssClasses.selectorOptionActive));

        nativeSelector.value = newValue;
        nativeSelector.dispatchEvent(new Event('change'));
        target.classList.add(cssClasses.selectorOptionActive);
      }
    });
  });
};

export const renderCustomSelector = (nativeSelector, props) => {
  const selectorHTML = ReactDOMServer.renderToStaticMarkup(<CustomSelector {...props} />);
  const customSelector = convertHTMLStringToElement(selectorHTML);
  if (nativeSelector.parentElement && customSelector && nativeSelector.parentElement.className !== 'ui-site-custom-selector--mobile') {
    nativeSelector.parentElement.insertBefore(customSelector, nativeSelector);
    customSelector.querySelector(`.${cssClasses.selectorMobile}`).appendChild(nativeSelector);
    addClickHandlerOnOptions(nativeSelector, customSelector);
  }
};

export const getTemplateData = nativeSelector => {
  const additionalClasses = [...nativeSelector.classList].join(' ');
  const nativeOptions = [...collectElements(nativeSelector, 'option')];
  const customOptions = nativeOptions.map(opt => ({
    value: opt.value,
    text: opt.textContent,
    disabled: opt.disabled ? 'disabled' : '',
    activeClass: nativeSelector.value === opt.value ? 'ui-site-custom-selector__option--active' : ''
  }));
  const selectedOption = customOptions.find(({ value }) => value === nativeSelector.value);
  return {
    selectedText: selectedOption ? selectedOption.text : '',
    selectedValue: nativeSelector.value,
    options: customOptions,
    additionalClasses
  };
};

export const initOneCustomSelector = nativeSelector => {
  if (!(nativeSelector instanceof HTMLElement || nativeSelector.tagName === 'SELECT')) {
    throw new Error(
      'Native select element is expected to be of type `HTMLElement` and tag `select`'
    );
  }

  const templateData = getTemplateData(nativeSelector);
  renderCustomSelector(nativeSelector, templateData);
};

export const initClickOnSelectors = () => {
  document.addEventListener(
    'click',
    event => {
      const targetEl = event.target;
      const isSelectorTarget = targetEl.matches(`.${cssClasses.selectorSelectedContainer}`)
        || targetEl.matches(`.${cssClasses.selectorSelectedText}`);
      if (isSelectorTarget) {
        if (targetEl.closest(`.${cssClasses.selectorContainer}`).classList.contains(cssClasses.selectorContainerOpened)) {
          return targetEl.closest(`.${cssClasses.selectorContainer}`).classList.remove(cssClasses.selectorContainerOpened);
        }
        closeAllSelectors();
        return targetEl.closest(`.${cssClasses.selectorContainer}`).classList.add(cssClasses.selectorContainerOpened);
      }
      return closeAllSelectors();
    },
    false
  );
};

const initCustomSelectors = () => {
  const customSelectors = [...collectElements(document, '[data-custom-selector]')];
  customSelectors.forEach(elem => {
    initOneCustomSelector(elem);
  });

  initClickOnSelectors();
};

export default initCustomSelectors;
