import { getBaseUrl } from '../helpers/common';

export default data => {
  const { locales } = data;
  const { pathname } = window.location;
  const paths = pathname.replace(/\/$/, '').split('/').filter(Boolean);

  const localeInUrl = paths.find(path => locales.includes(path));
  const localeUrlPart = localeInUrl ? `/${localeInUrl}` : '';

  const relativeLinks = [...document.querySelectorAll('[data-button-type="link"]')].filter(link => {
    const linkHref = link.getAttribute('href');
    return linkHref && linkHref.charAt(0) === '/';
  });

  relativeLinks.forEach(link => {
    const linkHref = link.getAttribute('href');
    const newHref = getBaseUrl() + localeUrlPart + linkHref;
    link.setAttribute('href', newHref);
  });
};
