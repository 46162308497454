import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { userIdModalDataid } from '../../components/ModalWindows/EditorModals';
import { UserIdModal } from '../../components/ModalWindows/UserIdModal';
import { ScriptsState } from '../../reducers/types/scripts-state';
import { getCountryCode } from '../../utils/cookie';
import { defaultLocale } from '../../utils/translation';
import { logOut } from '../blocks/user-account-services';
import { UserAccountEAAuthService } from '../blocks/user-account-services/partner-services/user-account-ea-auth-service';
import { UserAccountIdService } from '../blocks/user-account-services/user-account-id-service';
import { getStore } from '../store';
import { openModalWindow } from '../ui-components/editor/modalWindow';
import { analyticsManager } from './analytics/analytics';
import { AnalyticsActions } from './analytics/analyticsActions';
import { AnalyticsCategories } from './analytics/analyticsCategories';

export const initUserIdModal = (data: ScriptsState) => {
  const store = getStore();
  const {
    landingServices: { userService },
  } = store.getState();
  const mountNode = document.querySelector(`[data-id=${userIdModalDataid}]`);

  if (!mountNode || !(userService instanceof UserAccountIdService)) {
    return;
  }

  const { auth, merchantId, locale, locales, renderMode } = data;

  const needShowModalForGetUserId =
    userService instanceof UserAccountEAAuthService &&
    userService.needUpdateToken;

  const hideSocial =
    userService instanceof UserAccountEAAuthService &&
    (userService.needUpdateToken || userService.isEmailAuth);

  const onSubmit = (userId) => {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.USER_ID_PARTNER_METHOD_VALIDATION_START,
    });
    return userService
      .authUser(userId, getCountryCode())
      .then(({ isAuth, status }) => {
        if (isAuth) {
          analyticsManager.sendEvent({
            category: AnalyticsCategories.UID_WIDGET,
            event: AnalyticsActions.SUCCESS_ID,
            page: `landing_uid-widget_${AnalyticsActions.SUCCESS_ID}`,
            value: userId,
          });
        } else {
          analyticsManager.sendEvent({
            category: AnalyticsCategories.UID_WIDGET,
            event: AnalyticsActions.ERROR,
            page: `landing_uid-widget_${AnalyticsActions.ERROR}`,
            value: status,
          });
        }
        return isAuth;
      });
  };

  const onClose = () => {
    if (!needShowModalForGetUserId) {
      return;
    }
    logOut();
  };

  const initValue =
    userService.isAuthorized && userService instanceof UserAccountEAAuthService
      ? userService.id
      : '';

  const element = (
    // TODO problem with not same version redux and redux in react-redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Provider store={getStore()}>
      <IntlProvider locale={defaultLocale}>
        <UserIdModal
          createStep={() => undefined}
          auth={auth}
          onSubmit={onSubmit}
          locale={locale}
          languages={locales}
          renderMode={renderMode}
          merchantId={merchantId}
          onClose={onClose}
          hiddenSocials={hideSocial}
          initValue={initValue}
        />
      </IntlProvider>
    </Provider>
  );
  ReactDOM.render(element, mountNode);

  if (needShowModalForGetUserId) {
    openModalWindow(mountNode, 'UserId');
  }
};
