/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Need to bind an existing account?',
  wb_auth_modal_account_create: 'Need to create a GoT:C account?',
  wb_auth_modal_apple: 'Sign In with Apple',
  wb_auth_modal_conjunction_word: '-or-',
  wb_auth_modal_description:
    'This store uses your Game of Thrones: Conquest binding to find your account',
  wb_auth_modal_facebook: 'Sign In with Facebook',
  wb_auth_modal_google: 'Sign in with Google',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/en-us/articles/360001088488-Cloud-Saving-FAQ',
  wb_auth_modal_title: 'Login with your GOT:C Binding',
  wb_notification_added_to_cart: 'Added to your cart',
  wb_notification_cart_limit: 'Item exceeds the purchasing limit.',
  wb_notification_not_added_to_cart: 'Cannot add to shopping cart.',
  wb_notification_updated_cart: 'Your cart has been updated',
  wb_selector_amount_in_cart: 'In cart',
  wb_user_account_modal_continue: 'Continue',
  wb_user_account_modal_description:
    'This store uses your Game of Thrones: Conquest binding to find your account',
  wb_user_account_modal_error:
    'We could not find an account associated with that binding. Please try again.',
  wb_user_account_modal_footer_privacy: 'Privacy Policy',
  wb_user_account_modal_footer_terms: 'Terms of Use',
  wb_user_account_modal_footer_text:
    "After login, you will be directed to a purchase site operated by Xsolla, which is solely responsible for the site's content and all aspects of your purchase.",
  wb_user_account_modal_kingdom: 'Kingdom',
  wb_user_account_modal_logout: 'Log Out',
  wb_user_account_modal_title: 'LINK THIS GOTC ACCOUNT',
};

export default localizations;
