import createStoreManual from './create-store-manual';
import { middlewares } from './middlewares';
import rootReducer from './modules/site-builder';

export const createStore = (preloadedState) =>
  createStoreManual({
    rootReducer,
    customMiddlewares: middlewares,
    preloadedState,
    useDevtools: true,
  });
