import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';

import { Action, Dispatch, GetState } from '../../types';
import { RootState } from '../../types/root-state';

export const INIT_HIDDEN_BLOCKS = 'INIT_HIDDEN_BLOCKS';
export const ADD_HIDDEN_BLOCK = 'ADD_HIDDEN_BLOCKS';
export const REMOVE_HIDDEN_BLOCK = 'REMOVE_HIDDEN_BLOCK';

export type HiddenBlocks = {
  [key in BlockModule]: boolean;
};

export const initialState: Partial<HiddenBlocks> = {};

export const initHiddenBlocks = () => (dispatch: Dispatch) => {
  dispatch({
    type: INIT_HIDDEN_BLOCKS,
    payload: {},
  });
};

export const addHiddenBlock =
  (module: BlockModule) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { hiddenBlocks } = getState();
    dispatch({
      type: ADD_HIDDEN_BLOCK,
      payload: {
        ...hiddenBlocks,
        [module]: true,
      },
    });
  };

export const removeHiddenBlock =
  (module: BlockModule) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { hiddenBlocks } = getState();
    dispatch({
      type: REMOVE_HIDDEN_BLOCK,
      payload: {
        ...hiddenBlocks,
        [module]: false,
      },
    });
  };

export default function reducer(
  state: Partial<HiddenBlocks> = initialState,
  action: { payload: Partial<HiddenBlocks> } & Action
) {
  switch (action.type) {
    case INIT_HIDDEN_BLOCKS:
    case ADD_HIDDEN_BLOCK:
    case REMOVE_HIDDEN_BLOCK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
