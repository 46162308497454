export default data => {
  if (!data.pageTheme.videoBackground.enable) {
    return;
  }

  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  tag.async = true;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onYouTubeIframeAPIReady = () => {
    const player = new YT.Player('background-youtube-video', { // eslint-disable-line no-undef
      events: {
        onStateChange: onPlayerStateChange // eslint-disable-line no-use-before-define
      }
    });
    function onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.ENDED) { // eslint-disable-line no-undef
        player.seekTo(0);
        player.setPlaybackQuality('highres');
      }
    }
  };
};
