// @flow
import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';
import type { LandingWithStructure } from '@site-builder/common/src/types/model/landing';
import type { ProjectDetails } from '@site-builder/common/src/types/model/project';

import { PUBLISHER_URL } from '../../../config';
import { openErrorPopup, openCustomErrorPopup } from '../../../ui-components/settings/Error';
import * as api from '../../../utils/api';
import * as postMessages from '../../../utils/post-messages';


export type ProjectInfoState = {
  tokenlessIntegration: boolean | null,
  isUniversalItemEnabled: boolean | null,
  projectInfo: ProjectDetails | null,
  loading: boolean
}

const initialState: ProjectInfoState = {
  tokenlessIntegration: null,
  isUniversalItemEnabled: null,
  projectInfo: null,
  loading: true
};

export const INIT_PROJECT_INFO = 'INIT_PROJECT_INFO';
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
export const UPDATE_PROJECT_INFO = 'UPDATE_PROJECT_INFO';
export const SUCCESS_TOGGLE_TOGGLE_COMPONENT = 'SUCCESS_TOGGLE_TOGGLE_COMPONENT';

export const setProjectInfo = (projectInfo: ProjectDetails) => ({
  type: SET_PROJECT_INFO,
  payload: {
    tokenlessIntegration: projectInfo.send_json_to_paystation,
    isUniversalItemEnabled: projectInfo.is_universal_item_enabled,
    projectInfo
  }
});

export const initProjectInfo = (
  { merchantId, projectId, landingId }: {
    merchantId: string,
    projectId: string,
    landingId: string
  }
) => async (dispatch: Dispatch) => {
  const response = await api.getProjectInfo({
    merchantId,
    projectId,
    landingId
  });
  if (response.status !== 200) {
    openErrorPopup(response.data.error.code);
    return false;
  }

  return dispatch({
    type: INIT_PROJECT_INFO,
    payload: {
      tokenlessIntegration: response.data.send_json_to_paystation,
      isUniversalItemEnabled: response.data.is_universal_item_enabled,
      projectInfo: response.data
    }
  });
};

export const initServerProjectInfoData = (projectInfo: ProjectDetails) => ({
  type: INIT_PROJECT_INFO,
  payload: {
    tokenlessIntegration: projectInfo.send_json_to_paystation || false,
    isUniversalItemEnabled: projectInfo.is_universal_item_enabled,
    projectInfo
  }
});

export const updateProjectInfo = async ({
  landingId, projectId, merchantId, requestBody
}: { landingId: string, requestBody: ProjectDetails, projectId: string, merchantId: string }) => {
  const response = await api.updateProjectInfo({
    merchantId,
    projectId,
    landingId,
    requestBody
  });

  if (response.status !== 204) {
    const integrationSettingsLink = `${PUBLISHER_URL}/${merchantId}/projects/${projectId}/edit/advanced`;
    openCustomErrorPopup({
      textId: 'error.sb.projectInfo.update_info',
      textValues: {
        link: integrationSettingsLink
      },
      autoClose: false
    });
  }
};

export const updateProjectTokenlessIntegration = ({ landingId, projectId, merchantId }: { landingId: string } & LandingWithStructure) => async (dispatch: Dispatch) => {
  const response = await api.updateProjectTokenlessIntegration({
    merchantId,
    projectId,
    landingId,
    tokenlessIntegration: false
  });
  
  if (response.status !== 204) {
    const integrationSettingsLink = `${PUBLISHER_URL}/${merchantId}/projects/${projectId}/edit/advanced`;
    openCustomErrorPopup({
      textId: 'error.sb.projectInfo.update_info',
      textValues: {
        link: integrationSettingsLink
      },
      autoClose: false
    });
    return false;
  }
  
  postMessages.disableTokenLess();
  
  return dispatch({
    type: UPDATE_PROJECT_INFO,
    payload: {
      tokenlessIntegration: false
    }
  });
};

export const toggleStoreComponent = ({
  landingId, projectId, merchantId, componentName = 'subscriptions'
}: { landingId: string, componentName: string } & LandingWithStructure) => async (dispatch: Dispatch, getState: GetState<RootState>) => {
  const response = await api.toggleStoreComponent({
    merchantId,
    projectId,
    landingId,
    componentName
  });

  if (response.status !== 204) {
    const storeLink = `${PUBLISHER_URL}/${merchantId}/projects/${projectId}/storefront`;
    openCustomErrorPopup({
      textId: 'error.sb.projectInfo.toggle_component',
      textValues: {
        link: storeLink
      },
      autoClose: false
    });
    return false;
  }

  postMessages.enableStore(projectId);

  const {
    projectInfoData: {
      projectInfo
    }
  } = getState();

  if (projectInfo && projectInfo.components) {
    projectInfo.components[componentName].live = !projectInfo.components[componentName].live;
  }

  return dispatch({
    type: SUCCESS_TOGGLE_TOGGLE_COMPONENT,
    payload: {
      projectInfo
    }
  });
};

export default function projectInfoReducer(state: ProjectInfoState = initialState, action: { payload: Object } & Action) {
  switch (action.type) {
    case INIT_PROJECT_INFO:
    case SET_PROJECT_INFO:
    case SUCCESS_TOGGLE_TOGGLE_COMPONENT:
    case UPDATE_PROJECT_INFO:
      return { ...state, ...action.payload, ...{ loading: false } };
    default:
      return state;
  }
}
