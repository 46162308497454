export const selectDocumentLogin = (document) => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.loginId;
};

export const selectDocumentWebhook = (document) => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.webhookUrl;
};

export const selectDocumentIsRequiredWebhook = (document) => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.requiredWebhook;
};

export const selectDocumentUserIdLoginId = (document) => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.userIdLoginId;
};

export const selectDocumentParent = (document) =>
  document?.site?.parent;

export const selectSelfOrParentDocumentLogin = (document) => {
  const parentDocument = selectDocumentParent(document);
  return selectDocumentLogin(parentDocument || document);
};
