/**
 * @prettier
 * @flow
 * */

import type { CartItemObject, CartItemPrice } from '../../../../utils/types';
import type {
  StoreItemIdentifier,
  StoreUniversalItemTypes,
} from '@site-builder/common/src/flow-types/store';

export class CartItem implements StoreItemIdentifier {
  _sku: string;

  _name: string;

  _imageUrl: string | null;

  _quantity: number;

  _price: CartItemPrice;

  _type: StoreUniversalItemTypes;

  _canChangeQuantity: boolean;

  _isBonus: boolean;

  constructor({
    sku,
    quantity,
    price,
    type,
    name,
    imageUrl,
    isBonus,
  }: CartItemObject) {
    this._sku = sku;
    this._quantity = quantity;
    // $FlowFixMe
    this._price = price;
    this._type = type;
    this._name = name;
    this._imageUrl = imageUrl;
    this._canChangeQuantity = true;
    this._isBonus = isBonus || false;
  }

  get type() {
    return this._type;
  }

  get sku() {
    return this._sku;
  }

  get name() {
    return this._name;
  }

  get imageUrl() {
    return this._imageUrl;
  }

  hasSKU(sku: string) {
    return sku === this._sku;
  }

  get quantity() {
    return this._quantity;
  }

  set quantity(value: $PropertyType<CartItemObject, 'quantity'>) {
    this._quantity = value;
  }

  checkIfCanChangeQuantity() {
    return !this.isBonus && this._canChangeQuantity;
  }

  get price(): CartItemPrice {
    return this._price;
  }

  get isBonus() {
    return this._isBonus;
  }

  toStoreObject() {
    return {
      sku: this._sku,
      quantity: this._quantity,
    };
  }

  toJSON() {
    return {
      sku: this._sku,
      price: this._price,
      quantity: this._quantity,
      type: this._type,
      imageUrl: this._imageUrl,
      name: this._name,
    };
  }
}
