/**
 * @prettier
 */

const CartErrors = {
  gt10_gk: {
    id: 'gt10_gk',
    message: 'more then 10 game keys is not allowed',
  },
  no_keys: {
    id: 'no_keys',
    message: 'no available keys left for drm',
  },
  non_consumable: {
    id: 'non_consumable',
    message: 'cannot add another one non consumable item',
  },
};

export default CartErrors;
