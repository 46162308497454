/** @prettier */
// @flow
import React from 'react';

import type { Translations } from '../../../scripts/types';

import hboLogo from '../../../assets/images/experiments/logo_hbo.png';
import wbLogo from '../../../assets/images/experiments/logo_wb.svg';

import './style.pcss';

type ModalFooterProps = {
  translations: Translations,
};

const ModalFooter = ({ translations }: ModalFooterProps) => (
  <div className='exp-modal-footer'>
    <div className='exp-modal-footer__top-line'>
      <img className='exp-modal-footer__logo' src={hboLogo} alt='HBO' />
      <img className='exp-modal-footer__logo' src={wbLogo} alt='Warner Bros.' />
      <span className='exp-modal-footer__main-text'>
        {translations.wb_user_account_modal_footer_text}
      </span>
    </div>
    <div className='exp-modal-footer__bottom-line'>
      <a
        className='exp-modal-footer__link'
        href='https://policies.warnerbros.com/privacy/'
        target='_blank'
        rel='noopener noreferrer'
      >
        {translations.wb_user_account_modal_footer_privacy}
      </a>
      <span className='exp-modal-footer__separator'>|</span>
      <a
        className='exp-modal-footer__link'
        href='https://policies.warnerbros.com/terms/en-us'
        target='_blank'
        rel='noopener noreferrer'
      >
        {translations.wb_user_account_modal_footer_terms}
      </a>
    </div>
  </div>
);

export default ModalFooter;
