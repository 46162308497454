import React, { ComponentType, useEffect, useState } from 'react';

const withAsyncLoaders =
  <TProps, TAsyncProp>(
    WrappedComponent: ComponentType<TProps>,
    asyncProps: TAsyncProp
  ) =>
  (props: Omit<TProps, keyof TAsyncProp>) => {
    const asyncPropsExist = asyncProps && typeof asyncProps === 'object';
    const propKeys = Object.keys(props);
    const keysToLoad = asyncPropsExist
      ? Object.keys(asyncProps).filter(
          (key) =>
            !propKeys.includes(key) &&
            typeof asyncProps[`${key}`] === 'function'
        )
      : [];

    const allLoaded = !keysToLoad.length;

    const [loaded, setLoaded] = useState(allLoaded);

    const executeLoaders = async () => {
      const promises = keysToLoad.map((key) => asyncProps[`${key}`]());
      const results = await Promise.all(promises);
      keysToLoad.forEach((key, index) => {
        asyncProps[`${key}`] = results[+index];
      });
    };

    useEffect(() => {
      if (!loaded) executeLoaders().then(() => setLoaded(true));
    }, []);

    if (!loaded) {
      return null;
    }

    type AllProps = TAsyncProp & TProps & Record<string, any>;
    const allProps = { ...asyncProps, ...props } as AllProps;
    return <WrappedComponent {...allProps} />;
  };

export default withAsyncLoaders;
