/**
 * @prettier
 * @flow
 * */

import type { CartItemObject, VICartItemObject } from '../../../../utils/types';
import type { VIType } from '@site-builder/common/src/flow-types/store';

import { CartItem } from './CartItem';

export class VICartItem extends CartItem {
  _virtualItemType: VIType;

  constructor(props: VICartItemObject) {
    const { virtualItemType } = props;
    super(props);
    this._virtualItemType = virtualItemType;
    this._canChangeQuantity = this._virtualItemType !== 'non_consumable';
  }

  set quantity(value: $PropertyType<CartItemObject, 'quantity'>) {
    if (this._virtualItemType === 'non_consumable' && value > 1) {
      throw Error(
        `too many quantity = '${value}' for non consumable item '${this._sku}'`
      );
    }
    this._quantity = value;
  }

  get quantity() {
    return this._quantity;
  }

  get virtualItemType() {
    return this._virtualItemType;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      virtualItemType: this._virtualItemType,
    };
  }
}
