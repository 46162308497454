import { RenderMode } from '@site-builder/common/src/types/build';
import { Asset } from '@site-builder/common/src/types/model/asset';
import cn from 'classnames';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { MethodsEnum } from '../../../scripts/afterBlocks/analytics/analytics';
import { XsollaMetrikaEventNames } from '../../../scripts/afterBlocks/analytics/analyticsEventsList';
import { getStyleForLazyLoad } from '../../../utils/lazy-load-img';

interface MediaWithLinkProps {
  asset: Asset;
  intl: IntlShape;
  onMediaButtonClick: () => void;
  renderMode: RenderMode;
  mediaOptions: {
    href: string;
    'data-fancybox': string;
    'data-thumb': string;
  };
  xaAttributes: {
    name: XsollaMetrikaEventNames;
    extra?: unknown;
    value?: string;
  };
  disableLazyLoad: boolean;
}

const MediaWithLink = ({
  asset,
  intl,
  onMediaButtonClick,
  renderMode,
  mediaOptions,
  xaAttributes,
  disableLazyLoad = false,
}: MediaWithLinkProps) => (
  <div className='ui-site-media main-image'>
    <>
      {renderMode !== RenderMode.EDITING && mediaOptions.href !== '' && (
        <a
          {...mediaOptions}
          className='ui-site-media__link'
          target='_blank'
          rel='noopener noreferrer'
          data-xa-extra={JSON.stringify(xaAttributes.extra) || ''}
          data-xa-value={xaAttributes.value || ''}
          data-xa-name={xaAttributes.name}
          data-xa-method={MethodsEnum.ELEMENT_CLICK}
        />
      )}
      <div
        className='ui-site-media__background-color'
        style={{ backgroundColor: asset.color }}
      />
      <div
        className={cn('ui-site-media__image', { lazy: !disableLazyLoad })}
        data-bg={`url(${asset.img})`}
        style={getStyleForLazyLoad(
          renderMode !== RenderMode.EDITING && !disableLazyLoad,
          asset.img,
          {
            backgroundSize: asset.size,
          }
        )}
      />
    </>
    {renderMode === RenderMode.EDITING && (
      <div
        className='ui-site-media__button-wrapper'
        onClick={onMediaButtonClick}
        onKeyDown={onMediaButtonClick}
        role='button'
        tabIndex={0}
      >
        <button type='button' className='ui-site-media__main-image-assets'>
          {intl.messages['nav_block.settings.image.set_image']}
        </button>
      </div>
    )}
  </div>
);

export default injectIntl(MediaWithLink);
