import cn from 'classnames';
import React, { Suspense } from 'react';

import deleteIconSvg from '../../../assets/images/icons/delete-icon.svg';

const Tooltip = React.lazy(() => import('xsolla-uikit/lib/tooltip'));

interface DeleteTooltipProps {
  label: string;
  cssClasses?: string[];
}

export const DeleteTooltip = ({
  label = '',
  cssClasses = [],
}: DeleteTooltipProps) => {
  return (
    // @ts-ignore
    <Suspense fallback={null}>
      <Tooltip label={label}>
        <div
          className={cn(...cssClasses)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: deleteIconSvg }}
        />
      </Tooltip>
    </Suspense>
  );
};
