/** @prettier */
// @flow
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import type { Translations } from '../../../../scripts/types';

import { LOGIN_API } from '../../../../config';
import { BUY_BUTTON_FIRST_CLICK } from '../../../../scripts/afterBlocks/experiments/customAuth';
import Button from '../../Button';
import Description from '../../Description';
import ErrorMessage from '../../ErrorMessage';
import Link from '../../Link';
import Logo from '../../Logo';
import ModalFooter from '../../ModalFooter';
import Title from '../../Title';
import Modal from '../Modal';

import './style.pcss';

type AuthorizationProps = {
  loginWidgetId: string,
  translations: Translations,
  mountNode: HTMLElement,
  showErrorMessage: boolean,
};

const Authorization = ({
  loginWidgetId,
  translations,
  mountNode,
  showErrorMessage,
}: AuthorizationProps) => {
  const [isSpinnerShown, showSpinner] = useState(false);
  const [isShow, showModal] = useState(true);

  const authViaSocialNetwork = (providerName) => {
    showModal(true);
    showSpinner(true);
    window.location.href = `${LOGIN_API}/social/${providerName}/login_redirect?projectId=${loginWidgetId}&login_url=${window.location.href}`;
  };

  const close = () => {
    localStorage.removeItem(BUY_BUTTON_FIRST_CLICK);
    showModal(false);
    ReactDOM.unmountComponentAtNode(mountNode);
  };

  return (
    <>
      <Modal isSpinnerShown={isSpinnerShown} onClose={close} isShow={isShow}>
        <Logo />
        <Title text={translations.wb_auth_modal_title} />
        <Description text={translations.wb_auth_modal_description} />
        {showErrorMessage && (
          <ErrorMessage text={translations.wb_user_account_modal_error} />
        )}
        <div className='authorization-modal__buttons-wrapper'>
          <Button handleClick={() => authViaSocialNetwork('facebook')}>
            {translations.wb_auth_modal_facebook}
          </Button>
          <Button handleClick={() => authViaSocialNetwork('google')}>
            {translations.wb_auth_modal_google}
          </Button>
          <Button handleClick={() => authViaSocialNetwork('apple')}>
            {translations.wb_auth_modal_apple}
          </Button>
        </div>
        <div className='authorization-modal__links-wrapper'>
          <Link
            url='https://gotcm.sng.link/A8vom/mh9g/ivrp'
            text={translations.wb_auth_modal_account_create}
          />
          <span className='exp-link'>
            {translations.wb_auth_modal_conjunction_word}
          </span>
          <Link
            url={translations.wb_auth_modal_link_bind}
            text={translations.wb_auth_modal_account_bind}
          />
        </div>
        <ModalFooter translations={translations} />
      </Modal>
    </>
  );
};

export default Authorization;
