/** @prettier */

export interface Listener<T> {
  sendEvent(event: T): void;
}

export class Subscribers<T extends Listener<any>, U> implements Listener<U> {
  listeners: T[] = [];

  initialEvents: U[] = [];

  sendEventAfterListenerSubscribe(event: U) {
    this.initialEvents.push(event);
  }

  subscribe(listener: T) {
    this.listeners.push(listener);
    this.initialEvents.forEach((event) => listener.sendEvent(event));
  }

  sendEvent(event?: U) {
    this.listeners.forEach((listener) => listener.sendEvent(event));
  }
}
