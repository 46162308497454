/**
 * @flow
 * @prettier
 */

import axios from 'axios';

import { SUBSCRIPTIONS_API } from '../../../../config';

type UserUrlParams = {
  urlType: string,
  productId?: null | string | number,
  projectId: string,
  subscriptionId?: null | number,
};

type QSParams = {
  [string]: string | number | number[],
};

type ClientOptions = {
  productId?: null | string | number,
  projectId: string,
  subscriptionId?: null | number,
  query: QSParams,
  headers: {
    [string]: string,
  },
  method: 'get' | 'post',
  body?: string,
};

const baseUserUrl = `${SUBSCRIPTIONS_API}/api/user/v1`;
const basePublicUrl = `${SUBSCRIPTIONS_API}/api/public/v1`;

const getUserUrl = ({
  urlType,
  productId,
  projectId,
  subscriptionId,
}: UserUrlParams) => {
  switch (urlType) {
    case 'plans': {
      return productId !== null &&
        productId !== '0' &&
        typeof productId !== 'undefined'
        ? `${baseUserUrl}/projects/${projectId}/products/${productId}/plans`
        : `${baseUserUrl}/projects/${projectId}/plans`;
    }
    case 'plans-public': {
      return productId !== null &&
        productId !== '0' &&
        typeof productId !== 'undefined'
        ? `${basePublicUrl}/projects/${projectId}/products/${productId}/user_plans`
        : `${basePublicUrl}/projects/${projectId}/user_plans`;
    }
    case 'subscriptions': {
      return `${baseUserUrl}/projects/${projectId}/subscriptions`;
    }
    case 'subscription': {
      return typeof subscriptionId !== 'undefined' && subscriptionId !== null
        ? `${baseUserUrl}/projects/${projectId}/subscriptions/${subscriptionId}`
        : baseUserUrl;
    }
    case 'buy': {
      return `${baseUserUrl}/projects/${projectId}/subscriptions/buy`;
    }
    case 'manage': {
      return `${baseUserUrl}/projects/${projectId}/subscriptions/manage`;
    }
    case 'renew': {
      return typeof subscriptionId !== 'undefined' && subscriptionId !== null
        ? `${baseUserUrl}/projects/${projectId}/subscriptions/${subscriptionId}/renew`
        : baseUserUrl;
    }
    default: {
      return baseUserUrl;
    }
  }
};

const qsConvert = (params: QSParams) =>
  Object.keys(params)
    .reduce((acc, key) => {
      const val = params[key];

      if (typeof val === 'string' || typeof val === 'number') {
        acc.push(`${key}=${val}`);
      }

      if (Array.isArray(val)) {
        val.forEach((el) => {
          acc.push(`${key}[]=${el}`);
        });
      }

      return acc;
    }, [])
    .join('&');

export const api = async (urlType: string, options: ClientOptions) => {
  const {
    productId,
    projectId,
    subscriptionId,
    query,
    headers: { token },
    method,
    body,
  } = options;

  const urlPath = getUserUrl({
    urlType,
    productId,
    projectId,
    subscriptionId,
  });
  const urlParams = qsConvert(query);

  const url = urlParams !== '' ? `${urlPath}?${urlParams}` : urlPath;
  const authByToken =
    typeof token !== 'undefined'
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
  const contentType =
    typeof body !== 'undefined'
      ? {
          'Content-Type': 'application/json',
        }
      : {};

  const response = await axios.request({
    url,
    method,
    headers: {
      ...authByToken,
      ...contentType,
    },
    data: body,
  });

  return {
    status: response.status,
    json: response.data,
  };
};
