// @flow

import cn from 'classnames';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './style.pcss';

type Spinner = 'sb' | 'ps';

type Props = {
  className?: string,
  isLandingPage?: boolean,
  spinner?: Spinner
};

const CONTAINER_ID = 'loader';

export const mount = (props: Props) => {
  const container = document.createElement('div');
  container.id = CONTAINER_ID;
  if (document.body) {
    document.body.append(container);
  }

  ReactDOM.render(<Loader {...props} />, container);

  return () => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
  };
};

const Loader = ({ className = '', isLandingPage = false, spinner = 'sb' }: Props) => {
  useEffect(() => {
    if (document.body) {
      document.body.classList.add('hide-scrolls');
    }
    return () => {
      if (document.body) {
        document.body.classList.remove('hide-scrolls');
      }
    };
  });

  return (
    <div
      className={cn(
        className,
        'loader',
        isLandingPage && 'loader--landing-page'
      )}
    >
      <div className={cn(`loader__${spinner}-spinner`)}/>
    </div>
  );
};

export default Loader;
