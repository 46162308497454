import { TwoSymbolLocale } from '@site-builder/common/src/types/locale';
import {
  CartResponse,
  StoreBundle,
} from '@site-builder/common/src/types/store';
import axios from 'axios';
import uuid from 'uuid/v4';

import { STORE_URL } from '../../../config';
import { localeStorageUserId, sortVCPacks } from '../../../utils/store-helper';
import { StoreXUserData } from '../../../utils/types';
import { CartIdParam, StoreError, Rewards } from '../../helpers/common';
import { enableLongDescription } from '../../landingAPI/enableLongDescription';

type CartParams = ProjectIdParam & UserTokenParam & CartIdParam;

type DeleteItemFromCartParams = CartParams & {
  sku: string;
};

type HeaderForStore = CartIdParam & UserTokenParam;

type Promocode = {
  promocode: string;
};

type ProjectIdParam = {
  projectId: string;
};

type SaveItemsToCartParams = CartParams & {
  items: Array<{ sku: string; quantity: number }>;
};

type UserTokenParam = {
  userToken: string;
};

const getLongDescription = () =>
  enableLongDescription() ? `&additional_fields[]=long_description` : ``;

export const getUuid = (cartId: CartIdParam) => {
  const localStorageKey: string = localeStorageUserId(cartId);
  const unauthorizedId: string =
    localStorage.getItem(localStorageKey) || uuid();
  localStorage.setItem(localStorageKey, unauthorizedId);
  return unauthorizedId;
};

export const getStoreUserHeader = (data: StoreXUserData) => ({
  'x-user': btoa(JSON.stringify(data)),
});

export const handleStoreRequest = async <T = unknown>(
  req: Promise<Response>,
  path = 'items'
): Promise<T> => {
  const response = await req;

  const result = await response.json();
  const { errorMessage } = result;
  if (errorMessage) {
    throw Object(result);
  }

  return result[path] as T;
};

export const getHeaderForStore = ({ isAuth, userToken }: HeaderForStore) => {
  const commonHeader = {
    'Content-Type': 'application/json',
  };
  return isAuth
    ? { ...commonHeader, Authorization: `Bearer ${userToken}` } // userToken is JWT
    : { ...commonHeader, 'x-unauthorized-id': userToken }; // userToken is uuid
};

export const getGameKeys = async (
  projectId: string,
  locale: TwoSymbolLocale,
  userToken: string,
  isAuth: boolean
): Promise<unknown> => {
  const { data } = await axios.get<{
    errorMessage: string | undefined;
    items: unknown;
  }>(`${STORE_URL}/project/${projectId}/items/game?locale=${locale}`, {
    headers: getHeaderForStore({ isAuth, userToken }),
  });
  if (data?.errorMessage) {
    throw new Error(JSON.stringify(data));
  }

  return data?.items;
};

export const getBundles = (
  projectId: string,
  locale: TwoSymbolLocale,
  userToken: string,
  isAuth: boolean
) => {
  const req = fetch(
    `${STORE_URL}/project/${projectId}/items/bundle?locale=${locale}${getLongDescription()}`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );

  return handleStoreRequest(req);
};

export const getVirtualItems = (
  projectId: string,
  groupId: string,
  locale: TwoSymbolLocale,
  userToken: string,
  isAuth: boolean
) => {
  const req = fetch(
    `${STORE_URL}/project/${projectId}/items/virtual_items/group/${groupId}?locale=${locale}${getLongDescription()}`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );

  return handleStoreRequest(req);
};

export const getVirtualCurrencyPacks = async (
  projectId: string,
  locale: TwoSymbolLocale,
  userToken: string,
  isAuth: boolean
) => {
  const req = fetch(
    `${STORE_URL}/project/${projectId}/items/virtual_currency/package?locale=${locale}${getLongDescription()}`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );

  return sortVCPacks(await handleStoreRequest<StoreBundle[]>(req));
};

export const getVirtualCurrencies = (
  projectId: string,
  locale: TwoSymbolLocale,
  userToken: string,
  isAuth: boolean
) => {
  const req = fetch(
    `${STORE_URL}/project/${projectId}/items/virtual_currency?locale=${locale.slice(
      0,
      2
    )}`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );

  return handleStoreRequest(req);
};

export const buyItemForVirtualCurrency = async (
  userToken: string,
  projectId: string,
  itemSku: string,
  vcSku: string
) => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/payment/item/${itemSku}/virtual/${vcSku}`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }),
    }
  );

  return response.json();
};

export const buyItem = async ({
  userToken,
  projectId,
  sku,
  body,
}: {
  userToken: string;
  projectId: string;
  sku: string;
  body: any;
}) => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/payment/item/${sku}`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }),
      body: JSON.stringify(body),
    }
  );

  return response.json();
};

export const getVirtualCurrencyUserBalance = (
  projectId: string,
  userToken: string,
  locale: TwoSymbolLocale
) => {
  const req = fetch(
    `${STORE_URL}/project/${projectId}/user/virtual_currency_balance?locale=${locale}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }),
    }
  );

  return handleStoreRequest(req);
};

export const addItemToCart = async ({
  userToken,
  projectId,
  cartId,
  sku,
  isAuth,
  itemsQuantity,
}: UserTokenParam &
  ProjectIdParam &
  CartIdParam & { sku: string }): Promise<StoreError | void> => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/cart/${cartId}/item/${sku}`,
    {
      method: 'PUT',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
      body: JSON.stringify({
        quantity: itemsQuantity,
      }),
    }
  );

  if (response.status !== 204) {
    return response.json();
  }
  return Promise.resolve();
};

export const getCurrentUserInventory = async (
  userToken: string,
  projectId: string
) => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/user/inventory/items`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }),
    }
  );

  return response.json();
};

export const getUsersCart = async ({
  userToken,
  projectId,
  cartId,
  isAuth,
}: CartParams) => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/cart/${cartId}`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );

  return response.json();
};

export const clearCurrentUsersCart = async ({
  userToken,
  projectId,
  cartId,
  isAuth,
}: CartParams) => {
  await fetch(`${STORE_URL}/project/${projectId}/cart/${cartId}/clear`, {
    method: 'PUT',
    headers: new Headers(getHeaderForStore({ isAuth, userToken })),
  });
};

export const deleteItemFromCurrentUsersCart = async ({
  userToken,
  projectId,
  cartId,
  sku,
  isAuth,
}: DeleteItemFromCartParams) => {
  await fetch(`${STORE_URL}/project/${projectId}/cart/${cartId}/item/${sku}`, {
    method: 'DELETE',
    headers: new Headers(getHeaderForStore({ isAuth, userToken })),
  });
};

export const saveItemsToCart = async ({
  userToken,
  projectId,
  cartId,
  items,
  isAuth,
}: SaveItemsToCartParams) => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/cart/${cartId}/fill`,
    {
      method: 'PUT',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
      body: JSON.stringify({
        items,
      }),
    }
  );
  return response.json();
};

export const getRewardPromocode = async ({
  userToken,
  projectId,
  promocode,
  isAuth,
}: ProjectIdParam & UserTokenParam & Promocode & CartIdParam): Promise<
  StoreError | Rewards
> => {
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/promocode/code/${promocode}/rewards`,
    {
      method: 'GET',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
    }
  );
  return response.json();
};

export const redeemPromocode = async ({
  userToken,
  projectId,
  cartId,
  promocode,
  selectedUnitItems,
  isAuth,
}: CartParams &
  Promocode & {
    selectedUnitItems?: { [key: string]: string };
  }): Promise<StoreError | CartResponse> => {
  const body = {
    coupon_code: promocode,
    cart: {
      id: cartId,
    },
    selected_unit_items: selectedUnitItems,
  };
  const res = await fetch(
    `${STORE_URL}/project/${projectId}/promocode/redeem`,
    {
      method: 'POST',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
      body: JSON.stringify(body),
    }
  );
  return res.json();
};

export const cancelRedeemPromocode = async ({
  userToken,
  projectId,
  cartId,
  isAuth,
}: CartParams): Promise<StoreError | CartResponse> => {
  const body = {
    cart: {
      id: cartId,
    },
  };
  const response = await fetch(
    `${STORE_URL}/project/${projectId}/promocode/remove`,
    {
      method: 'PUT',
      headers: new Headers(getHeaderForStore({ isAuth, userToken })),
      body: JSON.stringify(body),
    }
  );
  return response.json();
};
