import { UserAccountLoginService } from '../user-account-login-service';

export class UserAccountWBAuthService extends UserAccountLoginService {
  protected static instance: UserAccountWBAuthService | null = null;

  get playerId() {
    return this.tokenData?.partner_data?.player_id;
  }

  get userName() {
    return this.tokenData?.partner_data?.player_name;
  }

  static getInstance({
    projectId,
    merchantId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }) {
    if (!UserAccountWBAuthService.instance) {
      UserAccountWBAuthService.instance = new UserAccountWBAuthService({
        projectId,
        merchantId,
        locale,
        loginId,
        loginWidgetGeneration,
        loginTheme,
      });
    }
    return UserAccountWBAuthService.instance;
  }
}
