/** @prettier */
import React from 'react';

import './style.pcss';

interface ModalProps {
  children: React.ReactNode;
  isSpinnerShown: boolean;
  onClose: () => void;
  isShow: boolean;
}

const Modal = ({ children, isSpinnerShown, onClose, isShow }: ModalProps) =>
  isShow ? (
    <div className='exp-modal__wrapper' onClick={() => onClose()}>
      {isSpinnerShown && <div className='exp-modal__spinner' />}
      <div
        style={{ visibility: isSpinnerShown ? 'hidden' : 'visible' }}
        className='exp-modal'
        onClick={(e) => e.stopPropagation()}
      >
        <button className='exp-modal__close' onClick={() => onClose()} />
        <div className='exp-modal__content'>{children}</div>
      </div>
    </div>
  ) : null;

export default Modal;
