export const COLORS = [
  'reset-color',
  '#000000',
  '#434343',
  '#666666',
  '#999999',
  '#b7b7b7',
  '#cccccc',
  '#d9d9d9',
  '#efefef',
  '#f3f3f3',
  '#ffffff',
  '#980000',
  '#ff0000',
  '#f90',
  '#ff0',
  '#0f0',
  '#0ff',
  '#4a86e8',
  '#00f',
  '#90f',
  '#f0f',
  '#e6b8af',
  '#f4cccc',
  '#fce5cd',
  '#fff2cc',
  '#d9ead3',
  '#d0e0e3',
  '#c9daf8',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#dd7e6b',
  '#ea9999',
  '#f9cb9c',
  '#ffe599',
  '#b6d7a8',
  '#a2c4c9',
  '#a4c2f4',
  '#9fc5e8',
  '#b4a7d6',
  '#D3A6BC',
  '#cc4125',
  '#e06666',
  '#f6b26b',
  '#ffd966',
  '#93c47d',
  '#76a5af',
  '#6d9eeb',
  '#6fa8dc',
  '#8e7cc3',
  '#c27ba0',
  '#a61c00',
  '#c00',
  '#e69138',
  '#f1c232',
  '#6aa84f',
  '#45818e',
  '#3c78d8',
  '#3d85c6',
  '#674ea7',
  '#a64d79',
  '#85200c',
  '#900',
  '#b45f06',
  '#bf9000',
  '#38761d',
  '#134f5c',
  '#15c',
  '#0b5394',
  '#351c75',
  '#741b47',
  '#5b0f00',
  '#600',
  '#783f04',
  '#7f6000',
  '#274e13',
  '#0c343d',
  '#1c4587',
  '#073763',
  '#20124d',
  '#4c1130',
];

export const FORMATS = [
  'bold',
  'italic',
  'align',
  'color',
  'link',
  'header',
  'list',
];

export const importQuillModules = (Quill: any) => {
  if (Quill) {
    const icons = Quill.import('ui/icons');
    const align = Quill.import('attributors/style/align');
    const Link = Quill.import('formats/link');
    const builtInFunc = Link.sanitize;
    Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
      let val = linkValueInput;
      if (!/^\w+:/.test(val) && !/^https?:/.test(val)) {
        val = `https://${val}`;
      }
      return builtInFunc.call(this, val); // retain the built-in logic
    };

    icons.align.left = icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
    align.whitelist = ['left', 'center', 'right', 'justify']; // add explicit 'left' option
    Quill.register(align, true);
  }
};
