/** @prettier @flow */
import type { ScriptsData } from '../types';

import { isCustomAuth } from '../../experiments/partnersRequests/utils';
import customAuth from './experiments/customAuth';

export default async (data: ScriptsData) => {
  if (isCustomAuth()) {
    await customAuth(data);
  }
};
