/** @prettier */
import { getUserSubscription } from '../../components/Blocks/SubscriptionsPacks/components/Button/helpers';
import { getTokenFromCookie } from '../helpers/common';

export class Subscriptions {
  userSubscriptionsInfo = [];

  async init({ isPreviewFrameMode, projectId }) {
    const userToken = getTokenFromCookie(isPreviewFrameMode);
    if (!userToken) {
      return;
    }

    this.userSubscriptionsInfo = await getUserSubscription(
      projectId,
      userToken
    );
  }
}

const subscriptionsService = new Subscriptions();

export { subscriptionsService };
