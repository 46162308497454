/** @prettier */
// @flow

import { AuthorizationTypes } from '@site-builder/common/src/types/model/landing';

import type { Environment } from '../../utils/types';
import type { ILoaderService } from '../blocks/loader';
import type { Translations } from '../types';
import type { RenderMode } from '@site-builder/common/src/types/build';
import type { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import type { StoreVirtualCurrency } from '@site-builder/common/src/types/store';

import { mount } from '../../ui-components/settings/Loader';
import { scrollToElement } from '../../utils/common-helper';
import { CookieNames } from '../../utils/cookie';
import { openPayStationWidget } from '../../utils/paystation/open-widget';
import { getPayStationToken } from '../../utils/paystation/paystation-token';
import {
  openLogin,
  getLastLoginSource,
  clearLastLoginSource,
  isLoginLastSource,
  LOGIN_SOURCES,
  openUserIdModalForButton,
} from '../blocks/user-account-services';
import { getCookie } from '../helpers/common';
import { getStore } from '../store';

export class CustomAmountService {
  _authType: AuthorizationTypes;

  _projectId: string;

  _locale: FiveSymbolLocale;

  _renderMode: RenderMode;

  _userToken: string;

  _analyticsCounterId: number;

  _merchantId: string;

  _environment: Environment;

  _translations: Translations;

  _loginId: string;

  _payStationReturnUrl: string;

  constructor({
    authType,
    projectId,
    locale,
    renderMode,
    analyticsCounterId,
    merchantId,
    environment,
    translations,
    loginId,
    payStationReturnUrl,
  }: {
    authType: AuthorizationTypes,
    projectId: string,
    locale: FiveSymbolLocale,
    renderMode: RenderMode,
    analyticsCounterId: number,
    merchantId: string,
    environment: Environment,
    translations: Translations,
    loginId: string,
    payStationReturnUrl: string,
  }) {
    this._userToken = getCookie(CookieNames.USER_LOGIN_TOKEN) || '';
    this._authType = authType;
    this._projectId = projectId;
    this._locale = locale;
    this._renderMode = renderMode;
    this._analyticsCounterId = analyticsCounterId;
    this._merchantId = merchantId;
    this._environment = environment;
    this._translations = translations;
    this._loginId = loginId;
    this._payStationReturnUrl = payStationReturnUrl;
  }

  async buyCustomAmount(
    buttonId: string,
    item: StoreVirtualCurrency,
    quantity: number
  ) {
    if (this._userToken) {
      const unmount = mount({
        isLandingPage: true,
        spinner: 'ps',
      });
      const store = getStore();
      const { userService } = store.getState().landingServices;
      const token = await getPayStationToken({
        userToken: this._userToken,
        sku: item.sku,
        quantity,
        locale: this._locale,
        analyticsCounterId: this._analyticsCounterId,
        payStationReturnUrl: this._payStationReturnUrl,
        renderMode: this._renderMode,
        projectId: this._projectId,
        userName: userService.userName,
      });
      unmount();
      openPayStationWidget({
        token,
        renderMode: this._renderMode,
      });
    } else if (this._authType === AuthorizationTypes.USER_ID) {
      openUserIdModalForButton(buttonId);
    } else {
      openLogin({ source: buttonId });
    }
  }
}
export class CustomAmountLoaderService implements ILoaderService {
  _id: string;

  constructor() {
    this._id = getLastLoginSource();
  }

  // eslint-disable-next-line class-methods-use-this
  checkAttach() {
    return isLoginLastSource(LOGIN_SOURCES.CUSTOM_AMOUNT_BUTTON);
  }

  checkDetach() {
    return (
      isLoginLastSource(LOGIN_SOURCES.CUSTOM_AMOUNT_BUTTON) &&
      !!document.querySelector(`[id='${this._id}']`)
    );
  }

  scrollToButton() {
    const button = document.querySelector(`[id='${this._id}']`);
    if (!button) {
      return;
    }
    scrollToElement(button, { block: 'center' });
    button.click();
  }

  detach() {
    this.scrollToButton();
    clearLastLoginSource();
  }
}

export const onClickCustomAmount =
  (service: CustomAmountService) =>
  ({
    buttonId,
    item,
    quantity,
  }: {
    buttonId: string,
    item: StoreVirtualCurrency,
    quantity: number,
  }) => {
    service.buyCustomAmount(buttonId, item, quantity);
  };
