import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import Color from 'color';
import React, { useMemo } from 'react';

import { getColorBySocial } from '../../../../utils/login/helper';
import { SocialInfo } from '../../../../utils/login/types';
import {
  getContunueWithSociaTextForUserIdModal,
  getSocialNameTextForUserIdModal,
} from '../../../../utils/translation';

import './style.pcss';

const svgByName = (name: string): string => {
  try {
    return require(`../../../../assets/images/socials/icons/login/${name}.svg`); // eslint-disable-line
  } catch (error) {
    return '';
  }
};

const MAX_NUMBER_SOCIAL = 4;

interface SocialsProps {
  socials: SocialInfo[];
  loginBySocial: (providerName: string) => void;
  openAllSocials: () => void;
  locale: FiveSymbolLocale;
}

export const Socials = ({
  socials,
  loginBySocial,
  openAllSocials,
  locale,
}: SocialsProps) => {
  const singleSocial = socials[0];
  const background = getColorBySocial(singleSocial.name);
  const color = Color(getColorBySocial(singleSocial.name)).isLight()
    ? 'black'
    : 'white';

  const showingSocials = useMemo(
    () => socials.slice(0, MAX_NUMBER_SOCIAL),
    [socials]
  );
  return (
    <div className='socials'>
      {socials.length === 1 ? (
        <button
          key={singleSocial.name}
          className={`socials__one-button socials__one-button--${singleSocial.name}`}
          onClick={() => loginBySocial(singleSocial.name)}
          style={{ color, backgroundColor: background }}
          data-testid={singleSocial.name}
        >
          <div
            className='socials__icon'
            dangerouslySetInnerHTML={{ __html: svgByName(singleSocial.name) }} // eslint-disable-line
          />
          <p className='socials__text'>
            {getContunueWithSociaTextForUserIdModal(locale, {
              socialName: getSocialNameTextForUserIdModal(singleSocial.name)(
                locale
              ),
            })}
          </p>
        </button>
      ) : (
        showingSocials.map((social) => (
          <button
            key={social.name}
            className='socials__button'
            dangerouslySetInnerHTML={{ __html: svgByName(social.name) }} // eslint-disable-line
            onClick={() => loginBySocial(social.name)}
            data-testid={social.name}
          />
        ))
      )}
      {socials.length > MAX_NUMBER_SOCIAL && (
        <button
          onClick={openAllSocials}
          className='socials__button'
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: svgByName('three-dots'),
          }}
          data-testid='three-dots'
        />
      )}
    </div>
  );
};
