import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';
import {
  AuthorizationTypes,
  ChangeablePageSettings,
  LandingTypes,
} from '@site-builder/common/src/types/model/landing';

import { sendClick, XAEvents } from '../../utils/init-xsolla-analytics';
import { DELETE_BLOCK } from '../modules/site-builder/blocks';
import {
  changePageSettings,
  CHANGE_PAGE_SETTINGS,
} from '../modules/site-builder/pages';
import { Store } from '../types';

export const unShowShoppingCartByChangeAuthType =
  (store: Store) => (next) => async (action) => {
    if (action.type === CHANGE_PAGE_SETTINGS) {
      const { pages } = action.payload;
      const {
        landing: { landing },
      } = store.getState();
      if (
        pages[0].auth.type !== AuthorizationTypes.LOGIN &&
        pages[0].cart.enable
      ) {
        await changePageSettings({
          propName: ChangeablePageSettings.CART,
          propValue: { ...pages[0].cart, enable: false },
          needSetData: false,
        })(store.dispatch, store.getState);
        sendClick(landing.merchantId, XAEvents.cartDisable);
        action.payload.pages[0].cart.enable = false;
      }
    }
    next(action);
  };

export const unShowShoppingCartByDeleteStore =
  (store: Store) => (next) => async (action) => {
    const {
      blocks,
      landing: { landing },
      pages,
    } = store.getState();
    if (action.type === DELETE_BLOCK && landing.type === LandingTypes.TOPUP) {
      const deletedBlock = blocks.blocks.find((block) => {
        const remainedBlock = action.blocks.find(
          (remainedBlock) => remainedBlock._id === block._id
        );
        return !remainedBlock;
      });

      const storeBlocks = action.blocks.filter(
        (block) => block.module === BlockModule.STORE
      );
      if (deletedBlock?.module === BlockModule.STORE && !storeBlocks.length) {
        const page = pages.pages[pages.currentPage];
        await changePageSettings({
          propName: ChangeablePageSettings.CART,
          propValue: {
            ...page.cart,
            enable: false,
          },
        })(store.dispatch, store.getState);
        sendClick(landing.merchantId, XAEvents.cartDisable);
      }
    }
    next(action);
  };
