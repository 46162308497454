import { closeModals } from './login-settings';
import {
  unShowShoppingCartByChangeAuthType,
  unShowShoppingCartByDeleteStore,
} from './shopping-cart';

export const middlewares = [
  unShowShoppingCartByDeleteStore,
  unShowShoppingCartByChangeAuthType,
  closeModals,
];
