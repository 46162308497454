import Cookies from 'js-cookie';

export enum CookieNames {
  PARTNER_LOGIN_TOKEN = 'pa-v4-token',
  USER_LOGIN_TOKEN = 'xsolla_login_token_sb',
  COUNTRY_CODE = 'sb_country_code', // iso3166, e.g.RU
  COUNTRY_CODE_3 = 'sb_country_code3', // e.g. RUS
  COUNTRY_NAME = 'sb_country_name', // e.g. Russian Federation
  LANDING_LOCALE = 'sb-landing-locale', // e
}

export const setCookie = (cname: string, cvalue: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') {
    // TODO handle case with SSR
    return undefined;
  }
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const countryCode = getCookie(CookieNames.COUNTRY_CODE);
export const getCountryCode = () => countryCode;

export const deleteCookie = (name: string) => {
  Cookies.remove(name);
};

export const getTokenFromCookie = (isPreviewMode = false) =>
  isPreviewMode
    ? getCookie(CookieNames.PARTNER_LOGIN_TOKEN)
    : getCookie(CookieNames.USER_LOGIN_TOKEN);
