/**
 * @flow
 * @prettier
 */

import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';
import produce from 'immer';
import update from 'immutability-helper';

import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';
import type { LandingWithStructure } from '@site-builder/common/src/flow-types/model/landing';
import type { AbstractBlock } from '@site-builder/common/src/types/block/common/abstract-block';

import {
  movePageBlock,
  saveBlock,
  deletePageBlock,
  duplicatePageBlock,
  addPageBlock,
} from '../../../utils/api';
import { reorderArray } from '../../../utils/array-helper';
import {
  scrollToBlockById,
  twinkleElement,
} from '../../../utils/common-helper';
import { sendClick, XAEvents } from '../../../utils/init-xsolla-analytics';
import { assetsClose } from './assets';
import {
  initSavingProcess,
  dataSaved,
  dataDontSave,
  closeBlockComponentsSettings,
  closeTranslationsSettings,
} from './spaces';
import { addSubscriptionsPacksBlock } from './subscriptionsPacksBlock';

const INIT_BLOCKS_DATA = 'INIT_BLOCKS_DATA';
const SET_BLOCKS = 'SET_BLOCKS';
const REPLACE_BLOCKS = 'REPLACE_BLOCKS';
const ADD_BLOCK = 'ADD_BLOCK';
const MOVE_BLOCK = 'MOVE_BLOCK';
const DUPLICATE_BLOCK = 'DUPLICATE_BLOCK';
export const DELETE_BLOCK = 'DELETE_BLOCK';
const CHANGE_BLOCK_BY_ID = 'CHANGE_BLOCK_BY_ID';
const INIT_CURRENT_BLOCK = 'INIT_CURRENT_BLOCK';
export const CHECKOUT_BLOCK = 'CHECKOUT_BLOCK';
const SAVE_CURRENT_BLOCK = 'SAVE_CURRENT_BLOCK';
const SCROLL_BLOCK = 'SCROLL_BLOCK';
const CLOSE_BLOCK_SETTINGS = 'CLOSE_BLOCK_SETTINGS';
const SLIDE_ADDING = 'SLIDE_ADDING';
const SLIDE_ADDED = 'SLIDE_ADDED';
const CHECKOUT_BLOCK_BY_COMPONENT = 'CHECKOUT_BLOCK_BY_COMPONENT';
const CHANGE_BLOCK_DATA = 'CHANGE_BLOCK_DATA';
const CHANGE_BLOCK_BY_INDEX = 'CHANGE_BLOCK_BY_INDEX';
const CHANGE_CURRENT_BLOCK = 'CHANGE_CURRENT_BLOCK';

export type BlocksState = {|
  blocks: AbstractBlock[],
  currentBlock: ?AbstractBlock,
  scrolledBlock: ?AbstractBlock,
  isActiveEditor: boolean,
  isSlideAdded: boolean,
|};

const initialState: BlocksState = {
  blocks: [],
  // $FlowFixMe
  currentBlock: {},
  // $FlowFixMe
  scrolledBlock: {},
  isActiveEditor: false,
  isSlideAdded: false,
};

export const slideAdded =
  () => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { isSlideAdded },
    } = getState();
    if (isSlideAdded) {
      dispatch({
        type: SLIDE_ADDED,
        isSlideAdded: false,
      });
    }
  };

export const saveBlockProcess =
  (block: AbstractBlock) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, _id: landingId } = landing;

    dispatch(initSavingProcess());
    try {
      const savedBlock = await saveBlock({
        merchantId,
        projectId,
        landingId,
        data: block,
      });

      if (savedBlock.status >= 200 && savedBlock.status < 300) {
        dispatch(dataSaved());
        dispatch(slideAdded());
        return savedBlock.data;
      }

      dispatch(dataDontSave());
      return false;
    } catch (error) {
      dispatch(dataDontSave(error));
      return false;
    }
  };

export const constUpdateBlocks = (
  blocks: BlocksState,
  currentBlock: AbstractBlock
) => ({
  type: CHANGE_BLOCK_DATA,
  currentBlock,
});

const constChangeBlockByIndex = (blockIndex, currentBlock) => ({
  type: CHANGE_BLOCK_BY_INDEX,
  currentBlock,
  blockIndex,
});

export const setBlocks = (blocks: AbstractBlock[]) => ({
  type: SET_BLOCKS,
  blocks,
});

export const replaceBlocks =
  (blocksForReplace: AbstractBlock[]) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
    } = getState();
    const blocksWithReplaced = blocks.map((block) => {
      const blockForReplace = blocksForReplace.find(
        ({ _id }) => _id === block._id
      );
      if (blockForReplace) {
        return blockForReplace;
      }
      return block;
    });

    dispatch({
      type: REPLACE_BLOCKS,
      blocks: blocksWithReplaced,
    });
  };

export const initBlocks =
  (landing: LandingWithStructure) => (dispatch: Dispatch) => {
    const blocks = [];
    landing.pages.forEach((page) => {
      page.blocks.forEach((block) => {
        blocks.push(block);
      });
    });

    dispatch({
      type: INIT_BLOCKS_DATA,
      blocks,
    });
  };

export const closeBlockSettings =
  () => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { isActiveEditor },
    } = getState();

    if (isActiveEditor) {
      dispatch({
        type: CLOSE_BLOCK_SETTINGS,
        isActiveEditor: false,
      });
    }
  };

export const closeAllSettings = () => (dispatch: Dispatch) => {
  dispatch(assetsClose());
  dispatch(closeBlockSettings());
};

export const slideAdding = () => (dispatch: Dispatch) => {
  dispatch({
    type: SLIDE_ADDING,
    isSlideAdded: true,
  });
};

export const scrollBlock =
  (nextBlockId: string) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
    } = getState();
    const scrolledBlock = blocks.find((block) => block._id === nextBlockId);

    if (scrolledBlock && scrolledBlock._id) {
      dispatch({
        type: SCROLL_BLOCK,
        scrolledBlock,
      });
    }
  };

export const saveCurrentBlock =
  (nextBlockId: string) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    dispatch({
      type: SAVE_CURRENT_BLOCK,
      currentBlock: blocks.blocks.find((block) => block._id === nextBlockId),
    });
  };

export const checkoutBlock =
  (nextBlockId: string) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    const currentBlock = blocks.blocks.find(
      (block) => block._id === nextBlockId
    );
    const currentModule = currentBlock ? currentBlock.module : '';

    if (currentModule === BlockModule.SUBSCRIPTIONS) {
      dispatch(addSubscriptionsPacksBlock(currentBlock));
    }

    dispatch(closeBlockComponentsSettings(currentModule));
    dispatch(assetsClose());
    dispatch(closeTranslationsSettings());

    if (!currentBlock) {
      return;
    }
    dispatch({
      type: CHECKOUT_BLOCK,
      currentBlock,
      isActiveEditor: true,
    });

    twinkleElement(0);
  };

export const checkoutBlockByComponent =
  (nextBlockId: string, componentIndex: number) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    dispatch(assetsClose());
    dispatch(closeTranslationsSettings());

    const currentBlock = blocks.blocks.find(
      (block) => block._id === nextBlockId
    );
    if (!currentBlock) {
      return;
    }
    dispatch({
      type: CHECKOUT_BLOCK_BY_COMPONENT,
      currentBlock,
      isActiveEditor: true,
      component: componentIndex,
    });

    twinkleElement(0);
  };

export const changeCurrentBlock =
  (currentBlock: AbstractBlock) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
    } = getState();
    const blockIndex = blocks.findIndex((el) => el._id === currentBlock._id);
    dispatch({
      type: CHANGE_CURRENT_BLOCK,
      currentBlock,
      blockIndex,
    });

    return dispatch(saveBlockProcess(currentBlock));
  };

export const changeValue =
  (prop: string, value: mixed, needSave: boolean = true) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    const currentBlock = { ...blocks.currentBlock };

    const currentBlockCopy = update(currentBlock, {
      values: { [prop]: { $set: value } },
    });

    dispatch(constUpdateBlocks(blocks, currentBlockCopy));

    if (needSave) {
      return dispatch(saveBlockProcess(currentBlockCopy));
    }
    return true;
  };

export const changeUnderComponent =
  ({
    index,
    prop,
    value,
    blockIndex,
  }: {
    index: number,
    prop: string,
    value: mixed,
    blockIndex: number,
  }) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    const currentBlock = { ...blocks.currentBlock };

    const currentBlockCopy = produce(currentBlock, (draft) => {
      draft.components[blockIndex].components[index][prop] = value;
    });

    dispatch(constUpdateBlocks(blocks, currentBlockCopy));
    dispatch(saveBlockProcess(currentBlockCopy));
  };

export const changeComponent =
  ({
    index,
    prop,
    value,
    items,
    needSave = true,
    scroll = false,
    isSelfComponentValue = false,
  }: {
    index: number,
    prop: string,
    value: mixed,
    items?: Object[],
    needSave?: boolean,
    scroll?: boolean,
    isSelfComponentValue?: boolean,
  }) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks,
      landing: { landing },
      sideMenu: { currentComponentIndex },
    } = getState();
    const currentBlock = { ...blocks.currentBlock };

    let currentBlockCopy;
    let enabledComponent;

    if (items) {
      currentBlockCopy = update(currentBlock, {
        components: { $apply: (comps) => comps },
      });
      items.forEach((item) => {
        currentBlockCopy.components[index][item.prop] = item.value;
      });
    } else if (currentBlock.module === 'promoSlider') {
      const isComponentInside = !(value instanceof Object) || !value.logo;

      if (isSelfComponentValue) {
        currentBlockCopy = update(currentBlock, {
          components: {
            [currentComponentIndex]: {
              components: { [index]: { $set: value } },
            },
          },
        });
      } else if (isComponentInside) {
        currentBlockCopy = update(currentBlock, {
          components: {
            [currentComponentIndex]: {
              components: { [index]: { [prop]: { $set: value } } },
            },
          },
        });
        enabledComponent =
          currentBlock.components[currentComponentIndex].components[index];
      } else {
        currentBlockCopy = update(currentBlock, {
          components: { [index]: { [prop]: { $set: value } } },
        });
        enabledComponent = currentBlock.components[index];
      }
    } else {
      currentBlockCopy = update(currentBlock, {
        components: { [index]: { [prop]: { $set: value } } },
      });
      enabledComponent = currentBlock.components[index];
    }
    dispatch(constUpdateBlocks(blocks, currentBlockCopy));

    if (scroll) {
      scrollToBlockById(`${currentBlock.module}-${currentBlockCopy._id}`);
    }
    if (prop === 'enable' && enabledComponent) {
      const eventName = value
        ? XAEvents.addComponent
        : XAEvents.deleteComponent;
      sendClick(landing.merchantId, eventName, enabledComponent.type, {
        blockType: currentBlock.module,
      });
    }

    if (needSave) {
      return dispatch(saveBlockProcess(currentBlockCopy));
    }
    return true;
  };

export const addSeveralComponents =
  (components: Object[]) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    if (!Array.isArray(components)) {
      return false;
    }

    const {
      blocks,
      sideMenu: { currentComponentIndex, isBlockComponentsSettingsShown },
    } = getState();
    const currentBlock = { ...blocks.currentBlock };

    let currentBlockCopy;
    if (isBlockComponentsSettingsShown) {
      currentBlockCopy = update(currentBlock, {
        components: {
          [currentComponentIndex]: {
            components: {
              $set: components,
            },
          },
        },
      });
    } else {
      currentBlockCopy = update(currentBlock, {
        components: {
          $set: components,
        },
      });
    }

    const savedBlockResult = await dispatch(saveBlockProcess(currentBlockCopy));

    const blockIndex = blocks.blocks.findIndex(
      (el) => el._id === currentBlockCopy._id
    );
    return savedBlockResult
      ? dispatch(constChangeBlockByIndex(blockIndex, savedBlockResult))
      : false;
  };

export const addComponent =
  (component: Object[]) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks,
      sideMenu: { currentComponentIndex, isBlockComponentsSettingsShown },
    } = getState();
    const currentBlock = { ...blocks.currentBlock };

    let currentBlockCopy;

    // TODO component не имеет _i d, из-за этого падает ошибка при частом перерендере
    if (isBlockComponentsSettingsShown) {
      currentBlockCopy = update(currentBlock, {
        components: {
          [currentComponentIndex]: { components: { $push: [component] } },
        },
      });
    } else {
      currentBlockCopy = update(currentBlock, {
        components: { $push: [component] },
      });
    }
    const savedBlockResult = await dispatch(saveBlockProcess(currentBlockCopy));

    const blockIndex = blocks.blocks.findIndex(
      (el) => el._id === currentBlockCopy._id
    );
    return savedBlockResult
      ? dispatch(constChangeBlockByIndex(blockIndex, savedBlockResult))
      : false;
  };

export const removeComponent =
  (index: number) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks,
      landing: { landing },
      sideMenu: { currentComponentIndex, isBlockComponentsSettingsShown },
    } = getState();
    const currentBlock = { ...blocks.currentBlock };

    let currentBlockCopy;
    let deletedComponentType;

    if (isBlockComponentsSettingsShown) {
      currentBlockCopy = update(currentBlock, {
        components: {
          [currentComponentIndex]: { components: { $splice: [[index, 1]] } },
        },
      });
      deletedComponentType =
        currentBlock.components[currentComponentIndex].components[index].type;
    } else {
      currentBlockCopy = update(currentBlock, {
        components: { $splice: [[index, 1]] },
      });
      deletedComponentType = currentBlock.components[index].type;
    }

    const blockIndex = blocks.blocks.findIndex(
      (el) => el._id === currentBlockCopy._id
    );
    const savedBlockResult = await dispatch(saveBlockProcess(currentBlockCopy));

    sendClick(
      landing.merchantId,
      XAEvents.deleteComponent,
      deletedComponentType,
      { blockType: currentBlock.module }
    );

    return savedBlockResult
      ? dispatch(constChangeBlockByIndex(blockIndex, savedBlockResult))
      : false;
  };

export const changeBlockById =
  ({
    blockId,
    propName,
    propValue,
  }: {
    blockId: string,
    propName: string,
    propValue: mixed,
  }) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    const key = blocks.blocks.findIndex((el) => el._id === blockId);
    const updatedBlock = blocks.blocks[key];
    updatedBlock[propName] = propValue;
    dispatch({
      type: CHANGE_BLOCK_BY_ID,
      blockId,
      updatedBlock,
    });
  };

export const deleteBlock =
  () => async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks, currentBlock },
      landing: { landing },
      pages: { pages, currentPage },
    } = getState();
    const pageId = pages[currentPage]._id;
    const { merchantId, projectId, _id: landingId } = landing;
    const blockId = currentBlock._id;
    const updatedBlocks = blocks.filter((block) => block._id !== blockId);

    dispatch(initSavingProcess());

    const deletedBlock = await deletePageBlock({
      merchantId,
      projectId,
      landingId,
      pageId,
      blockId,
    });

    if (deletedBlock.status >= 200 && deletedBlock.status < 400) {
      dispatch({
        type: DELETE_BLOCK,
        blocks: updatedBlocks,
        isActiveEditor: false,
      });

      dispatch(dataSaved());
      return true;
    }

    dispatch(dataDontSave());
    return false;
  };

export const duplicateBlock =
  () => async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { currentBlock, blocks },
      landing: { landing },
      pages: { pages, currentPage },
    } = getState();
    const pageId = pages[currentPage]._id;
    const { merchantId, projectId, _id: landingId } = landing;
    const blockId = currentBlock._id;

    dispatch(initSavingProcess());

    const { data: duplicatedBlock, status } = await duplicatePageBlock({
      merchantId,
      projectId,
      landingId,
      pageId,
      data: { blockId },
    });

    if (status >= 200 && status < 400 && duplicatedBlock) {
      setImmediate(() => {
        scrollToBlockById(`${duplicatedBlock.module}-${duplicatedBlock._id}`);
      });
      const updatedBlocks = [...blocks];
      const currentBlockIndex = blocks.findIndex(({ _id }) => _id === blockId);
      updatedBlocks.splice(currentBlockIndex + 1, 0, duplicatedBlock);

      dispatch({
        type: DUPLICATE_BLOCK,
        blocks: updatedBlocks,
      });

      dispatch(dataSaved());
      dispatch(checkoutBlock(duplicatedBlock._id));
      return true;
    }

    dispatch(dataDontSave());
    return false;
  };

export const moveBlock =
  (source: number, destination: number) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
      landing: { landing },
      pages: { pages, currentPage },
    } = getState();
    const reorderedBlocks = reorderArray(blocks, source, destination);
    const pageId = pages[currentPage]._id;
    const { merchantId, projectId, _id: landingId } = landing;
    const data = { source, destination };

    dispatch(initSavingProcess());

    dispatch({
      type: MOVE_BLOCK,
      blocks: reorderedBlocks,
    });
    movePageBlock({
      merchantId,
      projectId,
      landingId,
      pageId,
      data,
    })
      .then(dispatch(dataSaved()))
      .catch((error) => dispatch(dataDontSave(error)));
  };

export const addBlock =
  (block: AbstractBlock, index: number) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
      landing: { landing },
      pages: { pages, currentPage },
    } = getState();
    const pageId = pages[currentPage]._id;
    const { merchantId, projectId, _id: landingId } = landing;
    const data = { block, index };

    dispatch(initSavingProcess());

    const { data: newBlock, status } = await addPageBlock({
      merchantId,
      projectId,
      landingId,
      pageId,
      data,
    });

    if (status >= 200 && status < 400 && newBlock) {
      const updatedBlocks = [...blocks];
      updatedBlocks.splice(index, 0, newBlock);

      dispatch({
        type: ADD_BLOCK,
        blocks: updatedBlocks,
      });

      dispatch(dataSaved());
      dispatch(checkoutBlock(newBlock._id));
      return newBlock;
    }

    dispatch(dataDontSave());
    return false;
  };

export default function reducerBlocks(
  state: Object = initialState,
  action: Action
) {
  switch (action.type) {
    case INIT_BLOCKS_DATA:
    case SET_BLOCKS:
    case REPLACE_BLOCKS:
    case ADD_BLOCK:
    case MOVE_BLOCK:
    case DUPLICATE_BLOCK:
      return { ...state, blocks: action.blocks };

    case DELETE_BLOCK:
      return {
        ...state,
        blocks: action.blocks,
        isActiveEditor: action.isActiveEditor,
      };

    case CHANGE_BLOCK_BY_ID:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block._id !== action.blockId) {
            return block;
          }
          return {
            ...block,
            ...action.updatedBlock,
          };
        }),
      };

    case INIT_CURRENT_BLOCK:
      return { ...state, currentBlock: action.currentBlock };

    case CHECKOUT_BLOCK:
      return {
        ...state,
        currentBlock: action.currentBlock,
        isActiveEditor: action.isActiveEditor,
      };

    case SAVE_CURRENT_BLOCK:
      return { ...state, currentBlock: action.currentBlock };

    case SCROLL_BLOCK:
      return { ...state, scrolledBlock: action.scrolledBlock };

    case CLOSE_BLOCK_SETTINGS:
      return { ...state, isActiveEditor: action.isActiveEditor };

    case SLIDE_ADDING:
      return { ...state, isSlideAdded: action.isSlideAdded };

    case SLIDE_ADDED:
      return { ...state, isSlideAdded: action.isSlideAdded };

    case CHECKOUT_BLOCK_BY_COMPONENT:
      return {
        ...state,
        currentBlock: action.currentBlock,
        isActiveEditor: action.isActiveEditor,
        component: action.component,
      };

    case CHANGE_BLOCK_DATA:
      return {
        ...state,
        currentBlock: action.currentBlock,
        blocks: state.blocks.map((block) => {
          if (block._id !== action.currentBlock._id) {
            return block;
          }
          return {
            ...block,
            ...action.currentBlock,
          };
        }),
      };

    case CHANGE_BLOCK_BY_INDEX:
      return {
        ...state,
        currentBlock: action.currentBlock,
        blocks: update(state.blocks, {
          [action.blockIndex]: { $set: action.currentBlock },
        }),
      };

    case CHANGE_CURRENT_BLOCK:
      return {
        ...state,
        blocks: update(state.blocks, {
          [action.blockIndex]: { $set: action.currentBlock },
        }),
        currentBlock: action.currentBlock,
      };
    default:
      return state;
  }
}
