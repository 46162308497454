/**
 * @prettier
 * @flow
 * */

import { BUNDLE_TYPE } from '@site-builder/common/src/utils/store';

import type { CartItemObjectExtends } from '../../../../utils/types';

import { normalizePrice } from '../../../../utils/store-helper';
import { BundleCartItem } from './BundleCartItem';
import { CartItem } from './CartItem';
import { GameKeyCartItem } from './GameKeyCartItem';
import { VICartItem } from './VICartItem';

export function cartItemFactory(props: CartItemObjectExtends) {
  const { virtualItemType, price, type } = props;
  let normalizedPrice = price;
  if (
    price &&
    Object.prototype.hasOwnProperty.call(price, 'amount_without_discount')
  ) {
    normalizedPrice = normalizePrice((price: any));
  }
  const normalizedProps = {
    ...props,
    price: normalizedPrice,
  };

  if (['unit', 'game_key'].includes(type)) {
    return new GameKeyCartItem(normalizedProps);
  }

  if (type === BUNDLE_TYPE) {
    return new BundleCartItem(normalizedProps);
  }

  if (virtualItemType) {
    return new VICartItem(normalizedProps);
  }

  return new CartItem(normalizedProps);
}
