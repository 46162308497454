// @flow
import { combineReducers } from 'redux';

import type { Action } from '../../types';
import type { State as AssetsState } from './assets';
import type { State as Features } from './features';
import type { HiddenBlocks } from './hiddenBlocks';
import type { LandingState } from './landing';
import type { State as LauncherState } from './launcherData';
import type { State as LoginState } from './loginData';
import type { State as LoginWidgetInfoState } from './loginWidgetInfo';
import type { State as ModalsState } from './modals';
import type { ProjectInfoState } from './projectInfo';
import type { RetailersState } from './retailers';
import type { ScriptsState } from './scripts';
import type { StoreState } from './storeData';
import type { SubscriptionState } from './subscriptionData';
import type { CombinedReducer } from 'redux';

import assets from './assets';
import blocks from './blocks';
import features from './features';
import hiddenBlocks from './hiddenBlocks';
import intersection from './intersection';
import landing from './landing';
import { landingServices } from './landingServices';
import launcherData from './launcherData';
import loginData from './loginData';
import loginWidgetInfo from './loginWidgetInfo';
import modals from './modals';
import pages from './pages';
import projectInfoData from './projectInfo';
import retailersData from './retailers';
import scripts from './scripts';
import sideMenu from './spaces';
import storeData from './storeData';
import subscriptionData from './subscriptionData';
import subscriptionsPacks from './subscriptionsPacksBlock';

export type RootState = {
  landing: LandingState,
  pages: Object,
  blocks: Object, // TODO заменить на BlocksState
  sideMenu: Object,
  scripts: ScriptsState,
  assets: AssetsState,
  features: Features,
  intersection: Object,
  launcherData: LauncherState,
  loginData: LoginState,
  subscriptionData: SubscriptionState,
  subscriptionsPacks: Object,
  storeData: StoreState,
  projectInfoData: ProjectInfoState,
  modals: ModalsState,
  retailersData: RetailersState,
  hiddenBlocks: HiddenBlocks,
  loginWidgetInfo: LoginWidgetInfoState,
  landingServices: Object
};

const rootReducer: CombinedReducer<RootState, Action> = combineReducers({
  projectInfoData,
  loginData,
  landing,
  pages,
  blocks,
  sideMenu,
  scripts,
  assets,
  features,
  intersection,
  launcherData,
  subscriptionData,
  subscriptionsPacks,
  modals,
  storeData,
  retailersData,
  hiddenBlocks,
  loginWidgetInfo,
  landingServices,
});

export default rootReducer;
