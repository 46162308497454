/* eslint-disable camelcase */
export enum PaystationActions {
  OPEN = 'open-paystation',
  CLOSE = 'close-widget',
  CLICK_BTN_SHARE = 'click-btn-share',
  CREATE_INVOICE = 'create-invoice',
  CHOOSE_PAYMENT_WIDGET = 'choose-payment-widget',
  CHOOSE_METHOD = 'choose-method',
  CHANGE_STATUS = 'change-status',
}

export interface IPayStationPostMessageDataType {
  action: PaystationActions;
  value: string;
  state: string;
  date: string;
  milliseconds: number;
  timezone: string;
  session_id: string;
  payment_country: string;
  purchase_invoice_id: number;
  purchase_external_id: string;
  purchase_type: string;
  purchase_sum: number;
  purchase_currency: string;
  purchase_sku: string;
  purchase_description: string;
  virtual_currency_amount: number;
  coupon_code: number;
  user_id: string;
  user_country: string;
  total_sum: number;
  total_sum_currency: string;
  payment_instance_name: string;
}
