/**
 * @prettier
 * @flow
 * */

import { StoreAPIDataService } from '../services/StoreAPIDataService';
import { UniversalCart } from './UniversalCart';

export class GlobalUniversalCart {
  static _instance: UniversalCart;

  static getInstance(
    projectId: string,
    cartId: string,
    dataAPIService: StoreAPIDataService,
    userToken?: string
  ) {
    if (!this._instance) {
      this._instance = new UniversalCart(
        projectId,
        cartId,
        dataAPIService,
        userToken
      );
    }
    return this._instance;
  }

  static get instance() {
    return this._instance;
  }
}
