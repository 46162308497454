/** @prettier */
import { IPayStationCreateTokenUISettings } from '../../types/paystation/create-token';
import { isCustomPayStationTheme } from './showCustomPayStationTheme';

let payStationWidgetSettings: null | IPayStationCreateTokenUISettings = null;

export const setPaystationWidgetUISettings = (
  value: IPayStationCreateTokenUISettings
) => {
  payStationWidgetSettings = value;
};

export const getPaystationWidgetUISettings = () =>
  payStationWidgetSettings || { theme: isCustomPayStationTheme() };
