import axios from 'axios';

import { BASE_URL, LOGIN_API } from '../../config';
import requestErrorHandler from '../request-error-handler';
import { SocialsResponse } from './types';

export const postLoginCustomization = ({ loginId }) =>
  requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `login/widget-customization/${loginId}`,
      method: 'post',
      withCredentials: false,
    })
  );

export const getLoginCustomization = ({ loginId }) =>
  requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `login/widget-customization/${loginId}`,
      method: 'get',
      withCredentials: false,
    })
  );

export const putLoginCustomization = ({ loginId, data }) =>
  requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `login/widget-customization/${loginId}`,
      method: 'put',
      withCredentials: false,
      data,
    })
  );

export const publishLoginCustomization = ({ loginId }) =>
  requestErrorHandler(
    axios({
      baseURL: BASE_URL,
      url: `login/widget-customization/${loginId}/publication`,
      method: 'post',
      withCredentials: false,
    })
  );

export const loginWidgetSettings = ({ loginId, paToken }) =>
  requestErrorHandler(
    axios({
      baseURL: LOGIN_API,
      url: `/projects/${loginId}/configuration`,
      method: 'get',
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${paToken}`,
      },
    })
  );

export const getConnectedSocials = ({
  loginId,
}): Promise<{ data: SocialsResponse }> =>
  requestErrorHandler(
    axios({
      baseURL: 'https://cdn.xsolla.net/xsolla-login-widget', // it's request with cache
      url: `/projects/${loginId}/settings/socials/by_region`,
      method: 'get',
      withCredentials: false,
    })
  );
