/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: '¿Necesitas vincular una cuenta existente?',
  wb_auth_modal_account_create: '¿Necesitas crear una cuenta de GOT:C?',
  wb_auth_modal_apple: 'Iniciar sesión con Apple',
  wb_auth_modal_conjunction_word: '-o-',
  wb_auth_modal_description:
    'Esta tienda usa tu vinculación a Game of Thrones: Conquest para encontrar tu cuenta.',
  wb_auth_modal_facebook: 'Iniciar sesión con Facebook',
  wb_auth_modal_google: 'Iniciar sesión con Google',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/es/articles/360001088488-Preguntas-frecuentes-sobre-c%C3%B3mo-guardar-en-la-nube',
  wb_auth_modal_title: 'Iniciar sesión con tu vinculación a GOT:C',
  wb_notification_added_to_cart: 'Añadido a tu carrito',
  wb_notification_cart_limit: 'El artículo supera el límite de compra.',
  wb_notification_not_added_to_cart: 'No se puede añadir al carrito.',
  wb_notification_updated_cart: 'Tu carrito ha sido actualizado',
  wb_selector_amount_in_cart: 'En el carrito',
  wb_user_account_modal_continue: 'Continuar',
  wb_user_account_modal_description:
    'Esta tienda se vincula con Game of Thrones: Conquest para encontrar tu cuenta.',
  wb_user_account_modal_error:
    'No encontramos ninguna cuenta asociada con esa vinculación. Vuelve a intentarlo.',
  wb_user_account_modal_footer_privacy: 'Política de privacidad',
  wb_user_account_modal_footer_terms: 'Términos de uso',
  wb_user_account_modal_footer_text:
    'Después de iniciar sesión, serás dirigido a un sitio de compras operado por Xsolla, responsable exclusivo del contenido de la página y todos los aspectos de tu compra.',
  wb_user_account_modal_kingdom: 'Reino',
  wb_user_account_modal_logout: 'Cerrar sesión',
  wb_user_account_modal_title: 'VINCULAR ESTA CUENTA DE GOT:C',
};

export default localizations;
