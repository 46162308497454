export const isWindowParent = () => window !== window.top && true;

export const lightBoxWindow = () =>
  !!window.opener && !window.opener.closed ? window.opener : window.parent;

export const sendPostMessage = (command: string, data: any = {}) => {
  const parentWindow = lightBoxWindow();
  if (parentWindow && parentWindow.postMessage) {
    parentWindow.postMessage(JSON.stringify({ command, ...data }), '*');
  }
};

export const disableTokenLess = () => {
  sendPostMessage('sb-disable-tokenless');
};

export const enableCommerceAPI = () => {
  sendPostMessage('sb-enable-commerce-api');
};

export const enableStore = (projectId: string, subscriptionsComponent: any) => {
  sendPostMessage('sb-enable-store-subscriptions', {
    projectId: String(projectId),
    subscriptionsComponent,
  });
};

export const closeSiteBuilderFrame = () => {
  sendPostMessage('sb-close');
};

export const publishFromSiteBuilderFrame = () => {
  sendPostMessage('sb-publish');
};

export const openMailchimpCreateModal = (login: { login: string }) => {
  sendPostMessage('sb-open-mailchimp-create-modal', login);
};

export const openMailchimpDeleteModal = (login: { login: string }) => {
  sendPostMessage('sb-open-mailchimp-delete-modal', login);
};

export const openGameKeysWizard = () => {
  sendPostMessage('sb-open-game-keys-wizard');
};

export const openSubscriptionCreateModal = () => {
  sendPostMessage('sb-open-subscriptions-create-modal', {
    subscriptionsBlockForm: true,
  });
};

export const publishEmailPostMessage = (projectId: string) => {
  sendPostMessage('sb-publish-email', {
    data: {
      projectId: projectId.toString(),
      isPublished: true,
    },
  });
};

export const publishLoginPostMessage = (loginId: string) => {
  sendPostMessage('sb-publish-login', {
    data: {
      loginId: loginId.toString(),
      isPublished: true,
    },
  });
};

export const enableWebhooks = (webhookUrl: string) => {
  sendPostMessage('sb-enable-webhooks', {
    webhookUrl,
  });
};

export const cfdTemplateCreated = (projectData: any) => {
  sendPostMessage('sb-cfd-template-created', {
    projectData,
  });
};
