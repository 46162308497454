import { XSOLLA_BB_WIDGET_LINK, XSOLLA_PAY_STATION_WIDGET_LINK } from '../../config';

const getScriptOnce = (() => {
  if (typeof document === 'undefined') {
    return () => ({});
  }

  const promises = {};
  const cache = {};
  return (source, scriptObjects) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = source;
    const promise = new Promise(resolve => {
      if (source in cache) {
        resolve(cache[source]);
        return;
      }
      script.addEventListener('load', () => {
        const objects = scriptObjects.reduce((accumulator, current) => {
          accumulator[current] = window[current];
          return { ...accumulator };
        }, {});
        cache[source] = objects;
        resolve(objects);
      });
    });
    return () => {
      if (source in promises) {
        return promises[source];
      }
      document.body.appendChild(script);
      promises[source] = promise;
      return promise;
    };
  };
})();

export const getSwiperScript = getScriptOnce('https://cdn.xsolla.net/site-builder/static/swiper.min.js', ['Swiper']);
export const getTwitterWidgets = getScriptOnce('https://platform.twitter.com/widgets.js', ['twttr']);
export const getTwitchEmbed = getScriptOnce('https://embed.twitch.tv/embed/v1.js', ['Twitch']);
export const getP2PScript = getScriptOnce(XSOLLA_BB_WIDGET_LINK, ['XBuyButtonWidget']);
export const getPayStationScript = getScriptOnce(XSOLLA_PAY_STATION_WIDGET_LINK, ['XPayStationWidget']);
export const getLoginScript = getScriptOnce('https://cdn.xsolla.net/xsolla-login-widget/sdk/2.2.3/xl.min.js', ['XL']);
