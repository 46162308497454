/**
 * @flow
 * @prettier
 */

import type { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import type { SubscriptionPlan } from '@site-builder/common/src/types/model/subscription';

export { api } from './api';

export const formatDate = (dateString: string, locale: FiveSymbolLocale) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const event = new Date(dateString);

  return event.toLocaleString(locale, options);
};

export const getSelectedPlans = (
  ids: number[],
  subscriptionPlans: SubscriptionPlan[]
): SubscriptionPlan[] =>
  subscriptionPlans.filter((plan) => ids.includes(plan.plan_id));
