import { changeHRef } from '@site-builder/common/src/url';

import { Environment } from './types';

export const getSitebuilderURL = (environment: string) => {
  if (environment === 'development') {
    return 'http://localhost:3000';
  }
  if (environment === 'stage') {
    return 'https://site-builder.nl-k8s-stage.srv.local';
  }
  return 'https://sitebuilder.xsolla.com';
};

export const getUserIdServiceURL = (environment: Environment) => {
  if (environment !== 'production') {
    return 'https://sb-user-id-service.nl-k8s-stage.srv.local/api/v1';
  }
  return 'https://sb-user-id-service.xsolla.com/api/v1';
};

export const clearUrlParams = () => {
  window.history.replaceState(null, '', window.location.pathname);
};

export { changeHRef };
