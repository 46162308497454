/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Musst du ein existierendes Konto verknüpfen?',
  wb_auth_modal_account_create: 'Musst du ein GoT:C-Konto erstellen?',
  wb_auth_modal_apple: 'Mit Apple anmelden',
  wb_auth_modal_conjunction_word: '-oder-',
  wb_auth_modal_description:
    'Dieser Laden benutzt deine „Game of Thrones: Conquest“-Verknüpfungen, um dein Konto zu finden',
  wb_auth_modal_facebook: 'Mit Facebook anmelden',
  wb_auth_modal_google: 'Mit Google anmelden',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/de/articles/360001088488-FAQ-zum-Cloud-Speicher',
  wb_auth_modal_title: 'Mit deiner GoT:C-Verknüpfung einloggen',
  wb_notification_added_to_cart: 'Deinem Warenkorb hinzugefügt',
  wb_notification_cart_limit: 'Der Artikel überschreitet das Einkaufslimit.',
  wb_notification_not_added_to_cart:
    'Kann nicht in den Warenkorb gelegt werden.',
  wb_notification_updated_cart: 'Dein Warenkorb wurde aktualisiert',
  wb_selector_amount_in_cart: 'Im Warenkorb',
  wb_user_account_modal_continue: 'Weiter',
  wb_user_account_modal_description:
    'Dieser Laden benutzt deine „Game of Thrones: Conquest“-Verknüpfungen, um dein Konto zu finden',
  wb_user_account_modal_error:
    'Wir konnten kein Konto finden, das zu dieser Verknüpfung gehört. Bitte versuche es später nochmal ',
  wb_user_account_modal_footer_privacy: 'Datenschutzrichtlinien',
  wb_user_account_modal_footer_terms: 'Nutzungsbedingungen',
  wb_user_account_modal_footer_text:
    'Nach der Anmeldung wirst du zu einer Einkaufsseite, betrieben durch Xsolla, weitergeleitet, die allein für den Inhalt der Webseite und alle Aspekte deines Einkaufs verantwortlich ist.',
  wb_user_account_modal_kingdom: 'Königreich',
  wb_user_account_modal_logout: 'Abmelden',
  wb_user_account_modal_title: 'DIESES GOT:C-KONTO VERKNÜPFEN',
};

export default localizations;
