// @flow
import { fiveSymbolsMap, TwoSymbolLocale } from '@site-builder/common/src/types/locale'

import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';
import type { Sku } from '@site-builder/common/src/flow-types/sku';
import type { Landing } from '@site-builder/common/src/types/model/landing';
import type {StoreItemsGroup, StoreItem, StoreBundle} from '@site-builder/common/src/types/store';

import {
  getStoreItemsGroups,
  getStoreVirtualCurrencies,
  getStoreVirtualCurrencyPackages,
  getStoreGames,
  getAdminStoreGames,
  getStoreBundles,
  getSKU
} from '../../../utils/api';
import {
  collectStoreItemsGroups, filterGamesList
} from '../../../utils/store-helper';



export type StoreState = {
  storeItemsGroups: StoreItemsGroup[],
  virtualCurrencyPacks: StoreItem[],
  virtualCurrencies: StoreItem[],
  storeGames: StoreItem[],
  storeAdminGames: Object[],
  loading: boolean,
  bundles: StoreItem[],
  sku: Sku[],
}

export type payloadType = {
  storeItemsGroups?: StoreItemsGroup[],
  virtualCurrencyPacks?: StoreItem[],
  virtualCurrencies?: StoreItem[],
  storeGames?: StoreItem[],
  storeAdminGames?: Object[],
  bundles?: StoreItem[],
  sku?: Sku[]
}

const initialState: StoreState = {
  storeItemsGroups: [],
  virtualCurrencyPacks: [],
  virtualCurrencies: [],
  storeGames: [],
  storeAdminGames: [],
  bundles: [],
  sku: [],
  loading: true
};

export const UPDATE_STORE_GROUPS_DATA = 'UPDATE_STORE_GROUPS_DATA';
export const UPDATE_STORE_GROUPS_DATA_FOR_EDITOR = 'UPDATE_STORE_GROUPS_DATA_FOR_EDITOR';
export const START_STORE_DATA_LOADING = 'START_STORE_DATA_LOADING';
export const END_STORE_DATA_LOADING = 'END_STORE_DATA_LOADING';
export const UPDATE_STORE_SERVER_ITEMS_DATA = 'UPDATE_STORE_SERVER_ITEMS_DATA';
export const ADD_SKU_TO_LIST = 'ADD_SKU_TO_LIST';
export const SET_SERVER_SKU = 'SET_SERVER_SKU';

const ITEMS__OFFSET = 50;

const getBundlesRecursive = async (
  params: {
    merchantId: string,
    projectId: string,
    landingId: string,
    locale?: TwoSymbolLocale,
    offset?: number }
): Promise<StoreBundle[]> => {
  const response = await getStoreBundles(params)
  if (response.status !== 200) {
    return [];
  }
  const list = response.data.items;
  if (list.length === ITEMS__OFFSET) {
    const { offset } = params;
    const secondList = await getBundlesRecursive({
      ...params,
      offset: offset ? offset + ITEMS__OFFSET : ITEMS__OFFSET,
    });
    return [...list, ...secondList];
  }
  return list;
}

const getVirtualItemsGroups = async ({
  merchantId, projectId, landingId, locale
}) => {
  const responseStoreItemsGroups = await getStoreItemsGroups({
    merchantId, projectId, landingId, locale
  });
  if (responseStoreItemsGroups.status !== 200) {
    // TODO Add error handling, 'Error while receiving store items groups'
    return [];
  }
  const { data } = responseStoreItemsGroups;
  return collectStoreItemsGroups(data);
};

export const updateStoreGamesData = () => async (dispatch: Dispatch, getState: GetState<RootState>) => {
  const { landing: { locale, landing } } = getState();
  const shortLocale = fiveSymbolsMap[locale];
  const { projectId, merchantId, _id: landingId } = landing;
  dispatch({ type: START_STORE_DATA_LOADING });
  const params = {
    merchantId, projectId, landingId, locale: shortLocale
  };

  try {
    // TODO добавить полноценную проверку на корректность ответа, может прийти объект ошибки
    const [
      { data: games },
      adminGamesResponse
    ] = await Promise.all([
      getStoreGames(params),
      getAdminStoreGames(params)
    ]);

    let adminGames = [];
    if (adminGamesResponse.status === 200) {
      adminGames = adminGamesResponse.data;
    }

    const filteredGames = filterGamesList(games, adminGames);
    dispatch({
      type: UPDATE_STORE_GROUPS_DATA,
      payload: {
        storeGames: filteredGames || [],
        storeAdminGames: adminGames
      }
    });
  } finally {
    dispatch({ type: END_STORE_DATA_LOADING });
  }
};

export const initStoreItemsGroupsData = (landing: Landing) => async (dispatch: Dispatch, getState: GetState<RootState>) => {
  const { landing: { locale } } = getState();
  const shortLocale = fiveSymbolsMap[locale];
  const { projectId, merchantId, _id: landingId } = landing;
  dispatch({ type: START_STORE_DATA_LOADING });
  const params = {
    merchantId, projectId, landingId, locale: shortLocale
  };

  try {
    // TODO добавить полноценную проверку на корректность ответа, может прийти объект ошибки
    const [
      viGroups,
      vcPacksResponse,
      vcsResponse,
      gamesResponse,
      adminGamesResponse,
      bundleList,
      skuResponse,
    ] = await Promise.all([
      getVirtualItemsGroups(params),
      getStoreVirtualCurrencyPackages(params),
      getStoreVirtualCurrencies(params),
      getStoreGames(params),
      getAdminStoreGames(params),
      getBundlesRecursive(params),
      getSKU(params)
    ]);

    // TODO разбить на отдельные экшены
    const games = gamesResponse?.data || [];
    const adminGames = adminGamesResponse?.data || [];
    const filteredGames = filterGamesList(games, adminGames);
    dispatch({
      type: UPDATE_STORE_GROUPS_DATA,
      payload: {
        storeItemsGroups: viGroups || [],
        virtualCurrencyPacks: vcPacksResponse?.data || [],
        virtualCurrencies: vcsResponse?.data || [],
        storeGames: filteredGames || [],
        storeAdminGames: adminGamesResponse?.data || [],
        bundles: bundleList || [],
        sku: skuResponse?.data?.sku || [], 
      }
    });
  } finally {
    dispatch({ type: END_STORE_DATA_LOADING });
  }
};

export const initServerStoreItemsGroupsData = (groups: StoreItemsGroup[]) => {
  const collectedGroupsData = collectStoreItemsGroups(groups);
  return {
    type: UPDATE_STORE_GROUPS_DATA,
    payload: { storeItemsGroups: collectedGroupsData || [] }
  };
};

export const setServerSKU = (sku: Sku[]) => (dispatch: Dispatch) => dispatch({
  type: SET_SERVER_SKU,
  sku
});


export const addSKU = (sku: Sku) => (dispatch: Dispatch) => dispatch({
  type: ADD_SKU_TO_LIST,
  sku
});


export default function storeDataReducer(state: StoreState = initialState, action: { payload: payloadType } & Action) {
  switch (action.type) {
    case UPDATE_STORE_GROUPS_DATA:
      return { ...state, ...action.payload, ...{ loading: false } };
    case UPDATE_STORE_GROUPS_DATA_FOR_EDITOR:
    case UPDATE_STORE_SERVER_ITEMS_DATA:
      return { ...state, ...action.payload };
    case START_STORE_DATA_LOADING:
      return { ...state, loading: true };
    case END_STORE_DATA_LOADING:
      return { ...state, loading: false };
    case ADD_SKU_TO_LIST:
      return { ...state, sku: [...state.sku, action.sku] };
    case SET_SERVER_SKU:
      return { ...state, sku: [...action.sku] };
    default:
      return state;
  }
}
