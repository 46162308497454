/** @prettier */

export enum AnalyticsCategories {
  CART = 'Cart_landing',
  CTA = 'CTA_landing',
  FOOTER = 'Footer_landing',
  HEADER = 'Header_landing',
  PAYSTATION = 'Paystation_landing',
  PACKS = 'Packs_landing',
  PROMO = 'Promo_landing',
  STORE = 'Store_landing',
  UID_WIDGET = 'UID_widget_landing',
}
