import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import React from 'react';

import userLoginIcon from '../../../../assets/images/icons/arrow-left-go-back.svg';
import { SocialInfo } from '../../../../utils/login/types';
import {
  getButtonGoBackTextForUserIdModal,
  getSocialNameTextForUserIdModal,
} from '../../../../utils/translation';

import './style.pcss';

const svgByName = (name: string): string => {
  try {
    return require(`../../../../assets/images/socials/icons/login/${name}.svg`); // eslint-disable-line
  } catch (error) {
    return '';
  }
};

interface AllSocialsMediaProps {
  goBackClick: () => void;
  loginBySocial: (providerName: string) => void;
  socials: SocialInfo[];
  locale: FiveSymbolLocale;
}

export const AllSocialsMedia = ({
  goBackClick,
  loginBySocial,
  socials,
  locale,
}: AllSocialsMediaProps) => {
  return (
    <>
      <button
        className='social-list__back'
        onClick={goBackClick}
        data-testid='go-back'
      >
        <div
          className='social-list__back-icon'
          dangerouslySetInnerHTML={{ __html: userLoginIcon }} // eslint-disable-line
        />
        <p className='social-list__back-text'>
          {getButtonGoBackTextForUserIdModal(locale)}
        </p>
      </button>
      <div className='social-list__wrapper'>
        {socials.map((social) => (
          <button
            key={social.name}
            className='social-list__link-button'
            onClick={() => loginBySocial(social.name)}
            data-testid={social.name}
          >
            <div
              className='social-list__icon'
              dangerouslySetInnerHTML={{ __html: svgByName(social.name) }} // eslint-disable-line
            />
            <p className='social-list__name'>
              {getSocialNameTextForUserIdModal(social.name)(locale)}
            </p>
          </button>
        ))}
      </div>
    </>
  );
};
