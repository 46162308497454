// @flow
import type { Action, Dispatch } from '../../types';
import type { Landing } from '@site-builder/common/src/types/model/landing';
import type { Subscription } from '@site-builder/common/src/types/model/subscription';

import { getSubscriptionPlans } from '../../../utils/api';

export type SubscriptionState = {|
  subscriptionPlans: Subscription[],
  loading: boolean
|}

const initialState: SubscriptionState = {
  subscriptionPlans: [],
  loading: true
};

export const UPDATE_SUBSCRIPTION_DATA = 'UPDATE_SUBSCRIPTION_DATA';
export const START_SUBSCRIPTION_LOADING = 'START_SUBSCRIPTION_LOADING';
export const END_SUBSCRIPTION_LOADING = 'END_SUBSCRIPTION_LOADING';

export const initSubscriptionData = (landing: Landing) => async (dispatch: Dispatch) => {
  const { merchantId, projectId, _id } = landing;
  dispatch({ type: START_SUBSCRIPTION_LOADING });
  const responseSubscriptionPlans = await getSubscriptionPlans({
    merchantId,
    projectId,
    landingId: _id
  });
  if (responseSubscriptionPlans.status !== 200) {
    return false;
  }
  const { data } = responseSubscriptionPlans;

  dispatch({ type: END_SUBSCRIPTION_LOADING });
  return dispatch({
    type: UPDATE_SUBSCRIPTION_DATA,
    payload: { subscriptionPlans: data || [] }
  });
};

export const initServerSubscriptionData = (plans: Subscription[]) => ({
  type: UPDATE_SUBSCRIPTION_DATA,
  payload: { subscriptionPlans: plans || [] }
});

export default function subscriptionDataReducer(state: SubscriptionState = initialState, action: { payload: { subscriptionPlans: Subscription[] } } & Action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_DATA:
      return { ...state, ...action.payload, ...{ loading: false } };
    case START_SUBSCRIPTION_LOADING:
      return { ...state, loading: true };
    case END_SUBSCRIPTION_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}
