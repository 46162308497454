import type { Theme } from '../../../../utils/login/types';
import type { FiveSymbolLocale } from '@site-builder/common/src/types/locale';

import { FirstGeneration } from './firstGeneration';
import { ZeroGeneration } from './zeroGeneration';

const importLoginWidget = () =>
  // compilerOptions.moduleResolution: "node16" in tsconfig.json only requires *.js path for dynamic import
  // eslint-disable-next-line import/extensions
  import('./firstGeneration/index.js').then(
    (loginModule) => loginModule.FirstGeneration
  );

let widgetGenerationInstance: FirstGeneration | ZeroGeneration | null = null;

export const getWidgetGenerationInstance = () => widgetGenerationInstance;

export const init = async ({
  loginWidgetGeneration,
  loginId,
  locale,
  callbackUrl,
  payloadData,
  loginTheme,
}: {
  loginWidgetGeneration: 0 | 1 | null;
  loginId: string;
  locale: FiveSymbolLocale;
  callbackUrl: string;
  payloadData: Record<string, any>;
  loginTheme: Theme;
}) => {
  if (loginWidgetGeneration === 1) {
    const FirstGeneration = await importLoginWidget();
    widgetGenerationInstance = new FirstGeneration(
      {
        projectId: loginId,
        preferredLocale: locale,
        callbackUrl,
        payload: JSON.stringify(payloadData),
        _generation: 1,
      },
      loginTheme
    );
    await widgetGenerationInstance.init();
  } else if (loginWidgetGeneration === 0) {
    widgetGenerationInstance = new ZeroGeneration({
      payload: JSON.stringify(payloadData),
      projectId: loginId,
      callbackUrl,
      locale,
      _generation: 0,
    });
    await widgetGenerationInstance.init();
  }
};
