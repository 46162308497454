/** @prettier */

export enum AnalyticsActions {
  ADD = 'add',
  ADD_BONUS = 'add-bonus',
  ADD_VC = 'add-vc',
  AUTH_SUCCESS = 'authorization-success',
  CHANGE_STATUS = 'change-status',
  CHECKOUT = 'checkout',
  CHOOSE_WIDGET = 'choose-widget',
  CHOOSE_METHOD = 'choose-method',
  CLEAR = 'clear',
  CLICK_APPSTORE = 'click-appstore',
  CLICK_BUY = 'click-buy',
  CLICK_CART = 'click-cart',
  CLICK_CHECKOUT = 'click-checkout',
  CLICK_GOOGLEPLAY = 'click-googleplay',
  CLICK_INSTRUCTION = 'click-instruction',
  CLICK_LOGIN = 'click-login',
  CLICK_MORE_INFO = 'click-more-info',
  CLICK_SHARE = 'share',
  CLICK_PAY = 'click-pay',
  CLOSE = 'close',
  CLOSE_BUNDLE_MODAL = 'pop-up-bundle_close',
  CLOSE_CART = 'close-cart',
  CLOSE_MODAL_USER_ID = 'close-uid-widget',
  CLOSE_USER_ID_INSTRUCTION = 'close-instruction',
  CONTINUE = 'continue',
  CONFIRM = 'confirm',
  CONFIRM_CODE = 'confirm_code',
  DELETE = 'delete',
  DISCOUNT_CART = 'discount-cart',
  DISCOUNT_ITEM = 'discount-item',
  ENTER_EMAIL = 'enter-email',
  ERROR = 'error',
  ERROR_CODE = 'error_code',
  INPUT = 'input',
  INPUT_EMAIL = 'input-email',
  INPUT_CODE = 'input-code',
  OPEN = 'open',
  OPEN_CART = 'open-cart',
  OPEN_LOGIN = 'open-login',
  OPEN_UID_WIDGET = 'open-uid-widget',
  OPEN_UID_WIDGET_FROM_BUY_NOW = 'open-uid-widget-from-buy-now',
  PURCHASE = 'purchase-success',
  REDUCE_VC = 'reduce-vc',
  REMOVE_CODE = 'remove-code',
  SELECT_DRM = 'select-drm',
  SUCCESS_ID = 'success_id',
  SOCIAL = 'social',
  SOCIAL_AUTH = 'authorization-by-social',
  USER_ID_PARTNER_METHOD_VALIDATION_START = 'method-validation-start',
}
