/** @prettier */
const NANOBIT_MERCHANT_ID = '276633';
const NANOBIT_PROJECT_ID = '195543';
const WB_MERCHANT_ID = '169548';
const WB_PROJECT_ID = '184819';
const WB_DEV_PROJECT_ID = '186632';

let customAuthorization: boolean;
let devCustomAuthorization: boolean;
let initialVcPacksSorting: boolean;

export const isCustomAuth = () => customAuthorization;
export const isDevCustomAuth = () => devCustomAuthorization;
export const isInitialVcPacksSorting = () => initialVcPacksSorting;

export const initPartnersRequests = ({
  merchantId,
  projectId,
}: {
  merchantId: string;
  projectId: string;
}) => {
  customAuthorization =
    merchantId === WB_MERCHANT_ID &&
    (projectId === WB_PROJECT_ID || projectId === WB_DEV_PROJECT_ID);

  devCustomAuthorization =
    merchantId === WB_MERCHANT_ID && projectId === WB_DEV_PROJECT_ID;

  initialVcPacksSorting =
    merchantId === NANOBIT_MERCHANT_ID && projectId === NANOBIT_PROJECT_ID;
};

export const getCustomTranslation = (locale) => {
  let module;
  try {
    // eslint-disable-next-line import/no-dynamic-require
    module = require(`./translations/${locale || 'en-US'}`);
  } catch {
    module = require('./translations/en-US');
  }
  return module.default;
};
