/** @prettier */
// @flow
import cn from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

import './style.pcss';

import arrowIcon from '../../../assets/images/icons/arrow.svg';
import { analyticsManager } from '../../../scripts/afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../../scripts/afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../../scripts/afterBlocks/analytics/analyticsCategories';

const Status = {
  VALID: 'valid',
  INVALID: 'invalid',
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
};

type Props = {|
  onChange: (value: string) => void,
  onBlur: (value: string) => void,
  onSubmit: (
    value: string,
    setStatusState: (value: $Values<typeof Status>) => void
  ) => Promise<void> | void,
  onReset: () => void,
  selectState: (value: string) => $Values<typeof Status>,
  placeholder: string,
  ariaLabel: string,
  xaName: string,
  initValue: string,
  hideButton: boolean,
  colorTheme: 'no-active' | '',
  colorizeBorder?: boolean,
  className?: string,
|};

export const Input = ({
  onChange,
  onBlur,
  onSubmit,
  onReset,
  selectState,
  placeholder,
  ariaLabel,
  xaName,
  hideButton,
  initValue,
  colorTheme,
  colorizeBorder,
  className,
}: Props) => {
  const inputRef = useRef(null);
  const [valueState, setValueState] = useState(initValue);
  const [statusState, setStatusState] = useState(Status.INVALID);

  useEffect(() => {
    setValueState(initValue);
    setStatusState(selectState(initValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]); // TODO при добавлении selectState получаем вечный ререндер на превью, нужно разобраться в причинах

  const tryFocusInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;

    setValueState(value);
    setStatusState(selectState(value));

    onChange(value);
  };

  const handleButtonClick = async () => {
    switch (statusState) {
      case Status.VALID: {
        await onSubmit(valueState, setStatusState);
        const nextStatus = selectState(valueState);
        setStatusState(nextStatus);
        if (nextStatus !== Status.SUBMITTED) {
          tryFocusInput();
        }
        break;
      }
      case Status.SUBMITTED:
        setValueState('');
        setStatusState(selectState(''));
        onReset();
        tryFocusInput();
        break;
      default:
        break;
    }
  };

  const handleEnterDown = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };
  const handleFocus = () => {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.INPUT,
      page: `landing_uid-widget_${AnalyticsActions.INPUT}-userid-start`,
    });
  };

  return (
    <div
      className={cn(
        'ui-site-input',
        `ui-site-input--${statusState}`,
        { [`ui-site-input--color-theme-${colorTheme}`]: !!colorTheme },
        { 'ui-site-input--colorize-border': colorizeBorder }
      )}
    >
      <input
        className={cn('ui-site-input__input', className)}
        placeholder={placeholder}
        maxLength='255'
        spellCheck='false'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={() => onBlur(valueState)}
        onKeyDown={handleEnterDown}
        value={valueState}
        ref={inputRef}
      />
      {!hideButton && (
        <button
          onClick={handleButtonClick}
          className='ui-site-input__button'
          aria-label={ariaLabel}
          data-xa-method='elementClick'
          data-xa-name={xaName}
        >
          <div
            className='ui-site-input__status-icon'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: arrowIcon }}
          />
          <div className='ui-site-input__status-preloader' />
        </button>
      )}
    </div>
  );
};

Input.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onSubmit: () => {},
  onReset: () => {},
  selectState: () => Status.INVALID,
  placeholder: '',
  initValue: '',
  hideButton: false,
  colorTheme: '',
  colorizeBorder: false,
};

export { Input as default, Status };
