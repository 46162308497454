// @flow
import React from 'react';

import './style.pcss';

type Props = {
  video: ?string,
  options: Object
};

const VideoPlayer = ({ video, options }: Props) => (
  <video
    className='ui-site__video-player'
    {...options}
  >
    <source src={video} />
  </video>
);

export default VideoPlayer;
