/** @prettier */
let landingAPISetting: null | boolean = null;
let customTheme: null | string = null;

const DEFAULT_THEME = 'dark';

export const setIsCustomPayStationTheme = (value: boolean, theme: string) => {
  landingAPISetting = value;
  customTheme = theme;
};

export const isCustomPayStationTheme = () =>
  landingAPISetting != null ? customTheme : DEFAULT_THEME;
