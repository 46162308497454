/** @prettier */
import { XSOLLA_ANALYTICS_SERVER } from '../config';
import { parseMerchantToNumber } from './common-helper';

export enum XAEvents {
  // Global events
  changeHeaderFont = 'sb_global-settings_header-font_change',
  changeMainFont = 'sb_global-settings_main-font_change',
  addNewLanguage = 'sb_global-settings_language_add',
  changeLanguageSelectorLocation = 'sb_global-settings_language_change-selector-place',
  createLoginProject = 'create_login_progect',
  createLoginProjectForUserId = 'create_login_project',

  // General Events
  addBlock = 'sb_general_blocks_add',
  deleteBlock = 'sb_general_blocks_delete',
  addComponent = 'sb_general_components_add',
  deleteComponent = 'sb_general_components_delete',
  toggleCustomButtonBackground = 'sb_general_button_toggle-background',
  addCustomButton = 'sb_general_button_add',
  deleteCustomButton = 'sb_general_button_delete',
  changeCustomButton = 'sb_general_button_change',
  changeBuyButtonType = 'sb_general_buy-button_change-buy-type',
  clickOnAppleStoreButton = 'apple_store_button',
  clickOnGooglePlayButton = 'google_play_button',
  changeLocale = 'change_lang',
  addPlatform = 'sb_general_platforms_add',
  deletePlatform = 'sb_general_platforms_delete',

  // Start page events
  createLanding = 'sb_createdialog_create',
  selectTemplate = 'sb_createdialog_template_click',
  focusParsingInput = 'sb_createdialog_URL_input',
  startParsingInputValidation = 'sb_createdialog_URL_validation_start',
  successParsingInputValidation = 'sb_createdialog_URL_validation_success',
  errorParsingInputValidation = 'sb_createdialog_URL_validation_error',
  switchParsingToggle = 'sb_createdialog_toggle',

  // Header settings events
  toggleHeaderPosition = 'sb_header_fixed-pos_toggle',
  toggleHeaderLogo = 'sb_header_logo_toggle',
  toggleHeaderBackground = 'sb_header_background_toggle',
  toggleHeaderLogin = 'sb_header_login_toggle',
  openLoginDocumentationFromHeader = 'sb_header_login_set_link_click',
  openBurgerMenu = 'sb_header_menu_click',
  clickOnUserAccount = 'sb_header_login_vc_click',
  clickOnLogout = 'sb_header_logout_click',

  // PromoSlider settings events
  togglePromoSliderLogo = 'sb_promo-slider_logo_toggle',
  togglePromoSliderDescription = 'sb_promo-slider_description_toggle',

  // CTA settings events
  toggleCTALogo = 'sb_cta_logo_toggle',
  toggleCTADescription = 'sb_cta_description_toggle',

  // News settings events
  changeNewsLayout = 'sb_news_layout_change',
  addNewsArticle = 'sb_news_articles_add',
  deleteNewsArticle = 'sb_news_articles_delete',
  updateNewsArticle = 'sb_news_articles_update',

  // Description settings events
  changeDescriptionLayout = 'sb_description_layout_change',
  changeDescriptionMediaOpeningType = 'sb_description_media_change-opening',

  // Gallery settings events
  changeGalleryLayout = 'sb_gallery_layout_change',

  // Social media widgets settings events
  changeSocialMediaLayout = 'sb_social-media_layout_change',

  // FAQ settings events
  toggleFAQShowAnswers = 'sb_faq_show-answers_toggle',

  // Store settings events
  changeStoreSection = 'sb_store_section_change',
  toggleStoreHeader = 'sb_store_header_toggle',
  toggleStoreDescription = 'sb_store_description_toggle',
  toggleStoreItemDescription = 'sb_store_item-description_toggle',
  changeCardsLayout = 'sb-store-layout-change',
  changeSectionsTemplate = 'sb_c_store_section_template_change',
  toggleHorizontalScroll = 'sb_c_store_horizontal-scroll_toggle',

  // Packs settings events
  togglePacksDescription = 'sb_packs_description_toggle',
  togglePacksBuyArea = 'sb_packs_buy-area_toggle',
  changePacksStyle = 'sb_packs_pack-style_change',

  // Subscriptions-packs settings events
  toggleSubscriptionsPacksImage = 'sb_subscriptions-packs_image_toggle',
  toggleSubscriptionsPacksHeading = 'sb_subscriptions-packs_heading_toggle',
  toggleSubscriptionsPacksIcons = 'sb_subscriptions-packs_icons_toggle',
  toggleSubscriptionsPacksBackground = 'sb_subscriptions-packs_background_toggle',

  // Footer settings events
  changeFooterLayout = 'sb_footer_layout_change',
  toggleFooterFBLikeWidget = 'sb_footer_share-options_toggle-fb-like',
  toggleFooterFBShareWidget = 'sb_footer_share-options_toggle-fb-share',
  toggleFooterTweetWidget = 'sb_footer_share-options_toggle-tweet',

  // System requirements events
  changeSysReqLayout = 'sb_sys-req_layout_change',
  addSysReqRow = 'sb_sys-req_row_add',
  deleteSysReqRow = 'sb_sys-req_row_delete',

  // Top up block events
  openTopUpDocLink = 'sb_topup_doc-link_open',
  toggleTopupLogo = 'sb_topup_logo_toggle',
  toggleTopupTitle = 'sb_topup_title_toggle',
  toggleTopupDescription = 'sb_topup_description_toggle',
  toggleTopupBackground = 'sb_topup_background_toggle',
  toggleTopupUserInstruction = 'sb_topup_user-instruction_toggle',

  // Store custom amount
  customAmountInputFocus = 'sb_store-block_custom-vc_focus',
  customAmountInput = 'sb_store-block_custom-vc_inputted',
  customAmountIncremented = 'sb_store-block_custom-vc_plus',
  customAmountDecremented = 'sb_store-block_custom-vc_minus',

  // Login settings
  loginSettingsBackgroundToggle = 'sb_c_login-settings_background_toggle',
  loginSettingsBackgroundUpload = 'sb_c_login-settings_background_upload',
  loginSettingsLogoToggle = 'sb_c_login-settings_logo_toggle',
  loginSettingsLogoUpload = 'sb_c_login-settings_logo_upload',
  loginSettingsIdentifyType = 'sb_c_login-settings_identify_type',
  loginSettingsSiteStyle = 'sb_c_login-settings_site_style',
  loginSettingsInstructionToggle = 'sb_c_login-settings_user-instruction_toggle',
  loginSettingsInstructionAddStep = 'sb_c_login-settings_user-instruction_add-step',
  loginSettingsInstructionDeleteStep = 'sb_c_login-settings_user-instruction_delete-step',
  loginSettingsWebhookValidationSuccess = 'sb_c_login-settings_partner-validation_success',
  loginSettingsWebhookValidationFocus = 'sb_c_login-settings_input-focus',
  loginSettingsWebhookValidationFail = 'sb_c_login-settings_partner-validation_fails',
  loginSettingsLoginAdd = 'sb_c_login-settings_login_add',
  loginSettingsLoginRemove = 'sb_c_login-settings_login_remove',
  loginSettingsLoginProjectChoose = 'sb_c_login-settings_login-project-choosen',
  loginSettingsLoginProjectForUserIdChoose = 'sb_c_login-settings_user-id-project_choosen',
  loginSettingsSocialLink = 'sb_c_login-settings_userid_socials-link',
  loginSettingsPersonalizationLink = 'sb_c_login-settings_userid_personalization-link',
  loginSettingsUserIdDocLink = 'sb_c_login-settings_userid_doc-link',
  loginSettingsLoginSetUpDocLink = 'sb_c_login-settings_xsolla_login_setup-link',
  loginSettingsLoginDocLink = 'sb_c_login-settings_xsolla_login_doc-link',

  // Shopping Cart (enable cart)
  cartDisable = 'sb_с_store_cart_disable',
  cartToggleOn = 'sb_с_store_cart_toggle-on',
  cartToggleOff = 'sb_с_store_cart_toggle-off',
}

declare global {
  interface Window {
    XA: any;
    XsollaAnalytics: any;
  }
}

export const getUploadAssetXAEvent = (blockName: string, assetType: string) =>
  `sb_${blockName}_${assetType}_upload`;

export const initXsollaAnalytics = (merchantId: string) => {
  const { hostname } = window.location;
  if (!merchantId || hostname === 'localhost') {
    return;
  }

  const { XsollaAnalytics } = window;
  window.XA = new XsollaAnalytics({
    id: 1004,
    server: XSOLLA_ANALYTICS_SERVER,
    siteDomains: ['sitebuilder.xsolla.com'],
    merchantId: parseMerchantToNumber(merchantId),
  });
};

export const sendClick = (
  merchantId: string,
  name: string,
  value?: string,
  exvValue?: any
) => {
  if (typeof window.XA !== 'object' || !name) {
    return;
  }
  window.XA.elementClick(name, {
    eid: parseMerchantToNumber(merchantId),
    ev: value ? value.toString() : null,
    exv: exvValue ? JSON.stringify(exvValue) : null,
  });
};
