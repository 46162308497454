// Object for country to region mapping for social ranking
// 1 - Europe
// 2 - China
// 3 - Korea
// 4 - CIS
// 5 - America
// 6 - World (All other countries)

export const regionMap: { [key: string]: number } = {
  AD: 6,
  AE: 6,
  AF: 6,
  AG: 6,
  AI: 6,
  AL: 1,
  AM: 4,
  AO: 6,
  AQ: 6,
  AR: 4,
  AS: 5,
  AT: 1,
  AU: 6,
  AW: 5,
  AX: 1,
  AZ: 4,
  BA: 1,
  BB: 5,
  BD: 6,
  BE: 1,
  BF: 6,
  BG: 1,
  BH: 6,
  BI: 6,
  BJ: 6,
  BL: 5,
  BM: 5,
  BN: 6,
  BO: 5,
  BQ: 5,
  BR: 5,
  BS: 5,
  BT: 6,
  BV: 6,
  BW: 6,
  BY: 4,
  BZ: 5,
  CA: 5,
  CC: 5,
  CD: 6,
  CF: 6,
  CG: 6,
  CH: 1,
  CI: 6,
  CK: 6,
  CL: 1,
  CM: 6,
  CN: 2,
  CO: 1,
  CR: 1,
  CU: 1,
  CV: 6,
  CW: 5,
  CX: 6,
  CY: 1,
  CZ: 1,
  DE: 1,
  DJ: 6,
  DK: 1,
  DM: 5,
  DO: 5,
  DZ: 6,
  EC: 5,
  EE: 1,
  EG: 6,
  EH: 6,
  ER: 6,
  ES: 1,
  ET: 6,
  FI: 1,
  FJ: 6,
  FK: 5,
  FM: 5,
  FO: 6,
  FR: 1,
  GA: 6,
  GB: 1,
  GD: 5,
  GE: 4,
  GF: 5,
  GG: 1,
  GH: 6,
  GI: 1,
  GL: 6,
  GM: 6,
  GN: 6,
  GP: 5,
  GQ: 6,
  GR: 1,
  GS: 1,
  GT: 5,
  GU: 6,
  GW: 6,
  GY: 6,
  HK: 2,
  HM: 6,
  HN: 5,
  HR: 1,
  HT: 5,
  HU: 1,
  ID: 6,
  IE: 1,
  IL: 6,
  IM: 1,
  IN: 6,
  IO: 6,
  IQ: 6,
  IR: 6,
  IS: 6,
  IT: 1,
  JE: 1,
  JM: 5,
  JO: 6,
  JP: 6,
  KE: 6,
  KG: 4,
  KH: 6,
  KI: 6,
  KM: 6,
  KN: 5,
  KP: 3,
  KR: 3,
  KW: 6,
  KY: 5,
  KZ: 4,
  LA: 6,
  LB: 6,
  LC: 5,
  LI: 1,
  LK: 6,
  LR: 6,
  LS: 6,
  LT: 1,
  LU: 1,
  LV: 1,
  LY: 6,
  MA: 6,
  MC: 1,
  MD: 4,
  ME: 1,
  MF: 1,
  MG: 6,
  MH: 6,
  MK: 1,
  ML: 6,
  MM: 6,
  MN: 6,
  MO: 2,
  MP: 6,
  MQ: 1,
  MR: 5,
  MS: 5,
  MT: 1,
  MU: 6,
  MV: 6,
  MW: 6,
  MX: 5,
  MY: 6,
  MZ: 6,
  NA: 6,
  NC: 6,
  NE: 6,
  NF: 6,
  NG: 6,
  NI: 5,
  NL: 1,
  NO: 1,
  NP: 6,
  NR: 6,
  NU: 6,
  NZ: 6,
  OM: 6,
  PA: 5,
  PE: 5,
  PF: 6,
  PG: 6,
  PH: 6,
  PK: 6,
  PL: 1,
  PM: 5,
  PN: 6,
  PR: 6,
  PS: 6,
  PT: 1,
  PW: 6,
  PY: 5,
  QA: 6,
  RE: 6,
  RO: 1,
  RS: 1,
  RU: 4,
  RW: 6,
  SA: 6,
  SB: 6,
  SC: 6,
  SD: 6,
  SE: 1,
  SG: 6,
  SH: 6,
  SI: 1,
  SJ: 6,
  SK: 1,
  SL: 6,
  SM: 1,
  SN: 6,
  SO: 6,
  SR: 5,
  SS: 6,
  ST: 6,
  SV: 5,
  SX: 5,
  SY: 6,
  SZ: 6,
  TC: 5,
  TD: 6,
  TF: 6,
  TG: 6,
  TH: 6,
  TJ: 4,
  TK: 6,
  TL: 6,
  TM: 4,
  TN: 6,
  TO: 6,
  TR: 1,
  TT: 5,
  TV: 6,
  TW: 2,
  TZ: 6,
  UA: 4,
  UG: 6,
  UM: 6,
  US: 5,
  UY: 5,
  UZ: 4,
  VA: 1,
  VC: 5,
  VE: 5,
  VG: 6,
  VI: 6,
  VN: 6,
  VU: 6,
  WF: 1,
  WS: 6,
  YE: 6,
  YT: 6,
  ZA: 6,
  ZM: 6,
  ZW: 6,
  ZZ: 6,
};
