export default data => {
  try {
    const { merchantId, projectId } = data;
    const utmLabels = [
      'utm_campaign',
      'utm_content',
      'utm_medium',
      'utm_source',
      'utm_term'
    ];

    const getDefaultUtmLabel = (label, term) => {
      switch (label) {
        case 'utm_campaign':
          return merchantId;
        case 'utm_content':
          return projectId;
        case 'utm_medium':
          return 'platform';
        case 'utm_source':
          return 'sitebuilder';
        case 'utm_term':
          return term;
        default:
          return '';
      }
    };

    const searchToObject = search => search.substring(1)
      .split('&')
      .reduce((result, value) => {
        const parts = value.split('=');
        // eslint-disable-next-line no-param-reassign
        if (parts[0]) {
          result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return result;
      }, {});

    const constructHrefWithUtm = ({ baseHref = '', pathParams = {}, utmTerm = '' } = {}) => {
      let href = baseHref;
      const baseParams = searchToObject(baseHref);
      if (!baseHref.includes('?')) {
        href += '?';
      }

      utmLabels.forEach(labelKey => {
        if (baseParams[labelKey]) {
          return;
        }
        href += `${href[href.length - 1] === '?' ? '' : '&'}${labelKey}=${pathParams[labelKey] ? pathParams[labelKey] : getDefaultUtmLabel(labelKey, utmTerm)}`;
      });

      return href;
    };

    const pathParams = searchToObject(window.location.search);
    [].slice.call(document.querySelectorAll('[data-utm-term]'))
      .filter(node => node.tagName === 'A')
      .forEach(link => {
        // eslint-disable-next-line no-param-reassign
        link.href = constructHrefWithUtm({
          baseHref: link.href,
          pathParams,
          utmTerm: link.dataset.utmTerm
        });
      });
    // eslint-disable-next-line no-empty
  } catch (e) {
  }
};
