import { XSOLLA_GOOGLE_ANALYTICS_ID } from '../../config';

const gaPartnerEvent = ({
  eventCategory, eventAction, partnerGA, eventLabel = '', eventValue = ''
}) => {
  if (typeof gtag === 'function') {
    if (partnerGA) {
      window.gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue,
        send_to: partnerGA
      });
    }
  }
};

const gaXsollaEvent = ({
  eventCategory, eventAction, projectID = '', projectName = ''
}) => {
  if (typeof gtag === 'function') {
    window.gtag('event', eventAction, {
      event_category: eventCategory,
      projectID,
      projectName,
      send_to: XSOLLA_GOOGLE_ANALYTICS_ID
    });
  }
};

const fbEvent = (type, data = '') => {
  if (typeof fbq === 'function') {
    switch (type) {
      case 'InitiateCheckout':
        window.fbq('track', 'open-paystation', data);
        break;
      case 'Subscribe':
        window.fbq('track', 'click-subscribe-submit', data);
        break;
      case 'Purchase':
        window.fbq('track', 'Purchase', data);
        break;
      case 'ViewContent':
        window.fbq('track', 'view-content', data);
        break;
      default:
        break;
    }
  }
};

const twEvent = (type, data = null) => {
  if (typeof twq === 'function') {
    switch (type) {
      case 'PageView':
        window.twq('track', 'PageView');
        break;
      case 'Purchase':
        window.twq('track', 'Purchase', data);
        break;
      case 'SignUp':
        window.twq('track', 'Sign_Up', data);
        break;
      default:
        break;
    }
  }
};

export {
  gaPartnerEvent,
  gaXsollaEvent,
  fbEvent,
  twEvent
};
