export type { LoginWidgetRoute, ConfigMiddleware } from './utils';

export {
  logOut,
  initLoginButton,
  clearLastLoginSource,
  LOGIN_WIDGET_ROUTES,
  ATTRIBUTE_TO_ROUTE,
  LOGIN_SOURCES,
  getLastLoginSource,
  isLoginLastSource,
  openLoginWidget,
  openLogin,
  setConfigMiddleware,
  openUserIdModalForButton,
  getTheme,
} from './utils';
