import { twinkleElement } from '../../../utils/common-helper';
import { OPEN_ASSET_SETTING } from './assets';
import { closeAllSettings, CHECKOUT_BLOCK } from './blocks';

export const CHECKOUT_SEO_SECTION = 'CHECKOUT_SEO_SECTION';
export const SETUP_INTERNAL_LINKS = 'SETUP_INTERNAL_LINKS';
export const INIT_SAVING_PROCESS = 'INIT_SAVING_PROCESS';
export const DATA_SAVED = 'DATA_SAVED';
export const DATA_DONT_SAVE = 'DATA_DONT_SAVE';
export const CLEAR_SAVE_STATUS = 'CLEAR_SAVE_STATUS';
export const TOGGLE_GLOBAL_THEME = 'TOGGLE_GLOBAL_THEME';
export const TOGGLE_TRANSLATIONS_SETTINGS = 'TOGGLE_TRANSLATIONS_SETTINGS';
export const SHOW_TRANSLATIONS_SETTINGS = 'SHOW_TRANSLATIONS_SETTINGS';
export const CLOSE_TRANSLATIONS_SETTINGS = 'CLOSE_TRANSLATIONS_SETTINGS';
export const SHOW_BLOCK_COMPONENTS_SETTINGS = 'SHOW_BLOCK_COMPONENTS_SETTINGS';
export const OPEN_BLOCK_COMPONENTS_SETTINGS = 'OPEN_BLOCK_COMPONENTS_SETTINGS';
export const CLOSE_BLOCK_COMPONENTS_SETTINGS = 'CLOSE_BLOCK_COMPONENTS_SETTINGS';
export const TOGGLE_PACKS_STYLES_SETTINGS = 'TOGGLE_PACKS_STYLES_SETTINGS';
export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW';
export const TOGGLE_USER_TYPE = 'TOGGLE_USER_TYPE';
export const LOADED_PREVIEW = 'LOADED_PREVIEW';
export const SET_PREVIEW_SIZE = 'SET_PREVIEW_SIZE';
export const TOGGLE_LOGIN_SETTINGS = 'TOGGLE_LOGIN_SETTINGS';
export const CLOSE_LOGIN_SETTINGS = 'CLOSE_LOGIN_SETTINGS';


const initialState = {
  saveStatus: 'completed',
  isShowGlobalTheme: false,
  isTranslationsSettingsShown: false,
  isShowLoginSettings: false,
  isBlockComponentsSettingsShown: false,
  currentComponentIndex: 0,
  isPacksStyleSettingsShown: false,
  preview: {
    enable: false,
    presetUserSubscription: 'all',
    type: 'laptop',
    loaded: false,
    size: {
      width: '100%',
      height: '100%'
    }
  },
  assetSection: {
    icon: 'dvr'
  },
  spaces: [
    {
      id: 'landing',
      type: 'component'
    },
    {
      id: 'globalTheme',
      type: 'component',
      hideContent: true
    },
    {
      id: 'login',
      hideContent: true
    },
    {
      id: 'seo',
      type: 'link_page',
      link: '/seo',
      icon: 'share',
      current: 'favicon',
      settingsMenu: [
        {
          id: 'favicon',
          label: 'seo_page.favicon.title',
          isChecked: true
        },
        {
          id: 'sharingOptions',
          label: 'seo_page.sharing.title',
          isChecked: false
        }
      ]
    },
    {
      id: 'assets',
      type: 'link_curtain_right'
    },
    {
      id: 'translations',
      hideContent: true
    }
  ]
};

export const setPreviewSize = ({ width = 1440, height = '100%', type = 'custom' }) => dispatch => {
  dispatch({
    type: SET_PREVIEW_SIZE,
    width,
    height,
    newType: type
  });
};

export const checkoutSeoBlock = currentSectionID => (dispatch, getState) => {
  const { sideMenu } = getState();
  const seoSection = sideMenu.spaces.find(section => section.id === 'seo');

  if (seoSection.current === currentSectionID) {
    return false;
  }

  const updatedSpaces = sideMenu.spaces.map(section => {
    const result = { ...section };
    if (section.id === 'seo') {
      result.current = currentSectionID;
    }
    return result;
  });

  dispatch({
    type: CHECKOUT_SEO_SECTION,
    spaces: [...updatedSpaces]
  });

  return true;
};

export const setupInternalLinks = domain => dispatch => {
  const updatedSpaces = initialState.spaces.map(item => ({
    ...item,
    link: item.link ? `/sites/${domain}${item.link}` : ''
  }));
  dispatch({
    type: SETUP_INTERNAL_LINKS,
    spaces: updatedSpaces
  });
};

export const toggleGlobalTheme = () => (dispatch, getState) => {
  const { sideMenu: { isShowGlobalTheme } } = getState();
  dispatch({
    type: TOGGLE_GLOBAL_THEME,
    isShowGlobalTheme: !isShowGlobalTheme
  });
};

export const togglePacksStylesSettings = () => (dispatch, getState) => {
  const { sideMenu: { isPacksStyleSettingsShown } } = getState();
  dispatch({
    type: TOGGLE_PACKS_STYLES_SETTINGS,
    isPacksStyleSettingsShown: !isPacksStyleSettingsShown
  });
};

export const toggleTranslationsSettings = () => (dispatch, getState) => {
  const { sideMenu: { isTranslationsSettingsShown } } = getState();
  dispatch({
    type: TOGGLE_TRANSLATIONS_SETTINGS,
    payload: {
      isTranslationsSettingsShown: !isTranslationsSettingsShown
    }
  });
};

export const showTranslationsSettings = () => dispatch => {
  dispatch(closeAllSettings());
  dispatch({
    type: SHOW_TRANSLATIONS_SETTINGS,
    payload: {
      isTranslationsSettingsShown: true
    }
  });
  twinkleElement(0);
};

export const closeTranslationsSettings = () => (dispatch, getState) => {
  const { sideMenu: { isTranslationsSettingsShown } } = getState();
  if (!isTranslationsSettingsShown) {
    return;
  }
  dispatch({
    type: CLOSE_TRANSLATIONS_SETTINGS,
    payload: {
      isTranslationsSettingsShown: false
    }
  });
  twinkleElement(0);
};

export const toggleLoginSettings = () => (dispatch, getState) => {
  const { sideMenu: { isShowLoginSettings } } = getState();
  dispatch({
    type: TOGGLE_LOGIN_SETTINGS,
    isShowLoginSettings: !isShowLoginSettings
  });
};

export const closeLoginSettings = () => (dispatch) => {
  dispatch({
    type: CLOSE_LOGIN_SETTINGS,
    isShowLoginSettings: false
  });
};

export const toggleBlockComponentsSettings = (index = 0) => (dispatch, getState) => {
  const { sideMenu: { isBlockComponentsSettingsShown } } = getState();
  dispatch({
    type: SHOW_BLOCK_COMPONENTS_SETTINGS,
    isBlockComponentsSettingsShown: !isBlockComponentsSettingsShown,
    currentComponentIndex: index
  });
};

export const openBlockComponentsSettings = index => dispatch => {
  dispatch({
    type: OPEN_BLOCK_COMPONENTS_SETTINGS,
    isBlockComponentsSettingsShown: true,
    currentComponentIndex: index
  });
};

export const closeBlockComponentsSettings = module => dispatch => {
  if (module !== 'promoSlider') {
    dispatch({
      type: CLOSE_BLOCK_COMPONENTS_SETTINGS,
      isBlockComponentsSettingsShown: false,
      currentComponentIndex: 0
    });
  }
};

export const initSavingProcess = () => dispatch => {
  dispatch({
    type: INIT_SAVING_PROCESS,
    saveStatus: 'saving'
  });
};

export const togglePreview = () => dispatch => {
  dispatch({
    type: TOGGLE_PREVIEW
  });
};

export const toggleUserType = presetUserSubscription => dispatch => {
  dispatch({
    type: TOGGLE_USER_TYPE,
    presetUserSubscription
  });
};

export const loadedPreview = () => dispatch => {
  dispatch({
    type: LOADED_PREVIEW
  });
};

export const clearSaveStatus = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: CLEAR_SAVE_STATUS,
      saveStatus: 'not'
    });
  }, 3000);
};

export const dataSaved = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: DATA_SAVED,
      saveStatus: 'completed'
    });
  }, 1000);
};

export const dataDontSave = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: DATA_DONT_SAVE,
      saveStatus: 'error'
    });
  }, 1000);
};

export default function spacesReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_TRANSLATIONS_SETTINGS:
    case CLOSE_TRANSLATIONS_SETTINGS:
    case TOGGLE_TRANSLATIONS_SETTINGS:
      return { ...state, ...action.payload };
    case CHECKOUT_SEO_SECTION:
    case SETUP_INTERNAL_LINKS:
      return { ...state, spaces: action.spaces };
    case INIT_SAVING_PROCESS:
    case DATA_SAVED:
    case DATA_DONT_SAVE:
    case CLEAR_SAVE_STATUS:
      return { ...state, saveStatus: action.saveStatus };
    case TOGGLE_GLOBAL_THEME:
      return { ...state, isShowGlobalTheme: action.isShowGlobalTheme };
    case TOGGLE_LOGIN_SETTINGS:
    case CLOSE_LOGIN_SETTINGS:
      return { ...state, isShowLoginSettings: action.isShowLoginSettings };
    case SHOW_BLOCK_COMPONENTS_SETTINGS:
    case OPEN_BLOCK_COMPONENTS_SETTINGS:
    case CLOSE_BLOCK_COMPONENTS_SETTINGS:
      return { ...state, isBlockComponentsSettingsShown: action.isBlockComponentsSettingsShown, currentComponentIndex: action.currentComponentIndex };
    case TOGGLE_PACKS_STYLES_SETTINGS:
      return { ...state, isPacksStyleSettingsShown: action.isPacksStyleSettingsShown };
    case TOGGLE_USER_TYPE:
      return { ...state, preview: { ...state.preview, presetUserSubscription: action.presetUserSubscription } };
    case TOGGLE_PREVIEW:
      return {
        ...state,
        preview: {
          ...state.preview, enable: !state.preview.enable, loaded: false
        }
      };
    case OPEN_ASSET_SETTING:
      return {
        ...state,
        isPacksStyleSettingsShown: false,
        isTranslationsSettingsShown: false
      };
    case CHECKOUT_BLOCK:
      return {
        ...state,
        isPacksStyleSettingsShown: false,
        isTranslationsSettingsShown: false,
        isShowGlobalTheme: false,
        isShowLoginSettings: false
      };
    case LOADED_PREVIEW:
      return { ...state, preview: { ...state.preview, loaded: true } };
    case SET_PREVIEW_SIZE:
      return {
        ...state,
        preview: {
          ...state.preview,
          type: action.newType,
          size: {
            ...state.preview.size,
            width: action.width,
            height: action.height
          }
        }
      };
    default:
      return state;
  }
}
