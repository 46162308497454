/** @prettier */
import cn from 'classnames';
import React from 'react';

import './style.pcss';

type AddingToCartNotificationProps = {
  name?: string,
  img: string,
  text: string,
  imageType: 'checkmark' | 'cross',
};

export const AddingToCartNotification = ({
  img,
  name,
  text,
  imageType,
}: AddingToCartNotificationProps) => {
  return (
    <div className='exp-notification'>
      <div
        className={cn(
          'exp-notification__image',
          `exp-notification__image--${imageType}`
        )}
        style={{ backgroundImage: `url(${img})` }}
      />
      <span className='exp-notification__title'>
        {name && (
          <>
            {name} <br />
          </>
        )}
        {text}
      </span>
    </div>
  );
};
