const reorderArray = (array, source, destination) => {
  const result = Array.from(array);
  const [removed] = result.splice(source, 1);
  result.splice(destination, 0, removed);
  return result;
};

const removeDuplicates = <T>(myArr: T[], prop: string): T[] =>
  myArr.filter(
    (obj, pos, arr) =>
      arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
  );

const sortArrayAlphabetically = <T>(array: T[], key) =>
  array.sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    if (textA < textB) {
      return -1;
    }
    return textA > textB ? 1 : 0;
  });

const filterArray = <T>(array: T[], filters: any): any => {
  const getValue = (value) =>
    typeof value === 'string' ? value.toUpperCase() : value;
  const filterKeys = Object.keys(filters);
  return array.filter((item) =>
    filterKeys.every((key) => {
      if (!filters[key].length) {
        return true;
      }

      return filters[key].find((filter) => {
        if (typeof getValue(item[key]) === 'object') {
          return getValue(item[key]).some((f) => f === getValue(filter));
        }

        return getValue(filter) === getValue(item[key]);
      });
    })
  );
};

export { reorderArray, removeDuplicates, sortArrayAlphabetically, filterArray };
