/** @prettier */

export const StoreUniversalItemType = {
  VIRTUAL_CURRENCY: 'virtual_currency',
  UNIT: 'unit',
  BUNDLE: 'bundle',
  VIRTUAL_GOOD: 'virtual_good',
  VIRTUAL_ITEM: 'virtual_item',
  DIGITAL_CONTENT: 'digital_content',
};

export const LandingType = {
  CFD: 'cfd',
  GPLAY: 'gplay',
  RFP: 'rfppage',
  SELLING_PAGE: 'sellingpage',
  STEAM: 'steam',
  WEBSTORE: 'store',
  TOPUP: 'topup',
};

export const ERROR_VC_GROUP = '__error__';
export const GK_TYPE = 'gk';
export const BUNDLE_TYPE = 'bundle';
export const VC_TYPE = 'vc';
export const VI_TYPE = 'vi';
export const DIGITAL_CONTENT = 'digital_content';

export const DEFAULT_VC_IMAGE =
  'https://cdn3.xsolla.com/img/misc/merchant/default-pg-image.png';

export const toStoreType = (localType) => {
  switch (localType) {
    case VI_TYPE:
      return StoreUniversalItemType.VIRTUAL_ITEM;
    case VC_TYPE:
      return StoreUniversalItemType.BUNDLE;
    case GK_TYPE:
      return StoreUniversalItemType.UNIT;
    case BUNDLE_TYPE:
      return StoreUniversalItemType.BUNDLE;
    default:
      return localType;
  }
};

export const customTypesToStoreItem = (type) => {
  switch (type) {
    case GK_TYPE:
      return {
        type: StoreUniversalItemType.UNIT,
      };
    case BUNDLE_TYPE:
      return {
        type: StoreUniversalItemType.BUNDLE,
        bundleType: 'standard',
      };
    default:
      throw Error(`Unknown type: "${type}"`);
  }
};

export class StoreItem {
  constructor(o) {
    Object.getOwnPropertyNames(o).forEach((k) => {
      // $FlowFixMe
      this[k] = o[k];
    });
  }

  static isBundle(item) {
    return (
      item.type === StoreUniversalItemType.BUNDLE &&
      item.bundle_type === 'standard'
    );
  }

  static isDRM(item) {
    return Object.prototype.hasOwnProperty.call(item, 'drm_sku');
  }

  static isVirtualGood(item) {
    return item.type === StoreUniversalItemType.VIRTUAL_GOOD;
  }

  isVI() {
    return this.type === StoreUniversalItemType.VIRTUAL_GOOD;
  }

  isVC() {
    return this.type === StoreUniversalItemType.VIRTUAL_CURRENCY;
  }

  isVCPackage() {
    return (
      this.type === 'bundle' && this.bundle_type === 'virtual_currency_package'
    );
  }

  isUnit() {
    return this.type === 'unit';
  }

  hasVirtualPrice() {
    return (
      Object.hasOwnProperty.call(this, 'virtual_prices') &&
      this.virtual_prices.length > 0
    );
  }

  get parentSKU() {
    return this.sku;
  }

  isRequireAuth() {
    return (
      this.isVI() ||
      this.isVC() ||
      this.isVCPackage() ||
      (StoreItem.isBundle(this) && this.filterAuthRequired()) || // eslint-disable-line no-use-before-define
      this.hasVirtualPrice()
    );
  }

  isSame(b) {
    return this.sku === b.sku;
  }

  canShow() {
    return !!(
      !this.isUnit() ||
      this.unit_items.some(
        (unitItem) => unitItem.has_keys || unitItem.is_pre_order
      )
    );
  }

  canBuyForRealPrice() {
    return !!this.price;
  }
}

const hasOnlyVirtualGoods = (content) =>
  content.every((item) => {
    if (item.type === StoreUniversalItemType.BUNDLE) {
      return hasOnlyVirtualGoods(item.content);
    }
    return (
      item.type === StoreUniversalItemType.VIRTUAL_GOOD ||
      item.type === StoreUniversalItemType.VIRTUAL_CURRENCY
    );
  });

export const filterAuthRequired = (content) =>
  content.some((item) => {
    if (item.type === StoreUniversalItemType.BUNDLE) {
      return hasOnlyVirtualGoods(item.content);
    }
    return (
      item.type === StoreUniversalItemType.VIRTUAL_GOOD ||
      item.type === StoreUniversalItemType.VIRTUAL_CURRENCY
    );
  });
export class BundleItem extends StoreItem {
  _landingType;

  constructor(o, landingType) {
    super(o);
    this._landingType = landingType;
  }

  filterAuthRequired() {
    return filterAuthRequired(this.content);
  }

  hasOnlyVirtualGoods() {
    return hasOnlyVirtualGoods(this.content);
  }

  get tags() {
    return this.content.map((item) => {
      let res = item.name;
      if (item.quantity > 1) {
        res += ` ×${item.quantity}`;
      }
      return res;
    });
  }

  canShow() {
    return !!(
      (this._landingType === LandingType.TOPUP &&
        this.price &&
        this.hasOnlyVirtualGoods()) ||
      (this._landingType !== LandingType.TOPUP &&
        (this.price || this.virtual_prices.length > 0))
    );
  }
}

export class UnitDRMItem extends StoreItem {
  get parentSKU() {
    return this.sku.replace(`_${this.drm_sku}`, '');
  }
}

export class VirtualGoodItem extends StoreItem {
  _landingType;

  constructor(o, landingType) {
    super(o);
    this._landingType = landingType;
  }

  canShow() {
    return !!(
      (this._landingType !== LandingType.TOPUP &&
        this.virtual_prices.length > 0) ||
      this.price
    );
  }
}

export const storeItemFactory = (landingType) => (storeItem) => {
  if (StoreItem.isDRM(storeItem)) {
    return new UnitDRMItem(storeItem);
  }

  if (StoreItem.isBundle(storeItem)) {
    return new BundleItem(storeItem, landingType);
  }

  if (StoreItem.isVirtualGood(storeItem)) {
    return new VirtualGoodItem(storeItem, landingType);
  }

  return new StoreItem(storeItem);
};
