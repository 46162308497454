// @flow
import React from 'react';

type Props = {
  additionalClasses: string,
  selectedText: string,
  options: Array<{
    value: string,
    disabled: boolean,
    activeClass: string,
    text: string
  }>
}

const CustomSelector = ({
  additionalClasses,
  selectedText,
  options
}: Props) => (
  <div className={`ui-site-custom-selector ${additionalClasses}`}>
    <div className='ui-site-custom-selector__selected'>
      <span className='ui-site-custom-selector__selected-text'>{selectedText}</span>
    </div>
    <ul className='ui-site-custom-selector__list'>
      {options.map(item => (
        <li
          key={item.value}
          data-value={item.value}
          data-disabled={item.disabled}
          className={`ui-site-custom-selector__option ${item.activeClass}`}
        >
          {item.text}
        </li>
      ))}
    </ul>
    <div className='ui-site-custom-selector--mobile' />
  </div>
);

export default CustomSelector;
